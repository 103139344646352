import { FC } from "react";
// import cn from "classnames";
import { useSelector } from "react-redux";
import { selectorHome } from "src/redux/home/selectors";
import { Section } from "src/components/Section";
import { BrandListSwiper } from "../../components/BrandList/BrandListSwiper";

export const BrandsWeService: FC = () => {
  const pageData = useSelector(selectorHome);
  const brandsListItems = pageData?.brands_we_service ?? [];

  if (!brandsListItems.length) return null;

  return (
    <Section className="relative wrapper" title="Brands we service">
      {/* <div
        className={cn(
          DEFAULT_YELLOW_BLUR,
          "top-0 !-translate-y-1/2 !from-yellow-base !to-yellow-base"
        )}
      /> */}

      <BrandListSwiper brands={brandsListItems} />
    </Section>
  );
};
