import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { Link } from "src/components/Link";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "Contact Us Today";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => (
  <div className="relative">
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper"
        titleClassName="text-yellow-base"
        title={SECTION_TITLE}
      >
        <div className="mx-auto max-w-215 sm:text-center font-medium">
          <p>
            Ready to secure a reliable electrical foundation for your property?
            <br />
            Contact{" "}
            <span className="text-yellow-base">Smartchoice Service</span> today.
          </p>
          <br />

          <p className="font-semibold">
            Call us at{" "}
            <Link
              href={phone?.href}
              className="text-yellow-base font-bold hover:underline"
            >
              {phone?.label}
            </Link>{" "}
            to schedule a consultation with our experts.
          </p>

          <br />
          <p>
            Discover why{" "}
            <span className="text-yellow-base">Smartchoice Service</span> is
            your trusted partner for superior rough wiring solutions.
          </p>
        </div>
      </Section>
    </ContentOverlay>
  </div>
);
