import { WhenToCallUsItem } from "src/components/WhenToCallUsSection/types";

export const HVAC_MAINTENANCE_WITHOUT_MAINTENANCE: WhenToCallUsItem[] = [
  {
    id: crypto.randomUUID(),
    title: "Dirty Filters",
    description:
      "Dirty or clogged filters restrict airflow, reducing system efficiency and potentially causing damage to other components.",
  },
  {
    id: crypto.randomUUID(),
    title: "Refrigerant Leaks",
    description:
      "Low refrigerant levels can lead to decreased cooling capacity and increased energy consumption, potentially causing system breakdowns and compressor damage.",
  },
  {
    id: crypto.randomUUID(),
    title: "Frozen Evaporator Coils",
    description:
      "Poor airflow or refrigerant issues can cause evaporator coils to freeze, resulting in reduced cooling capacity and potential damage to the compressor.",
  },
  {
    id: crypto.randomUUID(),
    title: "Faulty Thermostats",
    description:
      "A malfunctioning thermostat can cause your HVAC system to run inefficiently or inconsistently, leading to discomfort and higher energy bills.",
  },
  {
    id: crypto.randomUUID(),
    title: "Ignition Problems",
    description:
      "Ignition system failures in furnaces can prevent your heating system from starting or cause it to shut down unexpectedly, leaving you without heat when you need it most.",
  },
];

export const BENEFITS = [
  {
    id: 1,
    cardNumber: "01",
    title: "Optimized Performance",
    description:
      "Proper maintenance ensures that your HVAC system operates at peak performance, providing consistent heating and cooling comfort year-round.",
  },
  {
    id: 2,
    cardNumber: "02",
    title: "Extended Lifespan",
    description:
      "Regular maintenance helps prevent premature wear and tear on system components, prolonging the lifespan of your HVAC system and delaying the need for replacement.",
  },
  {
    id: 3,
    cardNumber: "03",
    title: "Improved Energy Efficiency",
    description:
      "A well-maintained HVAC system operates more efficiently, reducing energy consumption and lowering utility bills.",
  },
  {
    id: 4,
    cardNumber: "04",
    title: "Enhanced Indoor Air Quality",
    description:
      "Clean filters and coils improve indoor air quality by reducing allergens, dust, and other airborne particles circulating throughout your home or business.",
  },
];
