import React, { useState } from "react";
import { Button } from "../Button";

import "src/styles/index.scss";
import { ButtonVariants } from "../Button/types";

enum MapArea {
  CALIFORNIA = "california",
  WASHINGTON = "washington",
}

export const GoogleMaps = () => {
  const [mapArea, setMapArea] = useState<MapArea>(MapArea.CALIFORNIA);

  const link =
    mapArea === MapArea.CALIFORNIA
      ? "https://www.google.com/maps/d/u/0/embed?mid=1vMfuSoDXU8Za9hbhg0z-4xKj1UyfQVE&ehbc=2E312F&noprof=1"
      : "https://www.google.com/maps/d/u/2/embed?mid=10JiznL_ijsPteyncmmVAZWyOcrklF2I&ehbc=2E312F&noprof=1";

  return (
    <div>
      <div className="flex justify-center gap-5 lg:gap-10 mb-5">
        <Button
          variant={
            mapArea === MapArea.CALIFORNIA
              ? ButtonVariants.ACTIVE
              : ButtonVariants.SECONDARY
          }
          onClick={() => setMapArea(MapArea.CALIFORNIA)}
        >
          California
        </Button>

        <Button
          variant={
            mapArea === MapArea.WASHINGTON
              ? ButtonVariants.ACTIVE
              : ButtonVariants.SECONDARY
          }
          onClick={() => setMapArea(MapArea.WASHINGTON)}
        >
          Washington
        </Button>
      </div>

      <div className="pb-20">
        <iframe
          className="outline-none w-full"
          src={link}
          height="640"
          title="google maps"
          onWheel={(e) => e.preventDefault()}
        >
          hello
        </iframe>
      </div>
    </div>
  );

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  // });

  // const [map, setMap] = useState(null);
  // const mapRef = useRef(null);

  // const onLoad = useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   polygonPath.forEach((coord) => bounds.extend(coord));
  //   map.fitBounds(bounds);

  //   setMap(map);
  //   mapRef.current = map;
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  // const handleZoomIn = () => {
  //   if (mapRef.current) {
  //     mapRef.current.setZoom(mapRef.current.getZoom() + 1);
  //   }
  // };

  // const handleZoomOut = () => {
  //   if (mapRef.current) {
  //     mapRef.current.setZoom(mapRef.current.getZoom() - 1);
  //   }
  // };

  // const handleFullScreen = () => {
  //   if (mapRef.current) {
  //     const mapDiv = mapRef.current.getDiv();
  //     if (mapDiv.requestFullscreen) {
  //       mapDiv.requestFullscreen();
  //     } else if (mapDiv.mozRequestFullScreen) {
  //       // Firefox
  //       mapDiv.mozRequestFullScreen();
  //     } else if (mapDiv.webkitRequestFullscreen) {
  //       // Chrome, Safari and Opera
  //       mapDiv.webkitRequestFullscreen();
  //     } else if (mapDiv.msRequestFullscreen) {
  //       // IE/Edge
  //       mapDiv.msRequestFullscreen();
  //     }
  //   }
  // };

  // const handleShare = () => {
  //   const shareUrl = window.location.href; // You can customize the URL if needed
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: "My Map",
  //         text: "Check out this map!",
  //         url: shareUrl,
  //       })
  //       .then(() => console.log("Successful share"))
  //       .catch((error) => console.log("Error sharing", error));
  //   } else {
  //     // Fallback for browsers that do not support the Web Share API
  //     prompt("Copy this link to share:", shareUrl);
  //   }
  // };

  // return isLoaded ? (
  //   <section className="relative mb-15">
  //     <div className="absolute top-0 left-0 right-0 z-10 h-20 bg-black-base bg-opacity-60">
  //       <div className="flex items-center justify-between h-full px-16 py-4 w-full">
  //         <h3 className="mb-0 text-32">Smartchoice Service</h3>

  //         <div className="flex items-center gap-7">
  //           <Button variant={ButtonVariants.WHITE} onClick={handleShare}>
  //             <ShareIcon />
  //           </Button>

  //           <Button variant={ButtonVariants.WHITE} onClick={handleFullScreen}>
  //             <FullScreenIcon />
  //           </Button>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="absolute left-10 bottom-10 z-10 p-2 w-28 h-10 bg-white rounded-20">
  //       <GoogleIcon className="w-full h-full" />
  //     </div>

  //     <div className="absolute right-10 bottom-10 z-10 bg-white rounded-10 overflow-hidden">
  //       <Button
  //         className="!w-14 !h-14"
  //         variant={ButtonVariants.WHITE}
  //         onClick={handleZoomIn}
  //       >
  //         <PlusIcon />
  //       </Button>

  //       <hr className="w-full" />

  //       <Button
  //         className="!w-14 !h-14"
  //         variant={ButtonVariants.WHITE}
  //         onClick={handleZoomOut}
  //       >
  //         <MinusIcon />
  //       </Button>
  //     </div>

  //     <GoogleMap
  //       mapContainerStyle={containerStyle}
  //       center={center}
  //       zoom={10}
  //       onLoad={onLoad}
  //       onUnmount={onUnmount}
  //       options={{
  //         maxZoom: 15,
  //         minZoom: 10,
  //         disableDefaultUI: true, // Вимикає всі елементи управління
  //         zoomControl: false, // Вимикає окремі елементи управління
  //         streetViewControl: false,
  //         mapTypeControl: false,
  //         scrollwheel: false,
  //       }}
  //     >
  //       <Polygon
  //         paths={polygonPath}
  //         options={{
  //           fillColor: "#484848",
  //           fillOpacity: 0.35,
  //           strokeColor: "#484848",
  //           strokeOpacity: 1.0,
  //           strokeWeight: 1,
  //         }}
  //       />

  //       {MARKERS.map((position, index) => (
  //         <Marker
  //           key={index}
  //           position={position}
  //           icon={{
  //             url: MarkerImage,
  //             scaledSize: new window.google.maps.Size(40, 40), // Задайте потрібний розмір
  //             origin: new window.google.maps.Point(0, 0),
  //             anchor: new window.google.maps.Point(16, 16),
  //           }}
  //         />
  //       ))}
  //     </GoogleMap>
  //   </section>
  // ) : (
  //   <></>
  // );
};
