import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorAcInstallationState = (state: RootState) =>
  state.acInstallation;

export const selectorAcInstallation = createSelector(
  selectorAcInstallationState,
  (acInstallationState) => acInstallationState.data
);

export const selectorAcInstallationIsLoading = createSelector(
  selectorAcInstallationState,
  (acInstallationState) => acInstallationState.isLoading
);
