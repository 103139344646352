import React, { FC, useState } from "react";
import cn from "classnames";
import { ReactComponent as FullStarIcon } from "src/assets/icons/full-yelp-star.svg";
import { ReactComponent as EmptyStarIcon } from "src/assets/icons/empty-yelp-star.svg";
import { ReactComponent as HalfStarIcon } from "src/assets/icons/half-yelp-star.svg";
import { ReactComponent as YelpIcon } from "src/assets/icons/yelp.svg";
import { ReactComponent as GoogleIcon } from "src/assets/icons/google-icon-no-background.svg";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down-upload-white-base.svg";
import { IReviewCard } from "./types";
import { StarRating } from "../StarRating";
import { Button } from "../Button";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
} from "../SectionCard/constants";

export const ReviewCard: FC<Omit<IReviewCard, "id">> = ({
  cardType = "google",
  name,
  date,
  ratingValue = 5,
  description,
  icon,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const Icon = cardType === "google" ? GoogleIcon : YelpIcon;

  return (
    <div className={cn(DEFAULT_CARD_WRAPPER_CLASSNAME, "max-w-70")}>
      <div className={cn(DEFAULT_CARD_CLASSNAME, "!p-5")}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="overflow-hidden w-10 h-10 rounded-full bg-gray-base flex items-center justify-center flex-shrink-0">
                <img src={icon} alt="person img" />
              </div>

              <div className="flex flex-col">
                <span
                  className="text-16 font-semibold line-clamp-1 break-all"
                  title={name}
                >
                  {name}
                </span>

                <span className="text-13 leading-normal">{date}</span>
              </div>
            </div>

            <div className="min-w-12 min-h-10">
              <Icon className="w-full h-full" width={48} height={40} />
            </div>
          </div>

          <div className="w-36">
            {cardType === "google" ? (
              <StarRating value={ratingValue} starSize={22} />
            ) : (
              <StarRating
                value={ratingValue}
                fullIcon={FullStarIcon}
                emptyIcon={EmptyStarIcon}
                halfIcon={HalfStarIcon}
                starSize={22}
              />
            )}
          </div>

          <p
            className={cn("text-13 leading-normal", {
              "line-clamp-4": !isOpen,
            })}
          >
            {description}
          </p>

          <div className="flex flex-row-reverse">
            <Button
              className={cn({
                "rotate-180": isOpen,
              })}
              onClick={handleOpenClick}
            >
              <ArrowIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
