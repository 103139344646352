export const BENEFITS_OF_UPGRADING_AC_SYSTEM = [
  {
    id: crypto.randomUUID(),
    title: "Enhanced Efficiency",
    description:
      "Replacing an old HVAC system with an ENERGY STAR-qualified unit can significantly reduce energy consumption. These systems use advanced technology to cool your home more efficiently, cutting your energy bill by nearly $200 a year, depending on your location.",
    cardNumber: "01",
  },
  {
    id: crypto.randomUUID(),
    title: "Improved Comfort",
    description:
      "A new AC system ensures consistent and reliable cooling, eliminating hot spots and maintaining a comfortable environment throughout your home.",
    cardNumber: "02",
  },
  {
    id: crypto.randomUUID(),
    title: "Increased Reliability",
    description:
      "Older HVAC systems are prone to breakdowns and inefficiencies. A new system reduces the likelihood of unexpected failures, providing peace of mind and uninterrupted comfort.",
    cardNumber: "03",
  },
  {
    id: crypto.randomUUID(),
    title: "Better Indoor Air Quality",
    description:
      "Modern AC units come with advanced filtration systems that enhance indoor air quality by removing dust, allergens, and other pollutants.",
    cardNumber: "04",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_AC_INSTALLATION = [
  {
    id: crypto.randomUUID(),
    title: "Expertise and Professionalism",
    areaText: [
      {
        subTitle: "Highly Skilled Technicians",
        description:
          "Our certified experts bring years of experience to every job, ensuring your AC system is installed efficiently and correctly.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Comprehensive Guarantees",
    areaText: [
      {
        subTitle: "Satisfaction Guarantee",
        description: "We ensure your complete satisfaction with our services.",
      },
      {
        subTitle: "Long-Term Warranties",
        description:
          "Our partnerships with top manufacturers offer extensive warranties ranging from 5 to 10 years, protecting your investment.",
      },
      {
        subTitle: "Performance Guarantee",
        description:
          "If your system doesn’t perform as expected, we will promptly address the issue at no extra cost.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Superior Products",
    areaText: [
      {
        subTitle: "Top-Tier Manufacturers",
        description:
          "We provide access to the latest, most energy-efficient air conditioning systems.",
      },
      {
        subTitle: "ENERGY STAR Qualified Systems",
        description:
          "Our units deliver superior energy efficiency and significant savings on your energy bills.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Comprehensive Service Coverage",
    areaText: [
      {
        subTitle: "Wide Service Area",
        description:
          "Serving Orange County, Los Angeles County in California, and Pierce County and King County in Washington State.",
      },
      {
        subTitle: "Full-Service Support",
        description:
          "From consultation to post-installation support, we handle everything for a seamless experience.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    areaText: [
      {
        subTitle: "Personalized Solutions",
        description:
          "We assess your specific needs and recommend the best system for your space and budget.",
      },
      {
        subTitle: "Transparent Pricing",
        description:
          "Competitive pricing with no hidden fees, and detailed estimates before work begins.",
      },
    ],
  },
];
