import { FC } from "react";
import { IBreadCrumbsItem } from "./types";
import { Link } from "../Link";

export const BreadCrumbsItem: FC<IBreadCrumbsItem> = ({ name, path }) => {
  return (
    <li className="text-lg uppercase group">
      {!path ? (
        <span className="text-yellow-base line-clamp-1">{name}</span>
      ) : (
        <div className="flex items-center">
          <Link href={path}>
            <span className="flex items-center truncate">
              <span className=" truncate group-hover:text-yellow-base">
                {name}
              </span>
            </span>
          </Link>

          <span className="mx-2 flex items-center">/</span>
        </div>
      )}
    </li>
  );
};
