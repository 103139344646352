import React, { FC, useState } from "react";
import cn from "classnames";
import { getImageUrl } from "src/utils/getImageUrl";
import { Sizes } from "src/@types/sizes";
import { IImage } from "src/@types";
import { Loader } from "../Loader";

interface Props {
  image: IImage;
  containerClassName?: string;
  className?: string;
}

export const ImageWithLoader: FC<Props> = ({
  image,
  containerClassName,
  className,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className={cn("min-h-40 relative w-full", containerClassName)}>
      <div
        className={cn(
          "absolute flex items-center justify-center w-full h-full opacity-0",
          {
            "opacity-100": isLoading,
          }
        )}
      >
        <Loader size={Sizes.XXL} />
      </div>

      <img
        className={cn("w-full h-full opacity-100", className, {
          "opacity-0": isLoading,
        })}
        src={getImageUrl(image?.image)}
        alt={image?.alt}
        title={image?.title}
        onLoad={handleImageLoad}
      />
    </div>
  );
};
