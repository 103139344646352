import { FC } from "react";
import { Services } from "src/components/ServicesList/types";
import { ServiceSwiper } from "./ServiceSwiper";

interface Props {
  services: Services;
}

export const ServicesList: FC<Props> = ({ services }) => (
  <>
    {services.map(({ category, cards }) => (
      <ServiceSwiper key={category?.id} cards={cards} category={category} />
    ))}
  </>
);
