import { FC } from "react";
import { Link } from "src/components/Link";
import { getItemPath } from "src/utils/getItemPath";
import { PATHNAMES } from "src/constants/routes";
import { IState } from "src/@types/serviceArea";

interface Props {
  state: IState;
}

export const ServiceRegionList: FC<Props> = ({ state }) => {
  if (!state) return null;

  return (
    <div className="lg:w-1/2">
      <div className="flex flex-col p-10 sm:py-0">
        <h4 className="mb-5 text-2xl text-yellow-base font-manrope font-bold">
          {state?.name}:
        </h4>

        <ul className="grid grid-cols-1 gap-x-10 list-disc md:grid-cols-2">
          {Boolean(state?.cities?.length) &&
            state.cities.map((city) => (
              <li
                className="text-base font-arial hover:text-yellow-base py-1"
                key={city.slug}
              >
                <Link
                  href={getItemPath(PATHNAMES.SERVICE_AREA_REGION, {
                    slug: city.slug,
                  })}
                >
                  {city.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
