import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { TYPES_OF_ROUGH_WIRING } from "./constants";

const SECTION_TITLE = "Types of Rough Wiring We Offer";
const SECTION_DESC =
  "At Smartchoice Service, we specialize in a wide range of rough wiring services to meet the diverse needs of our clients:";

export const RoughWiringTypes = () => (
  <Section
    className="wrapper mx-auto"
    descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10 lg:text-start"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {TYPES_OF_ROUGH_WIRING.map((type) => (
        <SectionCard key={type.id} {...type} />
      ))}
    </div>
  </Section>
);
