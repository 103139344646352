import { FC, useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { getApplianceMajorRepairPageAsync } from "src/redux/services/appliance/majorRepair/actions";
import {
  selectorApplianceMajorRepair,
  selectorApplianceMajorRepairsIsLoading,
} from "src/redux/services/appliance/majorRepair/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { ContactUs } from "src/page-components/Services/Appliance/MajorRepair/ContactUs";
import {
  MAJOR_APPLIANCE_SERVICE,
  WHY_CHOOSE_SMARTCHOICE_SERVICE,
} from "src/page-components/Services/Appliance/MajorRepair/constants";
import { Hero } from "src/components/Hero";
import { BookNowModal } from "src/components/BookNowModal";
import { OtherServices } from "src/components/OtherServices";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { Section } from "src/components/Section";
import { BrandListSwiper } from "src/components/BrandList/BrandListSwiper";
import { NewsSwiper } from "src/components/NewsSwiper";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { ReviewSection } from "src/components/ReviewSection";
import { Loader } from "src/components/Loader";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { Sizes } from "src/@types/sizes";

const MajorRepair: FC = () => {
  const sectionRef = useRef(null);
  const dispatch = useAppDispatch();

  const bookNow = useModal();

  const contactsData = useAppSelector(selectorDetails);
  const pageData = useAppSelector(selectorApplianceMajorRepair);
  const isLoading = useAppSelector(selectorApplianceMajorRepairsIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getApplianceMajorRepairPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            sectionRef={sectionRef}
            name="Major Appliance Repair"
            title="Keeping Your Appliances Running Smoothly"
            image={pageData?.banner_background}
          >
            Major appliances play a crucial role in everyday life, from
            refrigerators to washing machines. At Smartchoice Service, we
            specialize in providing expert repair services for a wide range of
            appliances. Whether it's a malfunctioning refrigerator, a noisy
            dishwasher, or a faulty washer/dryer, our skilled technicians are
            dedicated to restoring your appliances to optimal functionality.
          </Hero>
        )}

        <div className="container">
          <div ref={sectionRef}>
            <WhenToCallUsSection
              title="Our Major Appliance Repair Services"
              description="Smartchoice Service offers comprehensive repair services for major appliances, including:"
              items={MAJOR_APPLIANCE_SERVICE}
              image={pageData?.when_to_call_us}
              isShowBookOrcall={false}
            />
          </div>

          <Section
            className="wrapper relative"
            titleClassName="mb-10"
            descriptionClassName="mb-10 text-center"
            title="Brands We Service"
            description="We are experienced in repairing appliances from a variety of brands, including but not limited to:"
          >
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 right-0 !to-yellow-base "
              )}
            /> */}

            <BrandListSwiper brands={pageData?.brands_we_service} />
          </Section>

          <div className="relative">
            <WhenToCallUsSection
              className="gl:!flex-row-reverse"
              imageClassname="max-h-132.5 object-top"
              title="Why Choose Smartchoice Service?"
              description="Choose Smartchoice Service for your major appliance repairs because:"
              items={WHY_CHOOSE_SMARTCHOICE_SERVICE}
              image={pageData?.why_smartchoise}
              onButtonClick={bookNow.openModal}
            />

            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden left-0 bottom-0 translate-y-full !-translate-x-1/2 gl:block "
              )}
            /> */}
          </div>

          <AppointmentBooking
            className="wrapper gl:!py-25"
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
          />
        </div>

        <ContactUs
          image={pageData?.contact_us}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          <div className="relative py-25">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden top-0 right-0 gl:block"
              )}
            /> */}

            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden bottom-0 left-0 translate-y-1/2 !-translate-x-1/2 gl:block"
              )}
            /> */}

            <GetInTouch />
          </div>

          <OtherServices />

          <div className="hidden gl:block">
            <ReviewSection />
          </div>

          <Section className="wrapper gl:hidden" title="News and Tips">
            <NewsSwiper />
          </Section>
        </div>
      </PageWrapper>

      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </>
  );
};
export default MajorRepair;
