import { FC, useEffect } from "react";
import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useParams } from "react-router-dom";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  selectServiceAreaRegion,
  selectServiceAreaRegionIsLoading,
} from "src/redux/serviceAreaRegion/selectors";
import { getServiceAreaRegionAsync } from "src/redux/serviceAreaRegion/actions";
import { selectorDetails } from "src/redux/details/selectors";
import { ImageTextSection } from "src/page-components/ServiceArea/Region/ImageTextSection";
import { WHY_SERVICE_IN_REGION } from "src/page-components/ServiceArea/Region/constants";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { ContentOverlay } from "src/components/ContentOverlay";
import { GoogleMaps } from "src/components/GoogleMaps";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { SectionCard } from "src/components/SectionCard";
import { Link } from "src/components/Link";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { BookNowModal } from "src/components/BookNowModal";
import { GetInTouch } from "src/components/GetInTouchForm";
import { Loader } from "src/components/Loader";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

export const CITY_CLASSNAME = "capitalize text-yellow-base";

const ServiceAreaRegion: FC = () => {
  const bookNow = useModal();

  const { slug } = useParams();

  const contactsData = useAppSelector(selectorDetails);
  const dispatch = useAppDispatch();

  const pageData = useAppSelector(selectServiceAreaRegion);
  const isLoading = useAppSelector(selectServiceAreaRegionIsLoading);

  useEffect(() => {
    dispatch(getServiceAreaRegionAsync(slug));
  }, [slug]);

  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { path: PATHNAMES.SERVICE_AREA, name: "Service Area" },
    { name: pageData?.city_title },
  ];

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper isShownMaps={false}>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <>
            <div className="container">
              <div className="relative flex justify-center py-20">
                <BreadCrumbs
                  className="absolute top-8 left-5 sm:left-17.5 "
                  breadCrumbsItems={breadCrumbsItems}
                />
                <div className="px-5 w-full sm:max-w-160 sm:p-0">
                  <h3 className="mb-6 text-5xl font-bebas text-start">
                    HVAC, Electrical, and Appliance Services in &nbsp;
                    <span className={CITY_CLASSNAME}>
                      {pageData?.city_title}
                    </span>
                  </h3>
                  <p className="text-16 font-manrope">
                    Welcome to &nbsp;
                    <span className="text-yellow-base">
                      Smartchoice Service Inc.
                    </span>
                    , a trusted provider of HVAC, Electrical, and Appliance
                    repair services in{" "}
                    <span className={CITY_CLASSNAME}>
                      {pageData?.city_title}
                    </span>
                    . Established in 2017, &nbsp;
                    <span className="text-yellow-base">
                      Smartchoice Service Inc.
                    </span>
                    &nbsp; has proudly served the &nbsp;
                    <span className={CITY_CLASSNAME}>
                      {pageData?.city_title}
                    </span>{" "}
                    community with dedication and expertise. Our commitment to
                    quality service and customer satisfaction has made us a
                    leader in the industry.
                  </p>
                </div>
              </div>

              <GoogleMaps />
            </div>
            <ContentOverlay
              className="wrapper"
              bg={pageData?.banner_background}
              bgPositionClassName="object-center-30"
            >
              <p className="sm:text-center">
                Discover expert HVAC, electrical, and appliance repair services
                in &nbsp;
                <span className={cn(CITY_CLASSNAME, "font-bold")}>
                  {pageData?.city_title}
                </span>
                .
                <br />
                <span className={cn(CITY_CLASSNAME, "font-bold")}>
                  Smartchoice Service Inc
                </span>
                &nbsp;offers reliable solutions with a history of excellence.
                <br />
                <br />
                <Link
                  href={contactsData?.CONTACTS[2]?.href}
                  className="text-yellow-base font-bold hover:underline"
                >
                  Contact us
                </Link>{" "}
                today!
              </p>
            </ContentOverlay>

            <div className="container">
              <ImageTextSection
                img={pageData?.why_choose_smartchoice}
                city={pageData?.city_title}
              />

              <section className="wrapper relative">
                {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            />
            <div className={cn(DEFAULT_YELLOW_BLUR, "bottom-0 right-0 ")} /> */}

                <h3 className="mb-5">
                  Why Choose Smartchoice Service Inc. in &nbsp;
                  <span className={CITY_CLASSNAME}>{pageData?.city_title}</span>
                </h3>
                <p className="mb-5 text-16 sm:text-center">
                  Electrical systems can fail for numerous reasons, and
                  understanding these can help in preventing future issues:
                </p>

                <div className="flex flex-wrap justify-center items-center gap-10 ">
                  {WHY_SERVICE_IN_REGION.map((card) => (
                    <SectionCard key={card.id} {...card} />
                  ))}
                </div>
              </section>

              <AppointmentBooking
                title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
                image={pageData?.appointment_booking}
                onButtonClick={bookNow.openModal}
                isLoading={isLoading}
              />

              {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}

              <GetInTouch />
              {/* </div> */}
            </div>
          </>
        )}
      </PageWrapper>
      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </>
  );
};

export default ServiceAreaRegion;
