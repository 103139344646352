import { FC, RefObject } from "react";
import { useMediaQuery } from "react-responsive";
import { IImage } from "src/@types";
import { ImageWithLoader } from "src/components/ImageWithLoader";
import { SCREEN_BREAKPOINTS } from "src/constants/screenBreakpoints";

interface Props {
  sectionRef?: RefObject<HTMLElement>;
  image?: IImage;
}

export const RealiableRepair: FC<Props> = ({ sectionRef, image }) => {
  const isLGScreenBreakpoint = useMediaQuery({
    minWidth: SCREEN_BREAKPOINTS.MD,
  });

  return (
    <section className="wrapper" ref={sectionRef}>
      <div className="grid md:grid-cols-2 gap-10">
        {isLGScreenBreakpoint && (
          <ImageWithLoader
            containerClassName="md:max-h-110 gl:max-h-120.5 xl:max-h-90 border border-yellow-base rounded-10 overflow-hidden"
            className="object-cover object-top"
            image={image}
          />
        )}

        <div>
          <h4 className="text-center mb-5 font-bebas text-40 leading-9 lg:text-64 lg:leading-16">
            Trustworthy Technicians, Reliable Repairs
          </h4>
          {!isLGScreenBreakpoint && (
            <ImageWithLoader
              containerClassName="max-h-120 border border-yellow-base rounded-10 overflow-hidden mb-5"
              className="h-full rounded-10 object-cover"
              image={image}
            />
          )}

          <p className="text-16">
            Rest easy knowing that Smartchoice Service employs fully licensed,
            bonded, and insured technicians. Our verified professionals are
            equipped with the expertise and tools needed to tackle any furnace
            problem, ensuring the job is done right the first time.
            <br />
            <br />
            Our technicians arrive prepared with fully stocked trucks,
            containing a comprehensive range of furnace parts. From fan blades
            to igniters, we have everything needed to complete repairs quickly
            and effectively, restoring warmth and comfort to your home.
          </p>
        </div>
      </div>
    </section>
  );
};
