import React, { FC } from "react";

interface Props {
  title: string;
  // items?: string[];
  description?: string;
}

export const DetailsBlock: FC<Props> = ({ title, description }) => {
  const isHTML = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

  return (
    <div className="flex flex-col gap-2">
      <span className="text-yellow-base text-2xl font-bold">{title}</span>
      {/* {items ? (
        <ul className="list-disc list-inside">
          {Boolean(items?.length) &&
            items.map((item) => <li className="text-base">{item}</li>)}
        </ul>
      ) : (
        <p className="text-base">{description}</p>
      )} */}

      {isHTML(description) ? (
        <div
          className="text-base"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : (
        <div className="text-base">{description}</div>
      )}
    </div>
  );
};
