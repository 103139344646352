import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { WHY_ROUGH_WIRING_IMPORTANT } from "./constants";

const SECTION_TITLE = "Why Rough Wiring is Important";
const SECTION_DESC =
  "Rough wiring is the backbone of your electrical system. Proper installation ensures:";

export const WhyImportant = () => (
  <Section
    className="wrapper mx-auto"
    descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {WHY_ROUGH_WIRING_IMPORTANT.map((type) => (
        <SectionCard key={type.id} {...type} />
      ))}
    </div>
  </Section>
);
