import React, { FC } from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { CareerCard } from "src/components/CareerCard";
import { ReactComponent as Arrow } from "src/assets/icons/arrow.svg";
import { ICareerItem } from "src/@types/career";

export const SWIPER_ARROW_CLASSNAME =
  "w-10 h-10 fill-yellow-base cursor-pointer hover:scale-110 focus:scale-110";

interface Props {
  items?: ICareerItem[];
}

export const CareerSwiper: FC<Props> = ({ items }) => (
  <div className="relative max-w-267.5 mx-auto">
    <div className="absolute -top-20 right-0 flex gap-x-10">
      <Arrow
        className={cn(
          SWIPER_ARROW_CLASSNAME,
          "custom-swiper-button-prev rotate-180"
        )}
      />

      <Arrow
        className={cn(SWIPER_ARROW_CLASSNAME, "custom-swiper-button-next")}
      />
    </div>

    <Swiper
      modules={[Navigation]}
      spaceBetween={40}
      slidesPerView="auto"
      navigation={{
        nextEl: ".custom-swiper-button-next",
        prevEl: ".custom-swiper-button-prev",
      }}
    >
      {items?.map((item) => (
        <SwiperSlide className="max-w-82.5" key={item.id}>
          <CareerCard {...item} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);
