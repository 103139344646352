import React from "react";
import { SMARTCHOICE_ADVANTAGE_CARDS } from "./constants";
import { SectionCard } from "src/components/SectionCard";

export const AdvantagesList = () => (
  <div
    // className="grid grid-cols-1 sm:grid-cols-default-cards justify-center gap-5 md:gap-10"
    className="flex flex-col sm:flex-row sm:flex-wrap justify-center gap-5 lg:gap-10"
  >
    {SMARTCHOICE_ADVANTAGE_CARDS.map(({ ...card }) => (
      <SectionCard
        key={card.id}
        {...card}
        containerClassName="mx-auto sm:mx-0"
      />
    ))}
  </div>
);
