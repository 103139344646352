import { FC, ReactNode, RefObject } from "react";
import { useModal } from "src/hooks/useModal";
import { scrollToSection } from "src/utils/scrollToSection";
import { getImageUrl } from "src/utils/getImageUrl";
import { IImage } from "src/@types";
import { ReactComponent as BookIcon } from "src/assets/icons/book-black-base.svg";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { BreadCrumbs } from "../BreadCrumbs";
import { IBreadCrumbsItem } from "../BreadCrumbs/types";
import { BookNowModal } from "../BookNowModal";

interface HeroProps {
  image: IImage;
  title: string;
  name: string;
  children: ReactNode;
  sectionRef?: RefObject<HTMLElement>;
}

export const Hero: FC<HeroProps> = ({
  image,
  title,
  name,
  children,
  sectionRef,
}) => {
  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: "/",
      name: "Home",
    },
    {
      name: name,
    },
  ];

  const onClick = () => {
    if (sectionRef) {
      scrollToSection(sectionRef);
    }
  };
  const bookNow = useModal();

  return (
    <>
      <div className="relative w-full min-h-95 lg:h-200 overflow-hidden">
        <div className="absolute z-10 w-full h-full bg-gradient-to-r from-black-base to-transparent" />

        <img
          className="absolute w-full h-full object-cover"
          src={getImageUrl(image?.image)}
          alt={image?.alt}
        />

        <div className="wrapper flex flex-col justify-between gap-8  relative z-20 lg:gap-20 lg:py-8 px-17.5">
          <BreadCrumbs breadCrumbsItems={breadCrumbsItems} />

          <div className="sm:w-160">
            <h2 className="mb-6 text-40 leading-10 lg:text-5xl font-bebas">
              {title}
            </h2>

            <p className="mb-6 text-xl  leading-8 font-manrope">{children}</p>

            {/* <Button
            className="w-full xs:w-41.25"
            variant={ButtonVariants.PRIMARY}
            onClick={onClick}
          >
            More Details
          </Button> */}
            <Button
              className="flex items-center gap-2 min-w-40 border-goldGradientOlive bg-gradient-to-b from-heroBtnStart to-heroEnd lg:py-3"
              type="button"
              variant={ButtonVariants.PRIMARY}
              onClick={bookNow.openModal}
            >
              <BookIcon className="min-w-5 min-h-5" />

              <span className="text-18 font-extrabold whitespace-nowrap">
                Book now
              </span>
            </Button>
          </div>
        </div>
      </div>

      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </>
  );
};
