import { useEffect, useRef } from "react";
import { SuperSEO } from "react-super-seo";
import { selectorDetails } from "src/redux/details/selectors";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { ContactUs } from "src/page-components/Services/Appliance/Maintenance/ContactUs";
import {
  IMPORTANCE_OF_APPLIANCE_MAINTENANCE,
  WHY_MAINTENANCE_IMPORTANT,
  OUR_APPLIANCE_MAINTENANCE_SERVICES,
  WHY_CHOOSE_SMARTCHOICE_SERVICE_MAINTENANCE,
} from "src/page-components/Services/Appliance/Maintenance/constants";
import { BookNowModal } from "src/components/BookNowModal";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { ReviewSection } from "src/components/ReviewSection";
import { OtherServices } from "src/components/OtherServices";
import { NewsSwiper } from "src/components/NewsSwiper";
import { Loader } from "src/components/Loader";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { Sizes } from "src/@types/sizes";
import {
  selectorApplianceMaintenance,
  selectorApplianceMaintenancesIsLoading,
} from "src/redux/services/appliance/maintenance/selectors";
import { getApplianceMaintenancePageAsync } from "src/redux/services/appliance/maintenance/actions";

const Maintenance = () => {
  const bookNow = useModal();

  const sectionRef = useRef(null);
  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);
  const pageData = useAppSelector(selectorApplianceMaintenance);

  const isLoading = useAppSelector(selectorApplianceMaintenancesIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getApplianceMaintenancePageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />
      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            sectionRef={sectionRef}
            name="Appliance Maintenance"
            title="Keeping Your Appliances in Peak Condition"
            image={pageData?.banner_background}
          >
            Regular maintenance is crucial for prolonging the lifespan and
            efficiency of your appliances. At Smartchoice Service, we offer
            comprehensive appliance maintenance services to help you avoid
            costly repairs and ensure your appliances operate smoothly.
          </Hero>
        )}

        <div className="container">
          <div ref={sectionRef}>
            <Section
              className="wrapper relative"
              titleClassName="mb-1"
              subTitleClassName="mb-15 text-center"
              descriptionClassName="mb-10 text-center"
              title="Importance of Appliance Maintenance"
              subTitle="What Happens if Maintenance is Not Done?"
              description="Neglecting regular maintenance can lead to various issues with your appliances:"
            >
              {/* <div
                className={cn(
                  DEFAULT_YELLOW_BLUR,
                  "hidden top-0 right-0 gl:block"
                )}
              /> */}

              <div className="flex flex-wrap justify-center gap-10">
                {IMPORTANCE_OF_APPLIANCE_MAINTENANCE.map((card) => (
                  <SectionCard key={card.id} {...card} />
                ))}
              </div>
            </Section>
          </div>

          <Section
            className="wrapper relative"
            titleClassName="mb-5"
            descriptionClassName="mb-10 text-center"
            title="Why Maintenance is Important"
            description="Regular maintenance offers several benefits:"
          >
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}

            <div className="flex flex-wrap justify-center gap-10">
              {WHY_MAINTENANCE_IMPORTANT.map((card) => (
                <SectionCard key={card.id} {...card} />
              ))}
            </div>
          </Section>

          <WhenToCallUsSection
            title="Our Appliance Maintenance Services"
            description="Smartchoice Service offers comprehensive repair services for major appliances, including:"
            image={pageData?.when_to_call_us}
            items={OUR_APPLIANCE_MAINTENANCE_SERVICES}
            onButtonClick={bookNow.openModal}
          />

          <WhenToCallUsSection
            className="flex-row-reverse !items-start"
            imageClassname="max-h-138 object-top"
            title="Why Choose Smartchoice Service?"
            description="Choose Smartchoice Service for your major appliance repairs because:"
            image={pageData?.why_smartchoise}
            items={WHY_CHOOSE_SMARTCHOICE_SERVICE_MAINTENANCE}
            onButtonClick={bookNow.openModal}
          />

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden top-0 left-0 !-translate-x-1/2 gl:block"
              )}
            /> */}

            <AppointmentBooking
              className="py-25"
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
            />
          </div>
        </div>

        <ContactUs
          image={pageData?.contact_us}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          <div className="relative py-25">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden top-0 right-0 gl:block"
              )}
            />
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden bottom-0 left-0 translate-y-1/2 !-translate-x-1/2 gl:block"
              )}
            /> */}
            <GetInTouch />
          </div>

          <OtherServices />

          <div className="hidden lg:block">
            <ReviewSection />
          </div>

          <Section className="wrapper lg:hidden" title="News and Tips">
            <NewsSwiper />
          </Section>
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};
export default Maintenance;
