import { FC } from "react";
import { ImageWithLoader } from "src/components/ImageWithLoader";
import { Section } from "src/components/Section";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "About us";

interface Props extends ISectionWithImage {}

export const AboutUs: FC<Props> = ({ image }) => (
  <Section className="wrapper" title={SECTION_TITLE} titleClassName="sm:mb-15">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 gl:gap-15">
      <ImageWithLoader
        image={image}
        className="object-cover object-top h-96"
        containerClassName="border border-yellow-base rounded-10 max-h-96 overflow-hidden"
      />

      <div>
        <h4 className="mb-5 text-3xl lg:text-40 font-bebas">
          Welcome to{" "}
          <span className="text-yellow-base font-bebas">
            Smartchoice Service
          </span>
        </h4>

        <p className="text-base lg:text-xl">
          Your trusted partner for appliance repair, HVAC, and electrical
          services in California and Washington state since 2017. We are
          dedicated to providing top-notch service with a focus on quality,
          reliability, and customer satisfaction.
          <br />
          <br />
          At{" "}
          <span className="text-yellow-base font-bold">
            Smartchoice Service
          </span>
          , we understand the importance of having well-functioning appliances,
          heating, cooling, and electrical systems in your home or business. Our
          team of highly skilled technicians is trained to handle a wide range
          of issues, ensuring your equipment operates efficiently and safely.
        </p>
      </div>
    </div>
  </Section>
);
