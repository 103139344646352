import { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { getApplianceInstallationPageAsync } from "src/redux/services/appliance/installation/actions";
import {
  selectorApplianceInstallation,
  selectorApplianceInstallationsIsLoading,
} from "src/redux/services/appliance/installation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { ContactUs } from "src/page-components/Services/Appliance/Installation/ContactUs";
import {
  ADVANTAGES_OF_PROFESSIONAL_INSTALLATION,
  OUR_APPLIANCE_INSTALLATION_SERVICES,
  WHY_CHOOSE_SMARTCHOICE_SERVICE_INSTALATION,
} from "src/page-components/Services/Appliance/Installation/constants";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { SectionCard } from "src/components/SectionCard";
import { Section } from "src/components/Section";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { NewsSwiper } from "src/components/NewsSwiper";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import ContactUsForSolution from "src/assets/images/home/our-commitment.jpg";

const ApplianceInstallation = () => {
  const sectionRef = useRef(null);

  const bookNow = useModal();
  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);
  const pageData = useAppSelector(selectorApplianceInstallation);

  const isLoading = useAppSelector(selectorApplianceInstallationsIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getApplianceInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />
      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            sectionRef={sectionRef}
            name="Appliance Installation"
            title="Expert Installation for Your Appliances"
            image={pageData?.banner_background}
          >
            Proper installation of appliances is essential for their optimal
            performance and longevity. At Smartchoice Service, we provide
            reliable appliance installation services to ensure your new
            appliances are set up correctly and ready to use.
          </Hero>
        )}

        <div className="container">
          <div ref={sectionRef}>
            <Section
              className="wrapper relative"
              titleClassName="mb-1"
              subTitleClassName="mb-5 text-center"
              descriptionClassName="mb-10 gl:text-center"
              title="Importance of Professional Installation"
              subTitle="Why Professional Installation Matters?"
              description="Choosing professional installation offers several advantages:"
            >
              {/* <div
                className={cn(
                  DEFAULT_YELLOW_BLUR,
                  "top-0 left-0 !-translate-x-1/2"
                )}
              /> */}

              <div className="flex flex-wrap justify-center gap-10">
                {ADVANTAGES_OF_PROFESSIONAL_INSTALLATION.map((card) => (
                  <SectionCard key={card.id} {...card} />
                ))}
              </div>
            </Section>
          </div>

          <WhenToCallUsSection
            imageClassname="max-h-176.75 object-top"
            title="Our Appliance Installation Services"
            description="Smartchoice Service offers expert installation services for various appliances, including:"
            image={pageData?.when_to_call_us}
            items={OUR_APPLIANCE_INSTALLATION_SERVICES}
            onButtonClick={bookNow.openModal}
          />

          <WhenToCallUsSection
            className="gl:!flex-row-reverse"
            imageClassname="max-h-90 object-top"
            title="Why Choose Smartchoice Service?"
            description="Choose Smartchoice Service for your appliance installation needs because:"
            image={pageData?.why_smartchoise}
            items={WHY_CHOOSE_SMARTCHOICE_SERVICE_INSTALATION}
            isShowBookOrcall={false}
          />

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden top-0 left-0 !-translate-x-1/2 gl:block"
              )}
            /> */}

            <AppointmentBooking
              className="py-25"
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
            />
          </div>
        </div>

        <ContactUs
          image={pageData?.contact_us}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          <div className="relative">
            <GetInTouch />
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden top-0 right-0 gl:block"
              )}
            />
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden bottom-0 left-0 !-translate-x-1/2 translate-y-1/2 gl:block"
              )}
            /> */}
          </div>

          <OtherServices />

          <div className="hidden lg:block">
            <ReviewSection />
          </div>

          <Section className="wrapper lg:hidden" title="News and Tips">
            <NewsSwiper />
          </Section>
        </div>
      </PageWrapper>

      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
    </>
  );
};

export default ApplianceInstallation;
