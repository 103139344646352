import { ReactComponent as NewConstructionIcon } from "src/assets/icons/electrical-services/rough-wiring/new-construction-wiring.svg";
import { ReactComponent as RemodelingIcon } from "src/assets/icons/electrical-services/rough-wiring/remodeling-renovation-wiring.svg";
import { ReactComponent as LightingIcon } from "src/assets/icons/electrical-services/rough-wiring/lighting-circuit-installation.svg";
import { ReactComponent as OutletIcon } from "src/assets/icons/electrical-services/rough-wiring/outlet-switch-wiring.svg";
import { ReactComponent as HvacIcon } from "src/assets/icons/electrical-services/rough-wiring/hvac-appliance-wiring.svg";
import { ReactComponent as SecurityIcon } from "src/assets/icons/electrical-services/rough-wiring/security-system-wiring.svg";

export const TYPES_OF_ROUGH_WIRING = [
  {
    id: crypto.randomUUID(),
    title: "New Construction Wiring",
    icon: NewConstructionIcon,
    description:
      "Complete rough wiring for new homes, offices, and commercial buildings, ensuring all electrical systems are properly set up from the start.",
  },
  {
    id: crypto.randomUUID(),
    title: "Remodeling and Renovation Wiring",
    icon: RemodelingIcon,
    description:
      "Upgrading and reconfiguring wiring for renovations to meet current codes and support new electrical demands.",
  },
  {
    id: crypto.randomUUID(),
    title: "Lighting Circuit Installation",
    icon: LightingIcon,
    description:
      "Setting up dedicated circuits for various lighting systems, including recessed lighting, track lighting, and smart lighting solutions.",
  },
  {
    id: crypto.randomUUID(),
    title: "Outlet and Switch Wiring",
    icon: OutletIcon,
    description:
      "Installing wiring for outlets and switches in convenient and code-compliant locations throughout your property.",
  },
  {
    id: crypto.randomUUID(),
    title: "HVAC and Appliance Wiring",
    icon: HvacIcon,
    description:
      "Providing dedicated circuits and wiring for HVAC systems, kitchen appliances, and other major electrical equipment.",
  },
  {
    id: crypto.randomUUID(),
    title: "Security System Wiring",
    icon: SecurityIcon,
    description:
      "Installing wiring for alarm systems, surveillance cameras, and other security devices to ensure seamless operation.",
  },
];

export const WHY_ROUGH_WIRING_IMPORTANT = [
  {
    id: crypto.randomUUID(),
    title: "Safety",
    cardNumber: "01",
    description:
      "Correctly installed wiring minimizes the risk of electrical fires and other hazards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Functionality",
    cardNumber: "02",
    description:
      "Ensures that your electrical system can handle current and future demands efficiently.",
  },
  {
    id: crypto.randomUUID(),
    title: "Compliance",
    cardNumber: "03",
    description:
      "Meets all local building codes and standards, which is crucial for passing inspections and avoiding costly rework.",
  },
  {
    id: crypto.randomUUID(),
    title: "Futureproofing",
    cardNumber: "04",
    description:
      "Laying down a robust wiring infrastructure that can support future upgrades and technological advancements.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Experienced Electricians",
    description:
      "Our licensed electricians have extensive experience in all aspects of rough wiring, ensuring precision and adherence to all safety standards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality Materials",
    description:
      "We use only high-quality wiring and components to ensure durability and safety.",
  },
  {
    id: crypto.randomUUID(),
    title: "Attention to Detail",
    description:
      "We meticulously plan and execute each wiring project, ensuring no detail is overlooked.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction with clear communication, timely service, and a respectful approach to your property.",
  },
  {
    id: crypto.randomUUID(),
    title: "Competitive Pricing",
    description:
      "We offer transparent, competitive pricing with no hidden fees, providing excellent value for our top-notch services.",
  },
];
