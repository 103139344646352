import React from "react";
import { SectionCard } from "src/components/SectionCard";
import { CardType } from "src/@types/card";
import { PointInspectionCard } from "./PointInspectionCard";
import {
  MEMBERSHIPS_ADVANTAGES_CARDS,
  POINT_INSPECTION_CARD,
} from "./constants";

export const MembershipAdvantages = () => (
  <div className="flex flex-col gap-5">
    <SectionCard
      cardType={CardType.DOUBLE_CARD}
      title="Bi-Annual HVAC Tune-Ups"
      description="Spring/Summer Tune-Up: Prepare your system for the hot summer months with a comprehensive inspection and maintenance service. We ensure your air conditioning is running efficiently, keeping your home cool and your energy bills low."
      second_description="Fall/Winter Tune-Up: Get ready for the colder months with a thorough check-up and maintenance of your heating system. Our experts make sure your home stays warm and comfortable all winter long."
    />

    <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
      <div className="flex flex-col justify-between gap-5">
        {MEMBERSHIPS_ADVANTAGES_CARDS.map((card) => (
          <SectionCard
            key={card.id}
            cardType={CardType.MEMBERSHIP_CARD}
            {...card}
          />
        ))}
      </div>

      <PointInspectionCard
        title={POINT_INSPECTION_CARD.title}
        description={POINT_INSPECTION_CARD.description}
        blocks={POINT_INSPECTION_CARD.blocks}
      />
    </div>
  </div>
);
