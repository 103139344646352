import { ICard } from "src/@types/card";

export const SMARTCHOICE_ADVANTAGE_CARDS: ICard[] = [
  {
    id: 1,
    cardNumber: '01',
    title: 'Over 6 Years of Industry Experience',
    description: 'With more than six years in the HVAC, electrical, and appliance repair industry, SMARTCHOICE SERVICE brings a wealth of knowledge and expertise to every job. Our experience ensures that we can handle a wide variety of issues efficiently and effectively, providing solutions that stand the test of time.',
  },
  {
    id: 2,
    cardNumber: '02',
    title: 'Expert Technicians',
    description: 'Our team of expert technicians is dedicated to staying at the forefront of industry advancements. Through continuous training and professional development, we ensure that our staff is equipped with the latest skills and knowledge. This commitment to excellence guarantees top-quality service for all your HVAC, electrical, and appliance repair needs.',
  },
  {
    id: 3,
    cardNumber: '03',
    title: 'Bonded, Insured, and Guaranteed',
    description: 'At SMARTCHOICE SERVICE, your peace of mind is our priority. We are a fully bonded and insured company, ensuring that our clients are protected throughout the service process. Additionally, we provide a workmanship guarantee for both parts and labor, giving you confidence in the durability and quality of our work.',
  },
  {
    id: 4,
    cardNumber: '04',
    title: 'Commitment to Quality and Customer Satisfaction',
    description: 'We pride ourselves on delivering exceptional quality and outstanding customer service. Our goal is to exceed your expectations on every project. We strive for 100% customer satisfaction, and our team is always ready to go the extra mile to ensure you are completely happy with our work.',
  }
];