import { useEffect, useRef } from "react";
import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getPanelInstallationPageAsync } from "src/redux/services/electrical/panelInstallation/actions";
import {
  selectorPanelInstallation,
  selectorPanelInstallationIsLoading,
} from "src/redux/services/electrical/panelInstallation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { WhyChooseProfessional } from "src/page-components/Services/Electrical/PanelInstallation/WhyChooseProfessional";
import { ContactUs } from "src/page-components/Services/Electrical/PanelInstallation/ContactUs";
import { PanelInstallationServices } from "src/page-components/Services/Electrical/PanelInstallation/PanelInstallationServices";
import { WHY_CHOOSE_SMARTCHOICE_ITEMS } from "src/page-components/Services/Electrical/PanelInstallation/constants";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";

const WHY_CHOOSE_SMARTCHOICE_TITLE = "Why Choose Smartchoice Service?";
const WHY_CHOOSE_SMARTCHOICE_DESC =
  "Choose Smartchoice Service for your electrical panel installation needs because:";

const PanelInstallation = () => {
  const sectionRef = useRef(null);
  const bookNow = useModal();

  const dispatch = useAppDispatch();

  const pageData = useAppSelector(selectorPanelInstallation);
  const contactsData = useAppSelector(selectorDetails);
  const isLoading = useAppSelector(selectorPanelInstallationIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getPanelInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Expert Electrical Panel Installation for Your Home or Business"
            name="Electrical panel installation"
            image={pageData?.banner_background}
            sectionRef={sectionRef}
          >
            Installing a new electrical panel is a critical step in ensuring
            your home or business has a safe and reliable electrical system. At
            Smartchoice Service, we specialize in expert electrical panel
            installation to meet your specific needs and ensure your electrical
            setup is up to code.
          </Hero>
        )}

        <div className="container">
          <div className="relative" ref={sectionRef}>
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}
            <WhyChooseProfessional />
          </div>

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0"
              )}
            /> */}
            <PanelInstallationServices />
          </div>

          <WhenToCallUsSection
            imageClassname="max-h-120.5 object-top"
            title={WHY_CHOOSE_SMARTCHOICE_TITLE}
            description={WHY_CHOOSE_SMARTCHOICE_DESC}
            items={WHY_CHOOSE_SMARTCHOICE_ITEMS}
            image={pageData?.why_choose}
            onButtonClick={bookNow.openModal}
          />

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-40 left-0 !-translate-x-1/2"
              )}
            /> */}
            <AppointmentBooking
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
            />
          </div>
        </div>

        <ContactUs
          image={pageData?.contact_us_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
            <GetInTouch />
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            /> */}
          </div>

          <OtherServices />

          <NewsTips className="lg:hidden" />

          <div className="relative">
            {/* <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
            <ReviewSection className="hidden lg:flex" />
          </div>
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default PanelInstallation;
