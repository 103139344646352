import { ReactComponent as ElectricalInstallationsIcon } from "src/assets/icons/electrical-services/residential-services/electrical-installations.svg";
import { ReactComponent as ElectricalRepairsIcon } from "src/assets/icons/electrical-services/residential-services/electrical-repairs.svg";
import { ReactComponent as PanelUpgradesIcon } from "src/assets/icons/electrical-services/residential-services/panel-upgrades.svg";
import { ReactComponent as LightingSolutionsIcon } from "src/assets/icons/electrical-services/residential-services/lighting-solutions.svg";
import { ReactComponent as ApplianceWiringIcon } from "src/assets/icons/electrical-services/residential-services/appliance-wiring.svg";

export const OUR_RESIDENTIAL_SERVICES = [
  {
    id: crypto.randomUUID(),
    title: "Electrical Installations",
    icon: ElectricalInstallationsIcon,
    description:
      "From new lighting fixtures to complete wiring setups for home renovations.",
  },
  {
    id: crypto.randomUUID(),
    title: "Electrical Repairs",
    icon: ElectricalRepairsIcon,
    description:
      "Swift and professional repairs for outlets, switches, wiring issues, and more.",
  },
  {
    id: crypto.randomUUID(),
    title: "Panel Upgrades",
    icon: PanelUpgradesIcon,
    description:
      "Upgrading outdated panels to handle modern electrical demands and enhance safety.",
  },
  {
    id: crypto.randomUUID(),
    title: "Lighting Solutions",
    icon: LightingSolutionsIcon,
    description:
      "Installing energy-efficient lighting, outdoor lighting, and smart lighting systems.",
  },
  {
    id: crypto.randomUUID(),
    title: "Appliance Wiring",
    icon: ApplianceWiringIcon,
    description:
      "Ensuring proper wiring and connections for all your home appliances.",
  },
];

export const IMPORTANCE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Safety",
    cardNumber: "01",
    description:
      "Ensuring your family and home are protected from electrical hazards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Convenience",
    cardNumber: "02",
    description:
      "Keeping your electrical systems running smoothly without disruptions.",
  },
  {
    id: crypto.randomUUID(),
    title: "Efficiency",
    cardNumber: "03",
    description: "Maximizing energy efficiency and reducing utility costs.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Experience",
    description:
      "Our skilled electricians have extensive experience and knowledge in residential electrical systems.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality",
    description:
      "We use high-quality materials and follow industry best practices to ensure durable and reliable installations.",
  },
  {
    id: crypto.randomUUID(),
    title: "Workmanship Guaranteed",
    description:
      "We stand behind our work with a guarantee of quality craftsmanship.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Focus",
    description:
      "We prioritize your satisfaction, providing transparent communication and dependable service.",
  },
];
