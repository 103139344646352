import { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getElectricalRepairsPageAsync } from "src/redux/services/electrical/electricalRepairs/actions";
import {
  selectorElectricalRepairs,
  selectorElectricalRepairsIsLoading,
} from "src/redux/services/electrical/electricalRepairs/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { CommonReasons } from "src/page-components/Services/Electrical/ElectricalRepairs/CommonReasons";
import { ContactUs } from "src/page-components/Services/Electrical/ElectricalRepairs/ContactUs";
import { NewsTips } from "src/page-components/Home/NewsTips";
import {
  TYPES_OF_ELECTRICAL_REPAIRS,
  WHY_CHOOSE_SMARTCHOICE_ITEMS,
} from "src/page-components/Services/Electrical/ElectricalRepairs/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { ReviewSection } from "src/components/ReviewSection";
import { OtherServices } from "src/components/OtherServices";
import { GetInTouch } from "src/components/GetInTouchForm";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const TYPES_ELECTRICAL_REPAIRS_TITLE = "Types of Electrical Repairs We Offer";
const WHEN_TO_CALL_US_SECTION_DESC =
  "At Smartchoice Service, we offer a comprehensive range of electrical repair services to ensure your systems are safe, efficient, and up to code. Our skilled electricians are equipped to handle:";

const WHY_CHOOSE_SMARTCHOICE_TITLE =
  "Why Choose SMARTCHOICE for Your Electrical Needs?";
const WHY_CHOOSE_SMARTCHOICE_DESC =
  "When it comes to electrical repairs, you want a company that combines expertise, reliability, and customer-focused service. Here's why Smartchoice Service is the right choice for your electrical repair needs:";

const ElectricalRepairs = () => {
  const sectionRef = useRef(null);

  const dispatch = useAppDispatch();

  const bookNow = useModal();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorElectricalRepairs);
  const isLoading = useAppSelector(selectorElectricalRepairsIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getElectricalRepairsPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="The Importance of Reliable Electrical Systems"
            image={pageData?.banner_background}
            name="Electrical Repairs"
            sectionRef={sectionRef}
          >
            Electrical systems are the backbone of modern homes and businesses,
            providing the power needed for lighting, appliances, heating,
            cooling, and numerous other functions. Over time, electrical
            components can wear out, become outdated, or suffer damage, leading
            to a variety of issues that require professional repair.
          </Hero>
        )}

        <div className="container">
          <div >
            <WhenToCallUsSection
              imageClassname="sm:max-h-155 gl:max-h-full object-center-30"
              title={TYPES_ELECTRICAL_REPAIRS_TITLE}
              description={WHEN_TO_CALL_US_SECTION_DESC}
              items={TYPES_OF_ELECTRICAL_REPAIRS}
              image={pageData?.types}
              isShowBookOrcall={false}
            />
          </div>

          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />

          {/* <div className="relative"> */}
          <CommonReasons />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 !-left-14 !-translate-x-1/2"
              )}
            />
          </div> */}

          <WhenToCallUsSection
            className="gl:!flex-row-reverse"
            title={WHY_CHOOSE_SMARTCHOICE_TITLE}
            description={WHY_CHOOSE_SMARTCHOICE_DESC}
            items={WHY_CHOOSE_SMARTCHOICE_ITEMS}
            image={pageData?.why_choose}
            onButtonClick={bookNow.openModal}
          />
        </div>

        <ContactUs
          image={pageData?.contact_us_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}
          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default ElectricalRepairs;
