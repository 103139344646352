import React, { FC } from "react";
import { Link } from "src/components/Link";
import { ILink } from "src/@types";
import { ReactComponent as PhoneIcon } from "src/assets/icons/cards/phone-card-icon.svg";
import { ReactComponent as MailIcon } from "src/assets/icons/cards/email-card-icon.svg";
import { ReactComponent as HeadphonesIcon } from "src/assets/icons/cards/headphones-card-icon.svg";

interface Props {
  contacts: ILink[];
  isHiddenPage?: boolean;
}

export const ContactsList: FC<Props> = ({ contacts, isHiddenPage = false }) => (
  <div className="flex justify-center flex-wrap gap-10">
    <div
      className="p-px rounded-10 border border-yellow-base border-opacity-50"
      // "md:max-w-77.5"
    >
      <div className="py-5 px-6 w-full h-full bg-black-base rounded-card">
        <div className="flex justify-center items-center mx-auto mb-2">
          <PhoneIcon />
        </div>

        <h4 className="text-yellow-base text-2xl lg:text-xl font-bold mb-2 text-center">
          Phone
        </h4>
        <Link
          className="font-bold hover:underline"
          href={isHiddenPage ? contacts[3]?.href : contacts[1]?.href}
        >
          {isHiddenPage ? contacts[3]?.label : contacts[1]?.label}
        </Link>
      </div>
    </div>

    <div
      className="p-px rounded-10 border border-yellow-base border-opacity-50"
      // "md:max-w-77.5"
    >
      <div className="py-5 px-6 w-full h-full bg-black-base rounded-card">
        <div className="flex justify-center items-center mx-auto mb-2">
          <MailIcon />
        </div>

        <h4 className="text-yellow-base text-2xl lg:text-xl font-bold mb-2 text-center">
          e-mail
        </h4>
        <Link className="font-bold hover:underline" href={contacts[0]?.href}>
          {contacts[0]?.label}
        </Link>
      </div>
    </div>

    <div
      className="p-px rounded-10 border border-yellow-base border-opacity-50"
      // "md:max-w-77.5"
    >
      <div className="py-5 px-6 w-full h-full bg-black-base rounded-card">
        <div className="flex justify-center items-center mx-auto mb-2">
          <HeadphonesIcon />
        </div>

        <h4 className="text-yellow-base text-2xl lg:text-xl font-bold mb-2 text-center">
          Toll free
        </h4>
        <Link className="font-bold hover:underline" href={contacts[2]?.href}>
          {contacts[2]?.label}
        </Link>
      </div>
    </div>
  </div>
);
