import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorCareerState = (state: RootState) => state.career;

export const selectorCareer = createSelector(
  selectorCareerState,
  (careerState) => careerState.data
);

export const selectorCareerIsLoading = createSelector(
  selectorCareerState,
  (careerState) => careerState.isLoading
);
