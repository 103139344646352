import { useAppSelector } from "src/hooks/redux";
import { selectorCareer } from "src/redux/career/selectors";
import { Section } from "src/components/Section";
import { CareerSwiper } from "./CareerSwiper";

const SECTION_TITLE = "CAREER";

export const CareerSection = () => {
  const careerList = useAppSelector(selectorCareer);

  if (!careerList?.results) return null;

  return (
    <Section className="py-25" title={SECTION_TITLE}>
      <CareerSwiper items={careerList?.results} />
    </Section>
  );
};
