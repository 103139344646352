import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { BENEFITS } from "./constants";

export const FurnaceSystems = () => (
  <Section
    title="Types of Furnace Systems We Repair"
    className="wrapper mx-auto"
  >
    <div className="max-w-250 mx-auto">
      <p className="text-base mb-5 lg:mb-10">
        At Smartchoice Service, we specialize in repairing a variety of furnace
        systems to keep your home warm and comfortable during the colder months.
        <br />
        <br />
        Our team of expert technicians is equipped to handle repairs for several
        types of furnace systems, including:
      </p>
    </div>

    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {BENEFITS.map((card) => (
        <SectionCard key={card.id} {...card} />
      ))}
    </div>
  </Section>
);
