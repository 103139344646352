import React, { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getResidentialServicesPageAsync } from "src/redux/services/electrical/residentialServices/actions";
import {
  selectorResidentialServices,
  selectorResidentialServicesIsLoading,
} from "src/redux/services/electrical/residentialServices/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { OurResidentialServices } from "src/page-components/Services/Electrical/ResidentialServices/OurResidentialServices";
import { Importance } from "src/page-components/Services/Electrical/ResidentialServices/Importance";
import { ContactUs } from "src/page-components/Services/Electrical/ResidentialServices/ContactUs";
import { WHY_CHOOSE_SMARTCHOICE_ITEMS } from "src/page-components/Services/Electrical/ResidentialServices/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const WHY_CHOOSE_SMARTCHOICE_TITLE = "Why Choose Smartchoice Service?";
const WHY_CHOOSE_SMARTCHOICE_DESC =
  "Choose Smartchoice Service for your residential electrical needs because:";

const ResidentialServices = () => {
  const sectionRef = useRef(null);
  const bookNow = useModal();

  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorResidentialServices);
  const isLoading = useAppSelector(selectorResidentialServicesIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getResidentialServicesPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Ensuring Your Home's Electrical Systems Run Smoothly"
            name="Residential electrical Services"
            image={pageData?.banner_background}
            sectionRef={sectionRef}
          >
            Residential electrical systems are the backbone of modern living,
            powering everything from lights to appliances. At Smartchoice
            Service, we specialize in providing reliable and efficient
            electrical services tailored to meet the unique needs of homeowners.
            Whether you require installations, repairs, or upgrades, our team is
            dedicated to delivering top-quality service and ensuring your
            electrical systems operate safely and effectively.
          </Hero>
        )}

        <div className="container">
          <div className="relative" ref={sectionRef}>
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0"
              )}
            /> */}
            <OurResidentialServices />
          </div>

          {/* <div className="relative"> */}
          <Importance />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            />
          </div> */}

          <WhenToCallUsSection
            imageClassname="max-h-120.5 object-top"
            title={WHY_CHOOSE_SMARTCHOICE_TITLE}
            description={WHY_CHOOSE_SMARTCHOICE_DESC}
            items={WHY_CHOOSE_SMARTCHOICE_ITEMS}
            image={pageData?.why_choose}
            onButtonClick={bookNow.openModal}
          />

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-40 left-0 !-translate-x-1/2"
              )}
            /> */}
          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />
          {/* </div> */}
        </div>

        <ContactUs
          image={pageData?.contact_us_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}

          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default ResidentialServices;
