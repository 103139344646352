import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { OUR_RESIDENTIAL_SERVICES } from "./constants";

const SECTION_TITLE = "Our Residential Electrical Services";
const SECTION_DESC =
  "Smartchoice Service offers a wide range of residential electrical services to enhance your home:";

export const OurResidentialServices = () => (
  <Section
    className="wrapper"
    descriptionClassName="mx-auto mb-5 lg:mb-10"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {OUR_RESIDENTIAL_SERVICES.map((service) => (
        <SectionCard key={service.id} {...service} />
      ))}
    </div>
  </Section>
);
