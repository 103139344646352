import { ReactComponent as EnergyEfficiencyIcon } from "src/assets/icons/hvac-services/heat-pump-installation/energy-efficiency.svg";
import { ReactComponent as YearRoundComfortIcon } from "src/assets/icons/hvac-services/heat-pump-installation/year-round-comfort.svg";
import { ReactComponent as ImprovedIndoorAirQualityIcon } from "src/assets/icons/hvac-services/heat-pump-installation/improved-indoor-air-quality.svg";
import { ReactComponent as SpaceSavingDesignIcon } from "src/assets/icons/hvac-services/heat-pump-installation/space-saving-design.svg";

export const WHY_CHOOSE_SMARTCHOICE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Expertise",
    cardNumber: "01",
    description:
      "With years of experience and a team of highly skilled technicians, we ensure precise and efficient heat pump installations tailored to your specific requirements.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality Products",
    cardNumber: "02",
    description:
      "We only work with top-quality heat pump systems from leading manufacturers, ensuring durability, reliability, and optimal performance for years to come.",
  },
  {
    id: crypto.randomUUID(),
    title: "Personalized Service",
    cardNumber: "03",
    description:
      "Our customer-centric approach means we take the time to understand your unique needs and preferences, offering customized solutions that exceed your expectations.",
  },
  {
    id: crypto.randomUUID(),
    title: "Professionalism",
    cardNumber: "04",
    description:
      "From initial consultation to final installation, our team maintains the highest standards of professionalism, ensuring a seamless and stress-free experience for our customers.",
  },
];

export const WHY_HEAT_PUMPS_ARE_BETTER_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Energy Efficiency",
    icon: EnergyEfficiencyIcon,
    description:
      "Heat pumps are highly efficient, providing both heating and cooling capabilities using minimal energy. This translates to lower utility bills and reduced environmental impact.",
  },
  {
    id: crypto.randomUUID(),
    title: "Year-Round Comfort",
    icon: YearRoundComfortIcon,
    description:
      "Unlike traditional units that only provide either heating or cooling, heat pumps offer year-round comfort by effectively regulating indoor temperatures in all seasons.",
  },
  {
    id: crypto.randomUUID(),
    title: "Improved Indoor Air Quality",
    icon: ImprovedIndoorAirQualityIcon,
    description:
      "Heat pumps come with advanced filtration systems that remove pollutants and allergens from indoor air, creating a healthier and more comfortable living or working environment.",
  },
  {
    id: crypto.randomUUID(),
    title: "Space-Saving Design",
    icon: SpaceSavingDesignIcon,
    description:
      "Heat pumps are compact and versatile, requiring less space for installation compared to traditional HVAC systems, making them suitable for properties with limited space.",
  },
];

export const OUR_GUARANTEES_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Quality Workmanship",
    description:
      "We adhere to the highest industry standards and use best practices to ensure flawless installation and reliable repairs.",
  },
  {
    id: crypto.randomUUID(),
    title: "Warranty Coverage",
    description:
      "Our heat pump installations come with comprehensive warranty coverage, providing you with peace of mind and protection against unexpected issues.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Support",
    description:
      "Our dedicated customer support team is available to address any questions or concerns you may have before, during, and after the installation process.",
  },
  {
    id: crypto.randomUUID(),
    title: "Satisfaction Guarantee",
    description:
      "We are committed to your satisfaction, and we won't consider the job complete until you are fully satisfied with the results.",
  },
];
