import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { WHY_HEAT_PUMPS_ARE_BETTER_ITEMS } from "./constants";

const SECTION_TITLE = "Why Heat Pumps Are Better Than Regular Units?";
const SECTION_DESC =
  "Heat pumps offer numerous advantages over traditional HVAC systems, making them an ideal choice for both residential and commercial properties:";

export const WhyHeatPumpsAreBetter = () => (
  <Section
    className="wrapper mx-auto"
    descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10 lg:text-start"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {WHY_HEAT_PUMPS_ARE_BETTER_ITEMS.map((item) => (
        <SectionCard key={item.id} {...item} />
      ))}
    </div>
  </Section>
);
