import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getCurrentCareerPostAsync } from "src/redux/currentCareerPost/actions";
import { getCareerAsync } from "src/redux/career/actions";
import {
  selectorCurrentCareerIsLoading,
  selectorCurrentCareerPost,
} from "src/redux/currentCareerPost/selectors";
import { CareerSection } from "src/page-components/Career/Post/CareerSection";
// import { CAREER_POST } from "src/page-components/Career/Post/constants";
import { DetailsCareerPost } from "src/page-components/Career/Post/DetailsCareerPost";
import { ApplyBelowForm } from "src/page-components/Career/Post/ApplyBelowForm";
// import { DEFAULT_YELLOW_BLUR } from "src/page-components/home/HeroSwiper/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { PATHNAMES } from "src/constants/routes";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
import { selectorCareer } from "src/redux/career/selectors";

const CareerPost = () => {
  const { slug } = useParams();

  const dispatch = useAppDispatch();

  const careerPostData = useAppSelector(selectorCurrentCareerPost);
  const isLoading = useAppSelector(selectorCurrentCareerIsLoading);
  const careerList = useAppSelector(selectorCareer);

  useEffect(() => {
    dispatch(getCurrentCareerPostAsync(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    if (!careerList?.results) {
      dispatch(getCareerAsync(1));
    }
  }, [dispatch]);

  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { path: PATHNAMES.CAREER, name: "Career" },
    { name: careerPostData?.title },
  ];

  return (
    <PageWrapper isShownMaps={false}>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-96">
          <Loader size={Sizes.L} />
        </div>
      ) : (
        <div className="container relative px-5 lg:px-17.5">
          {/* <div className={DEFAULT_YELLOW_BLUR} /> */}
          <BreadCrumbs className="mt-8" breadCrumbsItems={breadCrumbsItems} />

          <div className="flex flex-col gap-10 lg:flex-row lg:justify-between">
            {careerPostData && <DetailsCareerPost {...careerPostData} />}
            <ApplyBelowForm />
          </div>

          <CareerSection />
        </div>
      )}
    </PageWrapper>
  );
};

export default CareerPost;
