import { SectionCard } from "src/components/SectionCard";
import { CardType } from "src/@types/card";
import { SERVICES_CARDS } from "./constants";

export const ServicesList = () => (
  <div className="flex flex-col md:flex-row md:flex-wrap justify-center gap-10">
    {SERVICES_CARDS.map((card) => (
      <SectionCard
        key={card.id}
        cardType={CardType.SERVICE_CARD}
        {...card}
        containerClassName="mx-auto md:mx-0"
      />
    ))}
  </div>
);
