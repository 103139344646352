import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Link } from "src/components/Link";
import { Section } from "src/components/Section";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "Join Today!";

interface Props extends ISectionWithImage {}

export const JoinToday: FC<Props> = ({ image, phone }) => (
  <ContentOverlay bg={image} bgPositionClassName="object-center-30">
    <Section
      className="wrapper"
      titleClassName="text-yellow-base"
      title={SECTION_TITLE}
    >
      <div className="max-w-4xl">
        <p className="md:text-center">
          Don’t wait until there’s a problem—stay ahead with proactive
          maintenance. Sign up for the Smartchoice Service Membership Program
          today and keep your HVAC system running smoothly all year round.
          <br />
          <br />
          <Link
            className="text-yellow-base font-bold transition-all duration-300 hover:underline"
            href={phone?.href}
          >
            Contact Us
          </Link>
          &nbsp; to learn more and become a member!
        </p>
      </div>
    </Section>
  </ContentOverlay>
);
