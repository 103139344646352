import { FC, useEffect } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getServiceAreaAsync } from "src/redux/serviceArea/actions";
import {
  selectServiceArea,
  selectServiceAreaIsLoading,
} from "src/redux/serviceArea/selectors";
import { ServiceRegionList } from "src/page-components/ServiceArea/ServiceReagionList";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { GoogleMaps } from "src/components/GoogleMaps";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Section } from "src/components/Section";
import { Loader } from "src/components/Loader";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const ServiceArea: FC = () => {
  const dispatch = useAppDispatch();

  const pageData = useAppSelector(selectServiceArea);
  const isLoading = useAppSelector(selectServiceAreaIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getServiceAreaAsync());
    }
  }, []);

  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    {
      name: "Service area",
    },
  ];

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper isShownMaps={false}>
        <div className="relative flex justify-center py-20">
          <BreadCrumbs
            className="absolute top-8 left-5 sm:left-17.5 "
            breadCrumbsItems={breadCrumbsItems}
          />

          <div className="px-5 w-full sm:max-w-160 sm:p-0">
            <h3 className="mb-6 text-5xl font-bebas text-center">
              Service Area
            </h3>
            <p className="text-16 font-manrope">
              Welcome to
              <span className="text-yellow-base">
                &nbsp; Smartchoice Service Inc.
              </span>
              , your trusted provider of HVAC, Electrical, and Appliance repair
              services across Orange County and Los Angeles County. At &nbsp;
              <span className="text-yellow-base">Smartchoice Service</span>, we
              are committed to delivering exceptional quality and reliability to
              every home and business we serve. Explore our service areas below
              to discover how we can assist you with expert solutions tailored
              to your needs.
            </p>
          </div>
        </div>

        <GoogleMaps />

        <Section
          titleClassName="mb-8 sm:mb-5 gl:mb-15"
          className="wrapper relative container"
          title="Choose the service regions"
        >
          {/* <div
            className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0 !translate-x-0")}
          />
          <div
            className={cn(
              DEFAULT_YELLOW_BLUR,
              "!bottom-0 !left-0 !-translate-x-1/2 !translate-y-1/2"
            )}
          /> */}
          {isLoading ? (
            <Loader size={Sizes.L} />
          ) : (
            <div className="flex flex-col justify-between md:justify-start sm:flex-row sm:gap-10">
              <ServiceRegionList state={pageData?.california} />
              <div className="w-4/5 h-px mx-auto border-t border-t-grey-dark sm:hidden" />
              <div className="hidden sm:block w-px min-h-full mx-auto bg-gray-dark" />
              <ServiceRegionList state={pageData?.washington} />
            </div>
          )}
        </Section>
      </PageWrapper>
    </>
  );
};

export default ServiceArea;
