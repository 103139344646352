export const WHY_UPGRADE_FURNACE_IMPORTANT = [
  {
    id: crypto.randomUUID(),
    title: "Enhanced Efficiency",
    description:
      "Older furnaces tend to lose efficiency over time, resulting in higher energy bills. Modern furnaces, especially those that are ENERGY STAR certified, offer superior energy efficiency, significantly reducing your heating costs.",
  },
  {
    id: crypto.randomUUID(),
    title: "Improved Comfort",
    description:
      "New furnaces provide more consistent and reliable heating, eliminating cold spots and maintaining a comfortable temperature throughout your home.",
  },
  {
    id: crypto.randomUUID(),
    title: "Increased Reliability",
    description:
      "Older furnaces are more prone to breakdowns and costly repairs. A new furnace reduces the risk of unexpected failures, ensuring your home stays warm when you need it most.",
  },
  {
    id: crypto.randomUUID(),
    title: "Better Indoor Air Quality",
    description:
      "Modern furnaces come with advanced filtration systems that improve indoor air quality by removing dust, allergens, and other pollutants.",
  },
  {
    id: crypto.randomUUID(),
    title: "Environmental Benefits",
    description:
      "New, energy-efficient furnaces reduce your home's carbon footprint, contributing to a healthier environment.",
  },
];

export const BENEFITS_OF_NEW_FURNACE = [
  {
    id: crypto.randomUUID(),
    title: "Energy Savings",
    description:
      "With a new ENERGY STAR-qualified furnace, you can save significantly on your energy bills each year.",
    cardNumber: "01",
  },
  {
    id: crypto.randomUUID(),
    title: "Enhanced Comfort",
    description:
      "Experience consistent, reliable heating throughout your home.",
    cardNumber: "02",
  },
  {
    id: crypto.randomUUID(),
    title: "Reduced Maintenance",
    description:
      "New furnaces require less maintenance and are less likely to need repairs, saving you time and money.",
    cardNumber: "03",
  },
  {
    id: crypto.randomUUID(),
    title: "Long-Term Investment",
    description:
      "Investing in a new furnace increases your home's value and provides long-term savings on energy and repair costs.",
    cardNumber: "04",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_FURNACE_INSTALLATION = [
  {
    id: crypto.randomUUID(),
    title: "Expertise and Professionalism",
    areaText: [
      {
        subTitle: "Highly Skilled Technicians",
        description:
          "Our certified technicians have extensive experience and training, ensuring your furnace is installed correctly and operates efficiently.",
      },
    ],
  },
  {
    title: "Comprehensive Guarantees",
    areaText: [
      {
        subTitle: "Satisfaction Guarantee",
        description:
          "We prioritize your satisfaction and ensure our services meet your expectations.",
      },
      {
        subTitle: "Long-Term Warranties",
        description:
          "We work with top manufacturers that offer extensive warranties, ranging from 5 to 10 years, providing long-term protection for your investment.",
      },
      {
        subTitle: "Performance Guarantee",
        description:
          "If your furnace doesn't perform as expected, we address the issue promptly at no extra cost.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Superior Products",
    areaText: [
      {
        subTitle: "Leading Brands",
        description:
          "We install high-quality furnaces from top manufacturers, known for their reliability and efficiency.",
      },
      {
        subTitle: "ENERGY STAR Qualified Systems",
        description:
          "Our ENERGY STAR-certified furnaces offer exceptional energy efficiency, helping you save on energy bills and reduce environmental impact.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Comprehensive Service Coverage",
    areaText: [
      {
        subTitle: "Wide Service Area",
        description:
          "We proudly serve clients in Orange County, Los Angeles County in California, and Pierce County and King County in Washington State.",
      },
      {
        subTitle: "Full-Service Support",
        description:
          "From initial consultation to post-installation care, we provide complete support to ensure a seamless experience.",
      },
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    areaText: [
      {
        subTitle: "Tailored Solutions",
        description:
          "We assess your specific heating needs and recommend the best furnace system for your home or business.",
      },
      {
        subTitle: "Transparent Pricing",
        description:
          "We offer competitive pricing with no hidden fees, and provide detailed estimates before beginning any work.",
      },
    ],
  },
];
