import { WhenToCallUsItem } from "src/components/WhenToCallUsSection/types";

export const FURNACE_REPAIR_WHEN_TO_CALL_US: WhenToCallUsItem[] = [
  {
    id: crypto.randomUUID(),
    title: "Insufficient Heating",
    description:
      "If your furnace is struggling to maintain a comfortable temperature in your home, it may be a sign of a malfunctioning component such as a faulty thermostat, igniter, or blower motor.",
  },
  {
    id: crypto.randomUUID(),
    title: "Strange Noises",
    description:
      "Unusual sounds coming from your furnace, such as banging, rattling, or squealing, could indicate mechanical problems or loose components that require immediate attention.",
  },
  {
    id: crypto.randomUUID(),
    title: "Frequent Cycling",
    description:
      "If your furnace is cycling on and off more frequently than usual, it may be a sign of an issue with the thermostat, air filter, or airflow, which can lead to inefficiency and increased energy consumption.",
  },
  {
    id: crypto.randomUUID(),
    title: "Unusual Odors",
    description:
      "Foul or burning odors emanating from your furnace could indicate a variety of issues, including overheating, electrical problems, or a buildup of dust and debris within the system.",
  },
  {
    id: crypto.randomUUID(),
    title: "Visible Damage or Leaks",
    description:
      "Any visible signs of damage, corrosion, or leaks around your furnace should be addressed promptly to prevent further deterioration and potential safety hazards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Inconsistent Temperatures",
    description:
      "If you experience inconsistent temperatures throughout your home, with some areas being significantly colder than others, it may indicate an issue with your heating system’s distribution.",
  },
];

export const BENEFITS = [
  {
    id: 1,
    cardNumber: "01",
    title: "Gas Furnaces",
    description:
      "Gas furnaces are a popular choice for heating homes, utilizing natural gas to generate heat. Our technicians are trained to diagnose and repair issues related to gas burners, igniters, thermocouples, and other components to ensure safe and efficient operation.",
  },
  {
    id: 2,
    cardNumber: "02",
    title: "Electric Furnaces",
    description:
      "Electric furnaces use electricity to generate heat, making them a viable option for homes without access to natural gas. We have the expertise to troubleshoot and repair electrical components such as heating elements, relays, and circuit boards to restore proper functionality.",
  },
  {
    id: 3,
    cardNumber: "03",
    title: "Oil Furnaces",
    description:
      "Oil furnaces are commonly found in older homes and rely on heating oil to produce heat. Our technicians are experienced in diagnosing and repairing issues with oil burners, fuel pumps, filters, and other components to keep your oil furnace running smoothly.",
  },
  {
    id: 4,
    cardNumber: "04",
    title: "Dual-Fuel Systems",
    description:
      "Dual-fuel systems offer the flexibility of using both gas and electricity for heating, providing efficiency and reliability. We have the knowledge and skills to repair and maintain dual-fuel systems to ensure optimal performance and comfort.",
  },
];
