import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { Link } from "src/components/Link";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "Contact Us for Expert Electrical Repairs";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => (
  <div className="relative">
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper gl:!px-0"
        titleClassName="text-yellow-base"
        title={SECTION_TITLE}
      >
        <div className="mx-auto max-w-215 md:text-center font-medium">
          <p>
            Don't let electrical issues disrupt your life. Trust{" "}
            <span className="text-yellow-base">Smartchoice Service</span> for
            all your electrical repair needs. Whether it's a minor fix or a
            major problem, our skilled team is here to help.
          </p>
          <br />

          <p className="font-semibold">
            Call{" "}
            <Link
              href={phone?.href}
              className="text-yellow-base font-bold hover:underline"
            >
              {phone?.label}
            </Link>{" "}
            today to schedule an appointment or get emergency repair services.
          </p>

          <br />
          <p>
            Our friendly staff is ready to assist you and ensure your electrical
            systems are safe and efficient. Choose{" "}
            <span className="text-yellow-base">Smartchoice Service</span> for
            reliable and professional electrical solutions!
          </p>
        </div>
      </Section>
    </ContentOverlay>
  </div>
);
