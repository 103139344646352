import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorNewsTipsState = (state: RootState) => state.newsTips;

export const selectorNewsTips = createSelector(
  selectorNewsTipsState,
  (newsTipsState) => newsTipsState.data
);

export const selectorNewsTipstIsLoading = createSelector(
  selectorNewsTipsState,
  (newsTipsState) => newsTipsState.isLoading
);
