import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectCouponsState = (state: RootState) => state.coupons;

export const selectCouponsIsLoading = createSelector(
  selectCouponsState,
  (couponsState) => couponsState.isLoading
);

export const selectCoupons = createSelector(
  selectCouponsState,
  (couponsState) => couponsState.couponsData
);
