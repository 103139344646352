import { FC, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useAppDispatch } from "src/hooks/redux";
import { getDetailsAsync } from "src/redux/details/actions";
import {
  Home,
  HiddenHome,
  ACRepair,
  NewsTips,
  NewsTipsPost,
  Career,
  CareerPost,
  Membership,
  NotFound,
  ServiceArea,
  FurnaceRepair,
  ElectricalRepairs,
  ResidentialServices,
  HVACMaintenance,
  MajorRepair,
  Maintenance,
  RoughWiring,
  ApplianceInstallation,
  BreakerInstallation,
  FurnanceInstallation,
  AcInstallation,
  EVChargerInstallation,
  PanelInstallation,
  HeatPumpInstallation,
  ServiceAreaRegion,
  PrivacyPolicy,
  TermsOfUse,
} from "src/pages";
import { PATHNAMES } from "src/constants/routes";

const ROUTES = [
  {
    element: <Home />,
    path: PATHNAMES.HOME,
  },
  {
    element: <HiddenHome />,
    path: PATHNAMES.HIDDEN_HOME,
  },

  {
    element: <ElectricalRepairs />,
    path: PATHNAMES.ELECTRICAL_REPAIRS,
  },
  {
    element: <BreakerInstallation />,
    path: PATHNAMES.ELECTRICAL_BREAKER_INSTALLATION,
  },
  {
    element: <PanelInstallation />,
    path: PATHNAMES.ELECTRICAL_PANEL_INSTALLATION,
  },
  {
    element: <RoughWiring />,
    path: PATHNAMES.ROUGH_WIRING,
  },
  {
    element: <ResidentialServices />,
    path: PATHNAMES.RESIDENTIAL_ELECTRICAL_SERVICES,
  },
  {
    element: <EVChargerInstallation />,
    path: PATHNAMES.EV_CHARGER_INSTALLATION,
  },

  {
    element: <NewsTips />,
    path: PATHNAMES.NEWS_TIPS,
  },
  {
    element: <NewsTipsPost />,
    path: PATHNAMES.NEWS_TIPS_POST,
  },
  {
    element: <Career />,
    path: PATHNAMES.CAREER,
  },
  {
    element: <CareerPost />,
    path: PATHNAMES.CAREER_POST,
  },
  {
    element: <Membership />,
    path: PATHNAMES.MEMBERSHIP,
  },
  {
    element: <ServiceArea />,
    path: PATHNAMES.SERVICE_AREA,
  },
  {
    element: <ServiceAreaRegion />,
    path: PATHNAMES.SERVICE_AREA_REGION,
  },
  {
    element: <ACRepair />,
    path: PATHNAMES.AC_REPAIR,
  },
  {
    element: <HVACMaintenance />,
    path: PATHNAMES.HVAC_MAINTENANCE,
  },
  {
    element: <AcInstallation />,
    path: PATHNAMES.AC_INSTALLATION,
  },
  {
    element: <FurnaceRepair />,
    path: PATHNAMES.FURNACE_REPAIR,
  },
  {
    element: <FurnanceInstallation />,
    path: PATHNAMES.FURNACE_INSTALLATION,
  },
  {
    element: <HeatPumpInstallation />,
    path: PATHNAMES.HEAT_PUMP_INSTALLATION,
  },

  {
    element: <MajorRepair />,
    path: PATHNAMES.MAJOR_APPLIANCE_REPAIR,
  },
  {
    element: <Maintenance />,
    path: PATHNAMES.APPLIANCE_MAINTENANCE,
  },
  {
    element: <ApplianceInstallation />,
    path: PATHNAMES.APPLIANCE_INSTALLATION,
  },

  {
    element: <PrivacyPolicy />,
    path: PATHNAMES.PRIVACY_POLICY,
  },
  {
    element: <TermsOfUse />,
    path: PATHNAMES.TERMS_OF_USE,
  },
  {
    element: <NotFound />,
    path: PATHNAMES.NOT_FOUND,
  },
];

const AppRoutes: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDetailsAsync());
  }, [dispatch]);

  return useRoutes(ROUTES);
};

export default AppRoutes;
