import React from "react";
import cn from "classnames";
import { Section } from "src/components/Section";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { MembershipAdvantages } from "./MembershipAdvantages";

const SECTION_TITLE = "Why Join Our Membership Program?";

export const WhyJoin = () => (
  <Section className="wrapper sm:py-20 relative" title={SECTION_TITLE}>
    <MembershipAdvantages />

    {/* <div
      className={cn(
        DEFAULT_YELLOW_BLUR,
        "!left-0 !bottom-30 -z-10 !-translate-x-1/2"
      )}
    /> */}
  </Section>
);
