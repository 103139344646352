import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { Link } from "src/components/Link";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE =
  "Ready to Upgrade Your HVAC System with Smartchoice Service?";

interface Props extends ISectionWithImage {}

export const ReadyUpgrade: FC<Props> = ({ image, phone }) => (
  <div className="relative">
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper"
        titleClassName="text-yellow-base max-w-215"
        title={SECTION_TITLE}
      >
        <div className="mx-auto max-w-215 md:text-center font-bold">
          <p>
            Don't wait any longer to experience the benefits of a high-quality
            heat pump installation from{" "}
            <span className="text-yellow-base">Smartchoice Service</span> . Get
            in touch with us today to schedule a consultation or request a
            quote. Our friendly and knowledgeable team is here to assist you
            every step of the way. Contact us by phone at{" "}
            <Link
              href={phone?.href}
              className="text-yellow-base font-bold hover:underline"
            >
              {phone?.label}.
            </Link>{" "}
          </p>
          <br />

          <p>
            Thank you for considering Smartchoice Service for all your HVAC
            needs!
          </p>
        </div>
      </Section>
    </ContentOverlay>
  </div>
);
