import PersonImg from "src/assets/images/review-people-images/person-1.png";
import { IReviewCard } from "./types";

export const DEFAULT_REVIEW_WRAPPER_CLASSNAME =
  "flex flex-col gap-y-8 gl:flex-row gl:items-start gl:justify-between gl:gap-x-10";

export const GOOGLE_REVIEW_CARDS: IReviewCard[] = [
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "google",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "google",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "google",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "google",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "google",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "google",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
];

export const YELP_REVIEW_CARDS: IReviewCard[] = [
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "yelp",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "yelp",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "yelp",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "yelp",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "yelp",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
  {
    id: crypto.randomUUID(),
    icon: PersonImg,
    name: "Max T.",
    date: "2024-04-07",
    cardType: "yelp",
    ratingValue: 4,
    description:
      "My dryer wasn't producing heat and making alot of loud noises. Smart choice sent out Technican named Bogdan. He was very professional, respectful and knowledgeable. He communicated everything that needed and showed what he was doing. My dryer is working great again. Smart choice and Bogdan would be my top pick when it comes to appliance repair. Can't recommend them enough.",
  },
];
