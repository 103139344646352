import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectServiceAreaRegionState = (state: RootState) =>
  state.serviceAreaRegion;

export const selectServiceAreaRegion = createSelector(
  selectServiceAreaRegionState,
  (serviceAreaRegionState) => serviceAreaRegionState.data
);

export const selectServiceAreaRegionIsLoading = createSelector(
  selectServiceAreaRegionState,
  (serviceAreaRegionState) => serviceAreaRegionState.isLoading
);
