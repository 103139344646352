import { ReactComponent as CommercialHVACIcon } from "src/assets/icons/hvac-services/commercial-hvac.svg";
import { ReactComponent as WindowAirIcon } from "src/assets/icons/hvac-services/window-air-conditioning.svg";
import { ReactComponent as DuctlessIcon } from "src/assets/icons/hvac-services/ductless-mini-split.svg";
import { ReactComponent as CentralAirIcon } from "src/assets/icons/hvac-services/central-air-conditioning.svg";

export const TYPES_AIR_CINDITIONING_SYSTEM = [
  {
    id: 453456346,
    title: " Central Air Conditioning Systems",
    icon: CentralAirIcon,
    description:
      "These systems use ducts to distribute cooled air throughout the entire building. They are efficient for cooling larger spaces and are controlled by a central thermostat.",
  },
  {
    id: 142352,
    title: "Ductless Mini-Split Systems ",
    icon: DuctlessIcon,
    description:
      "Ideal for homes without ductwork or for room additions, ductless mini-split systems consist of an outdoor compressor unit and one or more indoor air-handling units. They offer flexible cooling options and are energy-efficient.",
  },
  {
    id: 456234,
    title: "Window Air Conditioning Units ",
    icon: WindowAirIcon,
    description:
      "Commonly found in apartments or single rooms, window AC units are self-contained systems      installed in windows or through walls. They are cost-effective and easy to install but may be less efficient for cooling larger spaces.",
  },
  {
    id: 568345,
    title: "Commercial HVAC Systems ",
    icon: CommercialHVACIcon,
    description:
      "Designed for larger commercial or industrial spaces, commercial HVAC systems provide heating, ventilation, and air conditioning. They are complex systems that require specialized knowledge for repair and maintenance.",
  },
];
