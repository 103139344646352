import React, { FC } from "react";
import cn from "classnames";
import { WhenToCallUsItem } from "./types";

interface Props extends Omit<WhenToCallUsItem, "id"> {
  isOrderedList?: boolean;
}

export const ListItem: FC<Props> = ({
  title,
  description,
  subtitle,
  isOrderedList,
}) => (
  <li>
    <h4
      className={cn("text-2xl text-yellow-base font-manrope font-bold pb-2", {
        inline: isOrderedList,
      })}
    >
      {title}
    </h4>

    {Boolean(subtitle) && (
      <span className="text-xl font-bold font-arial block">{subtitle}</span>
    )}

    <p className="text-base font-arial !text-white">{description}</p>
  </li>
);
