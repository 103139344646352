import React, { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getMaintenancePageAsync } from "src/redux/services/hvac/maintenance/actions";
import {
  selectorMaintenance,
  selectorMaintenanceIsLoading,
} from "src/redux/services/hvac/maintenance/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { RegularMaintenance } from "src/page-components/Services/HVAC/HVACMaintenance/RegularMaintenance";
import { ReadyToHave } from "src/page-components/Services/HVAC/HVACMaintenance/ReadyToHave";
import { HVAC_MAINTENANCE_WITHOUT_MAINTENANCE } from "src/page-components/Services/HVAC/HVACMaintenance/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
// import HeroImg from "src/assets/images/service/hvac/hvac-maintenance/hero.png";
// import whenToCallUsImage from "src/assets/images/service/hvac/hvac-maintenance/without-maintenance.png";
// import AppointmentImg from "src/assets/images/home/appointment.jpg";
// import ReadyToHaveBg from "src/assets/images/home/our-commitment.jpg";

const TITLE_WHEN_TO_CALL_US_SECTION = "Common Issues Without Maintenance";
const DESC_WHEN_TO_CALL_US_SECTION =
  "Failure to perform regular maintenance on your HVAC system can lead to a range of common issues, including:";

const HVACMaintenance = () => {
  const sectionRef = useRef(null);
  const dispatch = useAppDispatch();

  const bookNow = useModal();
  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorMaintenance);
  const isLoading = useAppSelector(selectorMaintenanceIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getMaintenancePageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Keeping Your HVAC System in Peak Condition"
            image={pageData?.banner_background}
            name="HVAC Maintenance"
            sectionRef={sectionRef}
          >
            <span className="text-yellow-base font-bold">
              Smartchoice Service
            </span>{" "}
            is proud to offer comprehensive HVAC maintenance services in dozens
            of cities across the United States. With our extensive network of
            skilled technicians, we provide expert maintenance for both
            residential and commercial HVAC systems, including air conditioning
            units and furnaces. Our commitment to quality and customer
            satisfaction ensures that your HVAC system receives the care it
            deserves, no matter where you are located.
          </Hero>
        )}

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(DEFAULT_YELLOW_BLUR, "!left-0 !-translate-x-1/3")}
            /> */}

          <RegularMaintenance sectionRef={sectionRef} />

          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block !right-0 !bottom-0"
              )}
            />
          </div> */}

          <WhenToCallUsSection
            imageClassname="max-h-128 object-center-30"
            title={TITLE_WHEN_TO_CALL_US_SECTION}
            description={DESC_WHEN_TO_CALL_US_SECTION}
            image={pageData?.common_issues}
            items={HVAC_MAINTENANCE_WITHOUT_MAINTENANCE}
            isShowBookOrcall={false}
          />

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 !left-0 !-translate-x-1/3"
              )}
            /> */}
          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />
          {/* </div> */}
        </div>

        <ReadyToHave
          image={pageData?.schedule_maintenance_appointment}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}
          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};
export default HVACMaintenance;
