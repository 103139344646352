import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorMembershipPageState = (state: RootState) =>
  state.membershipPage;

export const selectorMembershipPage = createSelector(
  selectorMembershipPageState,
  (membershipPageState) => membershipPageState.data
);

export const selectorMembershipPageIsLoading = createSelector(
  selectorMembershipPageState,
  (membershipPageState) => membershipPageState.isLoading
);
