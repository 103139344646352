import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorBreakerInstallationState = (state: RootState) =>
  state.breakerInstallation;

export const selectorBreakerInstallation = createSelector(
  selectorBreakerInstallationState,
  (breakerInstallationState) => breakerInstallationState.data
);

export const selectorBreakerInstallationIsLoading = createSelector(
  selectorBreakerInstallationState,
  (breakerInstallationState) => breakerInstallationState.isLoading
);
