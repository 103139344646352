import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorCurrentNewsTipsPostState = (state: RootState) =>
  state.currentNewsTipsPost;

export const selectorCurrentNewsTipsPost = createSelector(
  selectorCurrentNewsTipsPostState,
  (currentNewsTipsPostState) => currentNewsTipsPostState.post
);

export const selectorCurrentNewsTipsPostIsLoading = createSelector(
  selectorCurrentNewsTipsPostState,
  (currentNewsTipsPostState) => currentNewsTipsPostState.isLoading
);
