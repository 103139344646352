import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorPanelInstallationState = (state: RootState) =>
  state.panelInstallation;

export const selectorPanelInstallation = createSelector(
  selectorPanelInstallationState,
  (panelInstallationState) => panelInstallationState.data
);

export const selectorPanelInstallationIsLoading = createSelector(
  selectorPanelInstallationState,
  (panelInstallationState) => panelInstallationState.isLoading
);
