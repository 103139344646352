export const MAJOR_APPLIANCE_SERVICE = [
  {
    id: crypto.randomUUID(),
    title: "Refrigerator Repair",
    description:
      "Common issues include cooling problems, ice maker malfunctions, and leaking water.",
  },
  {
    id: crypto.randomUUID(),
    title: "Washer and Dryer Repair",
    description:
      "Issues may include drum not spinning, failure to drain, dryer not heating or electrical problems.",
  },
  {
    id: crypto.randomUUID(),
    title: "Dishwasher Repair",
    description:
      "Common problems include poor cleaning, leaking, and issues with the electronic controls.",
  },
  {
    id: crypto.randomUUID(),
    title: "Oven and Stove Repair",
    description:
      "Problems may involve heating elements not working, temperature control issues, or igniters failing to spark.",
  },
  {
    id: crypto.randomUUID(),
    title: "Microwave Repair",
    description:
      "Common issues include no heating, turntable not turning, or control panel malfunctions.",
  },
  {
    id: crypto.randomUUID(),
    title: "Icemaker Repair",
    description:
      "Issues often include no ice production, ice cubes being too small, or leaks from the icemaker.",
  },
  {
    id: crypto.randomUUID(),
    title: "Icemaker Repair",
    description:
      "Common problems include temperature fluctuations, compressor failure, or electrical malfunctions.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_SERVICE = [
  {
    id: crypto.randomUUID(),
    title: "Experience",
    description:
      "Our technicians have extensive experience repairing appliances from various brands.",
  },
  {
    id: crypto.randomUUID(),
    title: "Versatility",
    description:
      "We handle a wide range of appliance brands, ensuring we can address your specific repair needs.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality Parts",
    description:
      "We use high-quality replacement parts to ensure lasting repairs and reliable performance.",
  },
  {
    id: crypto.randomUUID(),
    title: "Workmanship Guaranteed",
    description:
      "We stand behind our work with a guarantee of quality craftsmanship.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction with reliable service and transparent communication.",
  },
];
