export const WHY_SERVICE_IN_REGION = [
  {
    id: crypto.randomUUID(),
    title: "Local Expertise",
    description:
      "With years of experience serving Anaheim, we understand the unique needs of local residents and businesses.",
    cardNumber: "01",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality Assurance",
    description:
      "We use only top-quality materials and parts for all repairs and installations, ensuring durable and reliable results.",
    cardNumber: "02",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "Our commitment to customer satisfaction is reflected in our high rate of repeat customers and positive reviews.",
    cardNumber: "03",
  },
  {
    id: crypto.randomUUID(),
    title: "Emergency Service",
    description:
      "We offer emergency HVAC and appliance repair services in Anaheim to address urgent needs promptly.",
    cardNumber: "04",
  },
  {
    id: crypto.randomUUID(),
    title: "Licensed and Insured",
    description:
      "Smartchoice Service Inc. is licensed, bonded, and insured for your peace of mind.",
    cardNumber: "05",
  },
];
