import { FC, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { postApplyBelow } from "src/redux/applyBelow/actions";
import { CustomFileUpload } from "src/components/BookNowModal/CustomFileUpload";
import { TextInput } from "src/components/FormField/TextInput";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFields } from "src/components/RenderFields";
import { Section } from "src/components/Section";
import { IApplyBelow } from "src/@types/postFormsData";
import { BELOW_FORM_FIELDS } from "./constants";
import { NotificationService } from "src/helpers/notifications";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
import { selectorApplyBelowIsLoading } from "src/redux/applyBelow/selectors";
import cn from "classnames";

export const ApplyBelowForm: FC = () => {
  const dispatch = useAppDispatch();
  const methods = useForm<IApplyBelow>({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      referrer: "",
      resume: null,
      comments: "",
    },
  });

  const location = useLocation();
  const post = location.pathname.split("/").filter(Boolean).pop();

  const fileInputRef = useRef<{ clearFile: () => void } | null>(null);
  const isLoading = useAppSelector(selectorApplyBelowIsLoading);

  const onSubmit: SubmitHandler<IApplyBelow> = async (data) => {
    const formData = { ...data, resume: data.resume, post };

    try {
      await dispatch(postApplyBelow(formData)).unwrap();

      NotificationService.success("success request");
      methods.reset();
      fileInputRef.current?.clearFile();
    } catch (error) {
      console.error("Failed to submit form: ", error);
    }
  };

  return (
    <div>
      <Section
        title="Apply Below"
        description="All applications must have a resume when applying for this job. The applicant will not be considered without a proper resume."
        titleClassName="mb-5 text-yellow-base"
        descriptionClassName="mb-5 text-center"
      >
        <div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              <div className="flex flex-col gap-y-8 mb-8">
                <RenderFields fields={BELOW_FORM_FIELDS} />

                <CustomFileUpload
                  ref={fileInputRef}
                  acceptTypes=".pdf, .doc, .docx"
                  name="resume"
                  control={methods.control}
                  defaultLabel={"Upload Resume\u002A"}
                  changeLabel="Change Resume"
                  className="justify-center py-5"
                  maxSize={20 * 1024 * 1024} // 20 MB у байтах
                />

                <TextInput
                  fieldClassName="!pb-10"
                  name="comments"
                  placeholder="Comments"
                  type="textarea"
                  required={false}
                />
              </div>

              <Button
                className={cn("w-full", {
                  "opacity-55": isLoading,
                })}
                variant={ButtonVariants.PRIMARY}
                isDisabled={isLoading}
              >
                {isLoading ? <Loader size={Sizes.M} /> : "Submit"}
              </Button>
            </form>
          </FormProvider>
        </div>
      </Section>
    </div>
  );
};
