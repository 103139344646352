import { useEffect } from "react";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { PATHNAMES } from "src/constants/routes";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  selectorCareer,
  selectorCareerIsLoading,
} from "src/redux/career/selectors";
import { getCareerAsync } from "src/redux/career/actions";
import { Section } from "src/components/Section";
import { CareerCard } from "src/components/CareerCard";
import { Pagination } from "src/components/Pagination";
import { Loader } from "src/components/Loader";
import { useQueryParams } from "src/hooks/useQueryParams";
import { QUERY_PARAM_KEYS } from "src/constants/queryParams";
import { scrollTop } from "src/utils/scrollTop";
import { Sizes } from "src/@types/sizes";

const PAGE_NAME = "Career";
const SECTION_NAME = "Career";
const ITEMS_PER_PAGE = 9;

const Career = () => {
  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { name: PAGE_NAME },
  ];

  const dispatch = useAppDispatch();

  const { getQueryParam, setQueryParam } = useQueryParams();
  const currentPage = Number(getQueryParam(QUERY_PARAM_KEYS.PAGE)) || 1;

  useEffect(() => {
    dispatch(getCareerAsync(currentPage));
  }, [currentPage, dispatch]);

  const careerResponse = useAppSelector(selectorCareer);
  const isLoading = useAppSelector(selectorCareerIsLoading);

  const setPage = (page: number) => {
    scrollTop();
    setQueryParam(QUERY_PARAM_KEYS.PAGE, page.toString());
  };
  const pageCount = Math.ceil(careerResponse?.count / ITEMS_PER_PAGE);
  const isPaginated = pageCount > 1;

  return (
    <PageWrapper isShownMaps={false}>
      <div className="container wrapper">
        <BreadCrumbs breadCrumbsItems={breadCrumbsItems} />

        <Section className="pt-10 mx-auto max-w-267.5" title={SECTION_NAME}>
          {isLoading ? (
            <div className="flex justify-center items-center min-h-96">
              <Loader size={Sizes.L} />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 justify-items-center gap-10 sm:grid-cols-2 lg:grid-cols-3">
                {Boolean(careerResponse?.results?.length) &&
                  careerResponse.results.map((careerItem) => (
                    <CareerCard key={careerItem.id} {...careerItem} />
                  ))}
              </div>

              {isPaginated && (
                <Pagination
                  className="justify-center my-10"
                  page={currentPage}
                  pageCount={pageCount}
                  setPage={setPage}
                />
              )}
            </>
          )}
        </Section>
      </div>
    </PageWrapper>
  );
};

export default Career;
