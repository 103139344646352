import { ReactComponent as EnergyConsumptionIcon } from "src/assets/icons/appliance-service/maintenance/increased-energy.svg";
import { ReactComponent as ReducedIcon } from "src/assets/icons/appliance-service/maintenance/reduced-perform.svg";
import { ReactComponent as SafetyIcon } from "src/assets/icons/appliance-service/maintenance/safety-hazards.svg";
import { ReactComponent as HigerCostIcon } from "src/assets/icons/appliance-service/maintenance/higher-repair-cost.svg";

export const IMPORTANCE_OF_APPLIANCE_MAINTENANCE = [
  {
    id: crypto.randomUUID(),
    title: "Increased Energy Consumption",
    description:
      "Dirty or poorly maintained appliances may consume more energy to operate efficiently.",
    icon: EnergyConsumptionIcon,
  },
  {
    id: crypto.randomUUID(),
    title: "Reduced Performance",
    description:
      "Appliances may start to underperform, such as refrigerators not cooling effectively or washers not cleaning clothes properly.",
    icon: ReducedIcon,
  },
  {
    id: crypto.randomUUID(),
    title: "Potential Safety Hazards",
    description:
      "Faulty appliances due to lack of maintenance can pose safety risks, such as electrical hazards or gas leaks.",
    icon: SafetyIcon,
  },
  {
    id: crypto.randomUUID(),
    title: "Higher Repair Costs",
    description:
      "Neglected appliances are more prone to breakdowns, leading to costly repairs or even premature replacement.",
    icon: HigerCostIcon,
  },
];

export const WHY_MAINTENANCE_IMPORTANT = [
  {
    id: crypto.randomUUID(),
    cardNumber: "01",
    title: "Extended Lifespan",
    description:
      "Proper care helps appliances last longer, reducing the need for premature replacements.",
  },
  {
    id: crypto.randomUUID(),
    cardNumber: "02",
    title: "Improved Efficiency",
    description:
      "Well-maintained appliances operate more efficiently, saving you money on utility bills.",
  },
  {
    id: crypto.randomUUID(),
    cardNumber: "03",
    title: "Enhanced Performance",
    description:
      "Appliances function better when maintained regularly, providing optimal performance.",
  },
  {
    id: crypto.randomUUID(),
    cardNumber: "04",
    title: "Peace of Mind",
    description:
      "Knowing your appliances are well-maintained reduces the risk of unexpected breakdowns.",
  },
];

export const OUR_APPLIANCE_MAINTENANCE_SERVICES = [
  {
    id: crypto.randomUUID(),
    title: "Refrigerators",
    description:
      "Cleaning condenser coils, checking door seals, and inspecting temperature settings.",
  },
  {
    id: crypto.randomUUID(),
    title: "Washers and Dryers",
    description:
      "Inspecting hoses and connections, using cleaning tablets for cleaning out the drum and testing for optimal performance. For dryers: Cleaning the dryer inside of lint, lubricating all the rollers, removing excess hair or lint, and cleaning out the vent.",
  },
  {
    id: crypto.randomUUID(),
    title: "Dishwashers",
    description:
      "Checking and cleaning filters, inspecting spray arms, and ensuring proper drainage.",
  },
  {
    id: crypto.randomUUID(),
    title: "Ovens and Stoves",
    description:
      "Testing heating elements, calibrating temperature controls, and checking gas connections.",
  },
  {
    id: crypto.randomUUID(),
    title: "Microwaves",
    description:
      "Cleaning interior and exterior surfaces, testing door seals, and checking functionality.",
  },
  {
    id: crypto.randomUUID(),
    title: "Icemakers",
    description:
      "Cleaning components, ensuring proper water flow, and checking ice production.",
  },
  {
    id: crypto.randomUUID(),
    title: "Wine Coolers",
    description:
      "Testing temperature settings, inspecting seals, and cleaning condenser coils.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_SERVICE_MAINTENANCE = [
  {
    id: crypto.randomUUID(),
    title: "Experience",
    description:
      "Our technicians are trained to provide thorough maintenance for a wide range of appliances.",
  },
  {
    id: crypto.randomUUID(),
    title: "Preventive Care",
    description:
      "Regular maintenance helps prevent breakdowns and extends the life of your appliances.",
  },
  {
    id: crypto.randomUUID(),
    title: "Comprehensive Service",
    description:
      "We offer detailed inspections and cleaning tailored to each appliance type.",
  },
  {
    id: crypto.randomUUID(),
    title: "Convenience",
    description:
      "Schedule maintenance at your convenience to minimize disruption to your daily routine.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction with dependable service and clear communication.",
  },
];
