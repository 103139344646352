import React, { RefObject, FC } from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { WHY_CHOOSE_SMARTCHOICE_ITEMS } from "./constants";

const SECTION_TITLE = "Why Choose Smartchoice for Heat Pump Installation?";
const SECTION_DESC =
  "At Smartchoice Service, we pride ourselves on being your trusted partner for all your HVAC needs. When it comes to heat pump installation, here's why customers choose us:";

interface Props {
  sectionRef?: RefObject<HTMLElement>;
}

export const WhyChoose: FC<Props> = ({ sectionRef }) => (
  <Section
    className="wrapper mx-auto"
    descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10 lg:text-start"
    title={SECTION_TITLE}
    description={SECTION_DESC}
    sectionRef={sectionRef}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {WHY_CHOOSE_SMARTCHOICE_ITEMS.map((item) => (
        <SectionCard key={item.id} {...item} />
      ))}
    </div>
  </Section>
);
