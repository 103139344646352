import { FC } from "react";
import cn from "classnames";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { getImageUrl } from "src/utils/getImageUrl";
import { ISectionWithImage } from "src/@types";
import { Sizes } from "src/@types/sizes";
import { ReactComponent as BookIcon } from "src/assets/icons/book-black-base.svg";

import { Link } from "../Link";
import { Loader } from "../Loader";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";

interface AppointmentBookingProps extends ISectionWithImage {
  className?: string;
  title: string;
  onButtonClick: VoidFunction;
  isLoading?: boolean;
}

export const AppointmentBooking: FC<AppointmentBookingProps> = ({
  className,
  title,
  phone,
  image,
  onButtonClick,
  isLoading,
}) => {
  const contactsData = useAppSelector(selectorDetails);

  return (
    <div className={cn("wrapper", className)}>
      <div className="max-w-250 w-full mx-auto overflow-hidden rounded-10 border border-yellow-base">
        <div className="flex justify-between items-center h-full w-full rounded-10 bg-black-base back">
          <div className="p-5 lg:p-10">
            <h4 className="text-center text-2xl leading-9 font-bold mb-8 lg:mb-15 lg:text-start">
              {title}
            </h4>

            <div className="flex flex-col sm:flex-row items-center gap-8">
              <Button
                className="flex items-center gap-2  border-goldGradientOlive bg-gradient-to-b from-heroBtnStart to-heroEnd lg:py-3"
                type="button"
                variant={ButtonVariants.PRIMARY}
                onClick={onButtonClick}
              >
                <BookIcon className="min-w-5 min-h-5" />

                <span className="text-18 font-extrabold whitespace-nowrap">
                  Book now
                </span>
              </Button>

              <Link
                className="group text-xl font-manrope"
                href={phone ? phone?.href : contactsData?.CONTACTS[1]?.href}
              >
                <span className="underline group-hover:text-yellow-base font-medium">
                  or call us &nbsp;
                </span>
                <span className="underline group-hover:text-yellow-base font-extrabold">
                  {phone ? phone?.label : contactsData?.CONTACTS[1]?.label}
                </span>
              </Link>
            </div>
          </div>

          {isLoading ? (
            <div className="hidden min-w-110 lg:block">
              <Loader size={Sizes.M} />
            </div>
          ) : (
            <img
              className="hidden min-w-110 max-h-76.5 h-full rounded-10 object-cover lg:block"
              style={{ objectPosition: "center 30%" }}
              src={getImageUrl(image?.image)}
              alt={image?.alt}
            />
          )}
        </div>
      </div>
    </div>
  );
};
