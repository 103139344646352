import { Icon } from ".";

export enum CardType {
  SERVICE_CARD = "service-card",
  DEFAULT = "default",
  CONTACT_CARD = "contact-card",
  DOUBLE_CARD = "double-card",
  MEMBERSHIP_CARD = "membership-card",
}

export interface ICard {
  id: number|string;
  cardNumber?: string;
  title?: string;
  description?: string;
  icon?: Icon;
  contactInfo?: string;
  contactPath?: string;
  cardType?: CardType;
  path?: string;
  second_description?: string;
}

export interface IContactCard extends ICard {
  isExpanded?: boolean;
}
