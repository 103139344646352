import { FC, useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getFurnaceInstallationPageAsync } from "src/redux/services/hvac/furnaceInstallation/actions";
import {
  selectorFurnaceInstallation,
  selectorFurnaceInstallationIsLoading,
} from "src/redux/services/hvac/furnaceInstallation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { CallUsDoubleDesc } from "src/page-components/Services/HVAC/FurnanceInstallation/CallUsDoubleDesc";
import {
  BENEFITS_OF_NEW_FURNACE,
  WHY_CHOOSE_SMARTCHOICE_FURNACE_INSTALLATION,
  WHY_UPGRADE_FURNACE_IMPORTANT,
} from "src/page-components/Services/HVAC/FurnanceInstallation/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { Section } from "src/components/Section";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { Link } from "src/components/Link";
import { ContentOverlay } from "src/components/ContentOverlay";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const FurnanceInstallation: FC = () => {
  const sectionRef = useRef(null);
  const bookNow = useModal();

  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorFurnaceInstallation);
  const isLoading = useAppSelector(selectorFurnaceInstallationIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getFurnaceInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            name="Furnace Installation"
            title="Expert Furnace Installation by Smartchoice Service"
            image={pageData?.banner_background}
            sectionRef={sectionRef}
          >
            For reliable and professional furnace installation,&nbsp;
            <span className="text-yellow-base font-bold">
              Smartchoice Service
            </span>{" "}
            is the name you can trust. We proudly serve various regions in
            Southern California, including Orange and Los Angeles counties, as
            well as key areas in Washington State, such as Pierce and King
            counties.
            <br /> <br />
            Our team ensures your new furnace is installed with precision and
            care, providing optimal heating comfort and efficiency. Furnance
            Installation
          </Hero>
        )}

        <div className="container">
          <div ref={sectionRef}>
            <WhenToCallUsSection
              imageClassname="sm:h-143.5 object-center"
              title="Why It's Important to Upgrade Your Furnace"
              description="Upgrading your furnace is crucial for maintaining a comfortable and energy-efficient home. Here are key reasons why you should consider a new furnace:"
              image={pageData?.benefits}
              items={WHY_UPGRADE_FURNACE_IMPORTANT}
              onButtonClick={bookNow.openModal}
            />
          </div>

          <Section
            className="wrapper relative"
            title="Benefits of a New Furnace"
          >
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 right-0 -translate-y-1/2"
              )}
            /> */}
            <div className={DEFAULT_CARD_LIST_CLASSNAME}>
              {BENEFITS_OF_NEW_FURNACE.map((card) => (
                <SectionCard key={card.id} {...card} />
              ))}
            </div>
          </Section>

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 left-0 !-translate-x-1/2 translate-y-1/2"
              )}
            /> */}

          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />
          {/* </div> */}

          <CallUsDoubleDesc
            imageClassname="max-h-176.75 !object-cover object-top"
            sectionTitle="Why Choose Smartchoice for Your Furnace Installation?"
            image={pageData?.why_choose_us}
            data={WHY_CHOOSE_SMARTCHOICE_FURNACE_INSTALLATION}
            showBookOrCall={false}
            isReverse
          />
        </div>

        <ContentOverlay
          bg={pageData?.ready_for_new_furnace_background}
          className="flex relative"
          bgPositionClassName="object-center-30"
        >
          <Section
            className="wrapper"
            titleClassName="text-yellow-base"
            title="Ready for a New Furnace?"
          >
            <div className="text-start md:text-center mx-auto max-w-215">
              <p className="text-xl font-manrope font-medium">
                Enhance your home’s comfort with a professionally installed
                furnace from Smartchoice Service. Contact us today to schedule
                your consultation and learn how we can provide you with the
                perfect heating solution.
              </p>
              <br />
              <p className=" text-xl font-manrope font-bold">
                Call us at &nbsp;
                <Link
                  href={contactsData?.CONTACTS[2]?.href}
                  className="text-yellow-base hover:underline"
                >
                  {contactsData?.CONTACTS[2]?.label}
                </Link>
                &nbsp; or visit our website to book your appointment. With
                &nbsp;
                <span className="text-yellow-base">Smartchoice Service</span>,
                your satisfaction and comfort are guaranteed.
              </p>
            </div>
          </Section>
        </ContentOverlay>

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0 "
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 left-0 !-translate-x-1/2 translate-y-1/2 "
              )}
            />
          </div> */}

          <OtherServices />

          <NewsTips className="lg:hidden" />
          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default FurnanceInstallation;
