import { ReactComponent as HomeIcon } from "src/assets/icons/electrical-services/EV-charger-installation/home-EV-charger-installation.svg";
import { ReactComponent as CommercialIcon } from "src/assets/icons/electrical-services/EV-charger-installation/commercial-EV-charger-installation.svg";
import { ReactComponent as UpgradesIcon } from "src/assets/icons/electrical-services/EV-charger-installation/EV-charger-upgrades.svg";
import { ReactComponent as ConsultationIcon } from "src/assets/icons/electrical-services/EV-charger-installation/consultation-planning.svg";

export const EV_CHARGER_INSTALLATION_SERVICES = [
  {
    id: crypto.randomUUID(),
    title: "Home EV Charger Installation",
    icon: HomeIcon,
    description: "Installing Level 2 chargers for convenient charging at home.",
  },
  {
    id: crypto.randomUUID(),
    title: "Commercial EV Charger Installation",
    icon: CommercialIcon,
    description:
      "Setting up charging stations for businesses, parking facilities, and multifamily residences.",
  },
  {
    id: crypto.randomUUID(),
    title: "EV Charger Upgrades",
    icon: UpgradesIcon,
    description:
      "Upgrading existing chargers to higher-capacity models for faster charging times.",
  },
  {
    id: crypto.randomUUID(),
    title: "Consultation and Planning",
    icon: ConsultationIcon,
    description:
      "Assessing your property and providing customized solutions to meet your charging needs.",
  },
];

export const IMPORTANCE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Convenience",
    cardNumber: "01",
    description:
      "Ensuring you can charge your EV conveniently at home or work.",
  },
  {
    id: crypto.randomUUID(),
    title: "Sustainability",
    cardNumber: "02",
    description:
      "Contributing to a greener environment by promoting electric vehicle usage.",
  },
  {
    id: crypto.randomUUID(),
    title: "Future-Proofing",
    cardNumber: "03",
    description:
      "Preparing your property for the increasing adoption of electric vehicles.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Experience",
    description:
      "Our certified electricians have specialized training in EV charger installations.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality",
    description:
      "We use industry-leading products and ensure installations comply with safety standards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Workmanship Guaranteed",
    description:
      "We stand behind our work with a guarantee of quality craftsmanship.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Focus",
    description:
      "We prioritize your needs and provide clear communication throughout the installation process.",
  },
];
