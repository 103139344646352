import React, { FC } from "react";
import cn from "classnames";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
  DEFAULT_DESCRIPTION_CLASSNAME,
} from "../constants";
import { ICardProps } from "../types";

export const MembershipCard: FC<ICardProps> = ({
  title,
  description,
  className,
  containerClassName,
}) => (
  <div className={cn(DEFAULT_CARD_WRAPPER_CLASSNAME, containerClassName)}>
    <div
      className={cn(DEFAULT_CARD_CLASSNAME, "flex flex-col gap-2", className)}
    >
      <h4 className="text-yellow-base text-2xl font-bold">{title}</h4>

      <p className={DEFAULT_DESCRIPTION_CLASSNAME}>{description}</p>
    </div>
  </div>
);
