import { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Link } from "src/components/Link";
import { Section } from "src/components/Section";
import { ISectionWithImage } from "src/@types";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => {
  return (
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper"
        titleClassName="text-yellow-base max-w-215"
        title="Contact Us for Professional Electrical Breaker Installation"
      >
        <div className="mmx-auto max-w-215 md:text-center text-xl font-manrope">
          <p className="mb-10 font-medium">
            Ready to enhance the safety and efficiency of your electrical
            system?
            <br />
            Contact &nbsp;
            <span className="text-yellow-base">Smartchoice Service</span> today.
          </p>

          <p className="mb-10 font-bold">
            Call us at &nbsp;
            <Link
              href={phone?.href}
              className="text-yellow-base font-bold hover:underline"
            >
              {phone?.label}
            </Link>
            &nbsp; to schedule an appointment with our appliance repair experts.
          </p>

          <p>
            Discover why Smartchoice Service is your trusted partner for
            superior electrical breaker installations.
          </p>
        </div>
      </Section>
    </ContentOverlay>
  );
};
