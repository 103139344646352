import { ICard } from "src/@types/card";
import { ReactComponent as ApplianceRepairIcon } from "src/assets/icons/cards/appliance-service-icon.svg";
import { ReactComponent as HVACServicesIcon } from "src/assets/icons/cards/hvac-service-icon.svg";
import { ReactComponent as ElectricalServicesIcon } from "src/assets/icons/cards/electrical-service-icon.svg";

export const SERVICES_CARDS: ICard[] = [
  {
    id: 1,
    title: "APPLIANCE REPAIR",
    description:
      "We repair all major household appliances, including refrigerators, washers, dryers, ovens, and more. Our technicians are equipped with the knowledge and tools to diagnose and fix any issue promptly.",
    icon: ApplianceRepairIcon,
  },
  {
    id: 2,
    title: "HVAC SERVICES",
    description:
      "From routine maintenance to emergency repairs and new installations, we provide comprehensive HVAC services to keep your home comfortable year-round.",
    icon: HVACServicesIcon,
  },
  {
    id: 3,
    title: "ELECTRICAL SERVICES",
    description:
      "Our licensed electricians handle everything from troubleshooting electrical problems to complete rewiring projects, ensuring your electrical systems are safe and up to code.",
    icon: ElectricalServicesIcon,
  },
];
