import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useModal } from "src/hooks/useModal";
import { getCouponsAsync } from "src/redux/coupons/actions";
import { selectCoupons } from "src/redux/coupons/selectors";
import { BookNowModal } from "src/components/BookNowModal";
import { Section } from "src/components/Section";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ReactComponent as CheckImg } from "src/assets/icons/check-yellow-base.svg";
import { CouponSwiper } from "./CouponSwiper";
import { couponTerms } from "./constants";

export const SpecialOffers: FC = () => {
  const bookNow = useModal();
  const dispatch = useAppDispatch();

  const coupons = useAppSelector(selectCoupons);

  useEffect(() => {
    if (!coupons?.length) {
      dispatch(getCouponsAsync());
    }
  }, []);

  const activeCoupons = Array.isArray(coupons)
    ? coupons?.filter((coupon) => coupon?.is_active)
    : [];

  const discounts = activeCoupons.map((coupon) => ({
    ...coupon,
    label: coupon.discount && `${coupon.discount} Coupon`,
  }));

  return (
    <Section
      className="wrapper !pb-30 gl:pt-25 gl:px-15"
      title="Special Offers"
    >
      <div className="flex flex-col-reverse items-center gap-y-8 gl:flex-row gl:justify-between gl:gap-x-17.5">
        <div>
          <h4 className="mb-8 text-xl font-manrope font-bold text-yellow-base gl:text-32">
            Terms for the coupon offers :
          </h4>

          <ul className="flex flex-col gap-y-3 mb-5">
            {couponTerms.map(({ id, text }) => (
              <li className="flex items-center gap-x-5" key={id}>
                <div className="flex justify-center items-center w-8 h-8 p-1 border border-yellow-base rounded-full lg:w-10 lg:h-10 lg:p-2">
                  <CheckImg className="w-8 h-8  lg:w-10 lg:h-10" />
                </div>

                <p className="text-sm font-rubik lg:text-xl">{text}</p>
              </li>
            ))}
          </ul>

          <Button
            className="w-full px-5 py-3 gl:max-w-35"
            variant={ButtonVariants.PRIMARY}
            onClick={bookNow.openModal}
          >
            Get Offers
          </Button>
        </div>

        <CouponSwiper coupons={coupons} onButtonClick={bookNow.openModal} />
      </div>

      <BookNowModal
        isOpen={bookNow.isOpenModal}
        onClose={bookNow.closeModal}
        openWithCoupon
        discounts={discounts}
      />
    </Section>
  );
};
