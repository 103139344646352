import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorHeatPumpInstallationState = (state: RootState) =>
  state.heatPumpInstallation;

export const selectorHeatPumpInstallation = createSelector(
  selectorHeatPumpInstallationState,
  (heatPumpInstallationState) => heatPumpInstallationState.data
);

export const selectorHeatPumpInstallationIsLoading = createSelector(
  selectorHeatPumpInstallationState,
  (heatPumpInstallationState) => heatPumpInstallationState.isLoading
);
