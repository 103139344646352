import React from "react";
import { Section } from "src/components/Section";
import { AdvantagesList } from "./AdvantagesList";

const SECTION_TITLE = "Why Choose Smartchoice Service?";

export const WhySmartchoice = () => {
  return (
    <Section className="wrapper" title={SECTION_TITLE}>
      <AdvantagesList />
    </Section>
  );
};
