import React, { FC } from "react";
import cn from "classnames";
import { Link } from "src/components/Link";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
  DEFAULT_TITLE_CLASSNAME,
} from "../constants";
import { ICardProps } from "../types";
import { getImageUrl } from "src/utils/getImageUrl";

const ICON_SIZE = 80;

interface Props extends ICardProps {
  isExpanded?: boolean;
}

export const ContactCard: FC<Props> = ({
  title,
  contactInfo,
  contactPath,
  icon,
  className,
  containerClassName,
  isExpanded = false,
}) => {
  return (
    <div
      className={cn(
        DEFAULT_CARD_WRAPPER_CLASSNAME,
        // "max-w-52.5 w-full",
        {
          "!max-w-82.5": isExpanded,
        },
        containerClassName
      )}
    >
      <div
        className={cn(
          DEFAULT_CARD_CLASSNAME,
          "flex flex-col items-center gap-2.5 px-6 py-5",
          className
        )}
      >
        <div className="w-20 h-20">
          {typeof icon === "string" && (
            <img
              src={getImageUrl(icon)}
              className="yellow-base-svg"
              alt="icon"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          )}
        </div>

        <h4 className={cn(DEFAULT_TITLE_CLASSNAME, "text-yellow-base")}>
          {title}
        </h4>

        <Link
          className="text-xl font-bold hover:text-yellow-base"
          href={contactPath}
        >
          {contactInfo}
        </Link>
      </div>
    </div>
  );
};
