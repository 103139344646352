import React from "react";
import { Section } from "src/components/Section";
import { ServicesList } from "./ServicesList";

const SECTION_TITLE = "Our Services";

export const OurServices = () => (
  <Section title={SECTION_TITLE} className="wrapper">
    <ServicesList />
  </Section>
);
