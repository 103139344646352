import React, { FC } from "react";
import { getItemPath } from "src/utils/getItemPath";
import { PATHNAMES } from "src/constants/routes";
import { CareersItem } from "./types";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { Link } from "../Link";
import { ICareerItem } from "src/@types/career";
import { getImageUrl } from "src/utils/getImageUrl";

interface Props extends ICareerItem {}

export const CareerCard: FC<Props> = ({ title, description, img, id }) => {
  const path = getItemPath(PATHNAMES.CAREER_POST, { slug: id });

  const imagePath = getImageUrl(img);

  return (
    <div className="min-h-135 max-w-82.5 border border-yellow-base rounded-md">
      <div
        className="flex items-end px-6 pb-5 mb-5 h-72 rounded-t-md overflow-hidden"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 130%),
            url(${imagePath}) lightgray center / cover no-repeat
`,
        }}
      >
        <p className="line-clamp-2 text-xl font-manrope font-medium">{title}</p>
      </div>

      <div className="px-6 pb-10">
        {description && (
          <div
            className="line-clamp-6 mb-10 text-13 font-manrope leading-snug min-h-26.75"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <Link href={path}>
          <Button className="!w-full" variant={ButtonVariants.PRIMARY}>
            Read More
          </Button>
        </Link>
      </div>
    </div>
  );
};
