import { FC, RefObject } from "react";
import cn from "classnames";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { BENEFITS } from "./constants";

const SECTION_TITLE = "Benefits of AC Repair";
interface Props {
  sectionRef?: RefObject<HTMLElement>;
}

export const BenefitsRepair: FC<Props> = ({ sectionRef }) => (
  <div className="wrapper relative">
    {/* <div
      className={cn(DEFAULT_YELLOW_BLUR, "top-0 !left-0 !-translate-x-1/2")}
    /> */}

    <Section
      className="mx-auto pt-18.75 -mt-18.75"
      sectionRef={sectionRef}
      descriptionClassName="mb-5"
      title={SECTION_TITLE}
    >
      <div className="max-w-250 mx-auto mb-5 lg:mb-10">
        <p className="text-base font-manrope">
          When Smartchoice Service technicians arrive at your home, their truck
          will be stocked with the parts most frequently prone to failure. We
          carry replacement parts including blower motors, compressors, wiring,
          filters, refrigerant tanks and refills, trim kits, and more. This
          means most common AC repairs can be completed in one trip.
        </p>
        <br />
        <p className="text-base font-manrope">
          Repairing your AC has several advantages:
        </p>
      </div>

      <div className={DEFAULT_CARD_LIST_CLASSNAME}>
        {BENEFITS.map(({ ...card }) => (
          <SectionCard
            key={card.id}
            containerClassName="w-full gl:h-52"
            {...card}
          />
        ))}
      </div>
    </Section>
  </div>
);
