import { FC } from "react";
import cn from "classnames";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { IPostFormsData } from "src/@types/postFormsData";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-btn.svg";
import { RenderFields } from "../RenderFields";
import { BOOK_NOW_FORM_FIELDS } from "../BookNowModal/constants";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { ModalWindow } from "../ModalWindow";
import { postMembership } from "src/redux/membershipForm/actions";
import { NotificationService } from "src/helpers/notifications";
import { selectorMembershipIsLoading } from "src/redux/membershipForm/selectors";
import { Sizes } from "src/@types/sizes";
import { Loader } from "../Loader";

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const MembershipModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const methods = useForm<IPostFormsData>({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });

  const isLoading = useAppSelector(selectorMembershipIsLoading);

  const onSubmit: SubmitHandler<IPostFormsData> = async (data) => {
    try {
      await dispatch(postMembership(data));
      NotificationService.success("success request");
      methods.reset();
      onClose();
    } catch (error) {
      console.error("Failed to submit form:", error);
    }
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onClose}
      isShownCloseButton={false}
      isActivePortal
      className={cn("top-1/2 ", {
        "!-translate-y-1/2": isOpen,
      })}
    >
      <div className="custom-scrollbar relative p-10 w-87.5 h-143.5 overflow-y-auto overflow-x-hidden rounded-10 bg-black-base sm:w-120 lg:w-155  lg:px-30 lg:py-15">
        <button className="absolute top-5 right-3 z-40" onClick={onClose}>
          <CloseIcon className="w-7 h-7 fill-yellow-base hover:rotate-90" />
        </button>

        <p className="mb-10 text-center capitalize text-5xl  font-manrope font-bold sm:text-6xl">
          join our <span className="text-yellow-base">membership</span>
        </p>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <div className="flex flex-col gap-y-8 mb-7">
              <RenderFields
                fieldClassName="!px-0 !w-full"
                fields={BOOK_NOW_FORM_FIELDS}
              />
            </div>

            <Button
              className={cn("mx-auto max-w-25 w-full", {
                "opacity-55": isLoading,
              })}
              variant={ButtonVariants.PRIMARY}
              isDisabled={isLoading}
            >
              {isLoading ? <Loader size={Sizes.S} /> : "Send"}
            </Button>
          </form>
        </FormProvider>
      </div>
    </ModalWindow>
  );
};
