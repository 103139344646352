import React, { FC, RefObject } from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { BENEFITS } from "./constants";

const SECTION_TITLE = "Importance of Regular Maintenance";
const SECTION_DESC =
  "Regular HVAC maintenance is critical for maintaining the efficiency, reliability, and longevity of your heating, ventilation, and air conditioning (HVAC) system. Neglecting maintenance can lead to a variety of issues, including decreased efficiency, higher energy bills, and costly repairs. By investing in routine maintenance with Smartchoice Service, you can enjoy the following benefits:";

interface Props {
  sectionRef?: RefObject<HTMLElement>;
}

export const RegularMaintenance: FC<Props> = ({ sectionRef }) => (
  <Section
    title={SECTION_TITLE}
    description={SECTION_DESC}
    sectionRef={sectionRef}
    className="wrapper mx-auto"
    descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10 md:text-center"
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {BENEFITS.map((card) => (
        <SectionCard key={card.id} {...card} />
      ))}
    </div>
  </Section>
);
