import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { Section } from "src/components/Section";
import { ContactsList } from "./ContactsList";
import { FC } from "react";

const SECTION_TITLE = "Contact us";

interface Props {
  isHiddenPage?: boolean;
}

export const ContactUs: FC<Props> = ({ isHiddenPage }) => {
  const contactsData = useAppSelector(selectorDetails);

  if (!contactsData) return null;

  return (
    <Section className="wrapper" title={SECTION_TITLE}>
      <ContactsList
        contacts={contactsData?.CONTACTS}
        isHiddenPage={isHiddenPage}
      />
    </Section>
  );
};
