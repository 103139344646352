import React, { FC } from "react";
import cn from "classnames";
import { Link } from "src/components/Link";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
} from "src/components/SectionCard/constants";
import { ICardProps } from "src/components/SectionCard/types";

export const ServiceCard: FC<ICardProps> = ({ title, icon: Icon, path }) => (
  <Link href={path}>
    <div
      className={cn(
        DEFAULT_CARD_WRAPPER_CLASSNAME,
        "service-active-slide-border w-25 h-37.5 hover:border-opacity-100 lg:w-41.25 lg:h-60"
      )}
    >
      <div className={cn(DEFAULT_CARD_CLASSNAME, "px-2 py-6")}>
        <div className="flex flex-col items-center justify-center gap-y-3 w-full h-full lg:gap-y-10">
          <div className="flex items-center w-15 h-15 lg:w-25 lg:h-25">
            <Icon />
          </div>

          <h4 className="text-center text-11 lg:text-sm text-yellow-base leading-1.2 font-bold lg:font-medium">
            {title}
          </h4>
        </div>
      </div>
    </div>
  </Link>
);
