import { IFormField } from "src/@types/form";

export const NEWS_TIPS_FORM_FIELDS: IFormField[] = [
  {
    id: crypto.randomUUID(),
    fieldId: "newsTipsName",
    placeholder: "Name\u002A",
    name: "name",
    type: "text",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "newsTipsEmail",
    placeholder: "Email\u002A",
    name: "email",
    type: "email",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "newsTipsPhone",
    placeholder: "Phone\u002A",
    name: "phone",
    type: "tel",
    required: true,
  },
  {
    id: crypto.randomUUID(),
    fieldId: "newsTipsMessage",
    placeholder: "Message",
    name: "message",
    type: "text",
    required: false,
    isActiveValidation: false,
  },
];
