import React, { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getRoughWiringPageAsync } from "src/redux/services/electrical/roughWiring/actions";
import {
  selectorRoughWiring,
  selectorRoughWiringIsLoading,
} from "src/redux/services/electrical/roughWiring/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { RoughWiringTypes } from "src/page-components/Services/Electrical/RoughWiring/RoughWiringTypes";
import { WhyImportant } from "src/page-components/Services/Electrical/RoughWiring/WhyImportant";
import { ContactUs } from "src/page-components/Services/Electrical/RoughWiring/ContactUs";
import { WHY_CHOOSE_SMARTCHOICE_ITEMS } from "src/page-components/Services/Electrical/RoughWiring/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const WHY_CHOOSE_SMARTCHOICE_TITLE =
  "Why Choose Smartchoice for Your Rough Wiring Needs?";
const WHY_CHOOSE_SMARTCHOICE_DESC =
  "When it comes to rough wiring, you need a team that combines technical expertise, reliability, and a commitment to quality. Here's why Smartchoice Service is the ideal choice:";

const RoughWiring = () => {
  const sectionRef = useRef(null);

  const dispatch = useAppDispatch();

  const bookNow = useModal();
  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorRoughWiring);
  const isLoading = useAppSelector(selectorRoughWiringIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getRoughWiringPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Building a Solid Electrical Foundation"
            image={pageData?.banner_background}
            name="Rough Wiring"
            sectionRef={sectionRef}
          >
            Rough wiring is a crucial phase in any electrical installation,
            forming the foundational framework for your home's or business's
            electrical system. It involves installing all the necessary wiring
            and electrical components before the walls are sealed. Ensuring this
            phase is done correctly is essential for the safety, functionality,
            and futureproofing of your electrical system.
          </Hero>
        )}

        <div className="container">
          <div className="relative pt-17.5 -mt-17.5" ref={sectionRef}>
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0"
              )}
            /> */}
            <RoughWiringTypes />
          </div>

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}
          <WhyImportant />
          {/* </div> */}

          <WhenToCallUsSection
            imageClassname="max-h-155 object-center-30"
            title={WHY_CHOOSE_SMARTCHOICE_TITLE}
            description={WHY_CHOOSE_SMARTCHOICE_DESC}
            items={WHY_CHOOSE_SMARTCHOICE_ITEMS}
            image={pageData?.why_choose}
            onButtonClick={bookNow.openModal}
          />

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 left-0 !-translate-x-1/2"
              )}
            /> */}
          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />
          {/* </div> */}
        </div>

        <ContactUs
          image={pageData?.contact_us_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}

          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default RoughWiring;
