import { FC, useMemo } from "react";
import { CardType } from "src/@types/card";
import { DefaultCard } from "./CardView/DefaultCard";
import { ContactCard } from "./CardView/ContactCard";
import { ServiceCard } from "./CardView/ServiceCard";
import { DoubleCard } from "./CardView/DoubleCard";
import { MembershipCard } from "./CardView/MembershipCard";
import { ICardProps } from "./types";

export const SectionCard: FC<ICardProps> = (props) => {
  const Card = useMemo(() => {
    switch (props.cardType) {
      case CardType.SERVICE_CARD:
        return ServiceCard;

      case CardType.CONTACT_CARD:
        return ContactCard;

      case CardType.DOUBLE_CARD:
        return DoubleCard;

      case CardType.MEMBERSHIP_CARD:
        return MembershipCard;

      default:
        return DefaultCard;
    }
  }, [props.cardType]);

  return <Card {...props} />;
};
