import { FC } from "react";
import cn from "classnames";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { TYPES_AIR_CINDITIONING_SYSTEM } from "./constants";

const SECTION_TITLE = "Types of Air Conditioning Systems We Repair";
const SECTION_DESC =
  "At Smartchoice Service, we understand that different homes and businesses have unique cooling needs. That's why our team of expert technicians is trained to repair a variety of air conditioning systems, including:";

export const AirConditioning: FC = () => (
  <div className="wrapper relative">
    {/* <div className={cn(DEFAULT_YELLOW_BLUR, "hidden lg:block top-0 right-0")} />
    <div
      className={cn(
        DEFAULT_YELLOW_BLUR,
        "hidden lg:block !bottom-0 !left-0 !-translate-x-1/2"
      )}
    /> */}

    <Section
      className="mx-auto"
      descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10"
      title={SECTION_TITLE}
      description={SECTION_DESC}
    >
      <ul className={cn(DEFAULT_CARD_LIST_CLASSNAME, "w-full")}>
        {TYPES_AIR_CINDITIONING_SYSTEM.map((type) => (
          <SectionCard key={type.id} {...type} containerClassName="w-full" />
        ))}
      </ul>
    </Section>
  </div>
);
