import { FC } from "react";
import { v4 as uuid } from "uuid";
import { BreadCrumbsItem } from "./BreadCrumbsItem";
import { IBreadCrumbsItem } from "./types";

interface Props {
  className?: string;
  breadCrumbsItems: IBreadCrumbsItem[];
}

export const BreadCrumbs: FC<Props> = ({
  className,
  breadCrumbsItems = [],
}) => {
  if (!breadCrumbsItems.length) {
    return null;
  }

  return (
    <div className={className}>
      <ul className="flex items-center font-bebas">
        {breadCrumbsItems.map((breadCrumbsItem) => (
          <BreadCrumbsItem key={uuid()} {...breadCrumbsItem} />
        ))}
      </ul>
    </div>
  );
};
