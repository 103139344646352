import { FC, useState } from "react";
import cn from "classnames";
import { Step } from "src/@types/newsTips";
import { TableItem } from "./TableItem";

interface Props {
  steps: Step[];
  className?: string;
}

export const TableOfContent: FC<Props> = ({ steps, className }) => {
  const [activeLink, setActiveLink] = useState(null);

  if (!steps) return null;

  const onItemClick = (id) => {
    setActiveLink(id);
  };

  return (
    <div
      className={cn(
        "lg:max-h-135 overflow-auto z-20 w-full max-w-120.5 p-5 bg-black-base bg-opacity-90 rounded-lg",
        className
      )}
    >
      <span className="text-32 text-yellow-base font-bebas uppercase p-5">
        Table of content
      </span>

      <ul>
        {steps.map((step, index) => (
          <TableItem
            key={step.slug}
            onItemClick={onItemClick}
            activeLink={activeLink}
            index={index}
            {...step}
          />
        ))}
      </ul>
    </div>
  );
};
