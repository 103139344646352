import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorElectricalRepairsState = (state: RootState) =>
  state.electricalRepairs;

export const selectorElectricalRepairs = createSelector(
  selectorElectricalRepairsState,
  (electricalRepairsState) => electricalRepairsState.data
);

export const selectorElectricalRepairsIsLoading = createSelector(
  selectorElectricalRepairsState,
  (electricalRepairsState) => electricalRepairsState.isLoading
);
