import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorFurnaceInstallationState = (state: RootState) =>
  state.furnaceInstallation;

export const selectorFurnaceInstallation = createSelector(
  selectorFurnaceInstallationState,
  (furnaceInstallationState) => furnaceInstallationState.data
);

export const selectorFurnaceInstallationIsLoading = createSelector(
  selectorFurnaceInstallationState,
  (furnaceInstallationState) => furnaceInstallationState.isLoading
);
