import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectServiceAreaState = (state: RootState) => state.serviceArea;

export const selectServiceArea = createSelector(
  selectServiceAreaState,
  (ServiceAreaState) => ServiceAreaState.serviceAreaData
);

export const selectServiceAreaIsLoading = createSelector(
  selectServiceAreaState,
  (ServiceAreaState) => ServiceAreaState.isLoading
);
