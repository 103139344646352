import React from "react";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Section } from "src/components/Section";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { PATHNAMES } from "src/constants/routes";
import { Link } from "src/components/Link";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";

const PAGE_NAME = "Privacy Policy";
const SECTION_TITLE = "Privacy Policy";

const PrivacyPolicy = () => {
  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { name: PAGE_NAME },
  ];

  const contactData = useAppSelector(selectorDetails);

  return (
    <PageWrapper isShownMaps={false}>
      <div className="container wrapper">
        <BreadCrumbs breadCrumbsItems={breadCrumbsItems} className="mb-6" />
        <Section className="max-w-160 mx-auto" title={SECTION_TITLE}>
          <p className="mb-7 text-base">
            <span className="text-yellow-base font-semibold">
              Effective Date:
            </span>
            08/28/2024
          </p>
          <div className="flex flex-col justify-center gap-2 mb-5">
            <h4 className="text-yellow-base text-2xl font-manrope font-bold">
              1. Introduction
            </h4>
            <p className="text-base">
              Welcome to Smartchoice Service. We are committed to protecting
              your privacy and ensuring that your personal information is
              handled in a safe and responsible manner. This Privacy Policy
              outlines how we collect, use, and protect the information you
              provide to us when you use our website or services.
            </p>
          </div>

          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                2. Information We Collect
              </h4>
              <div className="flex flex-col gap-3 text-base">
                <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                  <li>
                    <span className="font-bold">
                      Personal Information: &nbsp;
                    </span>
                    We may collect personal information such as your name, email
                    address, phone number, and mailing address when you fill out
                    forms on our website, contact us, or use our services.
                  </li>
                  <li>
                    <span className="font-bold">
                      Service Information: &nbsp;
                    </span>
                    When you use our HVAC, Electrical, or Appliance repair
                    services, we may collect details related to the services
                    provided, including equipment details, service dates, and
                    customer feedback.
                  </li>
                  <li>
                    <span className="font-bold">
                      Payment Information: &nbsp;
                    </span>
                    If you make payments through our website, we may collect
                    payment details such as credit card information or bank
                    account details. All payment information is processed
                    securely.
                  </li>
                  <li>
                    <span className="font-bold">
                      Cookies and Tracking Technologies: &nbsp;
                    </span>
                    We use cookies and other tracking technologies to enhance
                    your experience on our website. This includes tracking
                    website usage patterns and preferences.
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                3. How We Use Your Information
              </h4>
              <div className="flex flex-col gap-3 text-base">
                <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                  <li>
                    <span className="font-bold">Service Delivery: &nbsp;</span>
                    We use your personal information to provide and improve our
                    services, process transactions, and communicate with you
                    regarding your service requests.
                  </li>
                  <li>
                    <span className="font-bold">Marketing: &nbsp;</span>
                    With your consent, we may use your contact information to
                    send you promotional materials, special offers, or
                    newsletters related to our services. You can opt-out of
                    receiving these communications at any time.
                  </li>
                  <li>
                    <span className="font-bold">Analytics: &nbsp;</span>
                    We use analytics tools to monitor website traffic and user
                    behavior to improve our website's performance and enhance
                    your experience.
                  </li>
                  <li>
                    <span className="font-bold">Legal Compliance: &nbsp;</span>
                    We may disclose your information if required by law or if we
                    believe such action is necessary to comply with legal
                    processes, protect our rights, or ensure the safety of our
                    users.
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                4. How We Protect Your Information
              </h4>
              <p className="text-base">
                We implement a variety of security measures to protect your
                personal information. This includes the use of encryption,
                firewalls, and secure socket layer (SSL) technology to safeguard
                your data during transmission. Access to your personal
                information is restricted to authorized personnel only.
              </p>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                5. Sharing Your Information
              </h4>
              <p className="text-base">
                We do not sell, trade, or otherwise transfer your personal
                information to third parties without your consent, except for
                the following circumstances:
              </p>
              <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                <li>
                  <span className="font-bold">Service Providers: &nbsp;</span>
                  We may share your information with trusted service providers
                  who assist us in operating our website or conducting our
                  business, as long as those parties agree to keep your
                  information confidential.
                </li>
                <li>
                  <span className="font-bold">Legal Obligations: &nbsp;</span>
                  We may disclose your information to comply with legal
                  obligations, enforce our site policies, or protect our or
                  others' rights, property, or safety.
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                6. Your Rights
              </h4>
              <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                <li>
                  Access and request a copy of the personal information we hold
                  about you.
                </li>
                <li>
                  Request corrections to any inaccuracies in your personal
                  information.
                </li>
                <li>
                  Request the deletion of your personal information, subject to
                  legal obligations.
                </li>
                <li>Opt-out of receiving marketing communications from us.</li>
              </ul>
            </div>

            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                7. Third-Party Links
              </h4>
              <p className="text-base">
                Our website may contain links to third-party websites. These
                websites have their own privacy policies, and we are not
                responsible for the content or privacy practices of these sites.
                We encourage you to review the privacy policies of any
                third-party websites you visit.
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                8. Changes to This Privacy Policy
              </h4>
              <p className="text-base">
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We will notify you of any significant
                changes by posting the new policy on our website and updating
                the "Effective Date."
              </p>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                9. Contact Us
              </h4>
              <p className="text-base">
                If you have any questions about these Terms, please contact us
                at:
              </p>
              <ul className="flex flex-col gap-y-2">
                <li className="text-yellow-base">Smartchoice Service</li>
                <li>
                  Phone:&nbsp;
                  <Link
                    className="cursor-pointer hover:text-yellow-base"
                    href={contactData?.CONTACTS[1].href}
                  >
                    {contactData?.CONTACTS[1].label}
                  </Link>
                </li>
                <li>
                  Email:&nbsp;
                  <Link
                    className="cursor-pointer hover:text-yellow-base"
                    href={contactData?.CONTACTS[0].href}
                  >
                    {contactData?.CONTACTS[0].label}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Section>
      </div>
    </PageWrapper>
  );
};

export default PrivacyPolicy;
