import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorRoughWiringState = (state: RootState) => state.roughWiring;

export const selectorRoughWiring = createSelector(
  selectorRoughWiringState,
  (roughWiringState) => roughWiringState.data
);

export const selectorRoughWiringIsLoading = createSelector(
  selectorRoughWiringState,
  (roughWiringState) => roughWiringState.isLoading
);
