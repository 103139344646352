import React, { useState } from "react";
import { DropdownMenu } from "../DropdownMenu";
import { Link } from "../Link";
import { HEADER_NAV } from "./constants";

export const NavMenu = () => {
  const [hoveredItem, setHoveredItem] = useState<number>(null);

  const onMouseEnter = (id: number) => setHoveredItem(id);
  const onMouseLeave = () => setHoveredItem(null);

  return (
    <nav>
      <ul className="flex items-center gap-3 gl:gap-5">
        {HEADER_NAV.map(({ id, label, path, services }) => (
          <li
            key={id}
            className="relative py-4 cursor-pointer whitespace-nowrap"
            onMouseEnter={() => onMouseEnter(id as number)}
            onMouseLeave={onMouseLeave}
          >
            <Link className="hover:text-yellow-base" href={path}>
              {label}
            </Link>

            <DropdownMenu items={services} isOpen={hoveredItem === id} />
          </li>
        ))}
      </ul>
    </nav>
  );
};
