import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorMembershipState = (state: RootState) =>
  state.membershipForm;

export const selectorMembership = createSelector(
  selectorMembershipState,
  (MembershipState) => MembershipState.data
);

export const selectorMembershipIsLoading = createSelector(
  selectorMembershipState,
  (MembershipState) => MembershipState.isLoading
);
