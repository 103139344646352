import React, { FC } from "react";
import { useLocation } from "react-router";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const NotFound: FC = () => {
  const location = useLocation();

  const slugs = location.pathname.split("/");
  const pageName = slugs[slugs.length - 1];

  return (
    <PageWrapper isShownMaps={false}>
      <div className="flex justify-center items-center h-80 mt-5 border-t border-b border-yellow-base">
        <h2 className="text-3xl text-yellow-base">
          <b>«{pageName}» Page</b> - Not Found
        </h2>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
