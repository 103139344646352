import { ReactComponent as ElectricalRepairIcon } from "src/assets/icons/electrical-services/electrical-repairs.svg";
import { ReactComponent as RoughWingsIcon } from "src/assets/icons/electrical-services/rough-wiring.svg";
import { ReactComponent as ElectricalBreakerIcon } from "src/assets/icons/electrical-services/electrical-breaker-installation.svg";
import { ReactComponent as ElectricalPanelIcon } from "src/assets/icons/electrical-services/electrical-panel-installation.svg";
import { ReactComponent as ResidentialElectricalServiceIcon } from "src/assets/icons/electrical-services/residential-electrical-services.svg";
import { ReactComponent as ChargerInstallationIcon } from "src/assets/icons/electrical-services/ev-charger-installation.svg";

import { ReactComponent as ApplianceInstallationIcon } from "src/assets/icons/appliance-service/appliance-installation.svg";
import { ReactComponent as ApplianceMaintanceIcon } from "src/assets/icons/appliance-service/appliance-maintenance.svg";
import { ReactComponent as MajorApplianceRepairIcon } from "src/assets/icons/appliance-service/major-appliance-repair.svg";

import { ReactComponent as ACRepairIcon } from "src/assets/icons/hvac-services/ac-repair.svg";
import { ReactComponent as FurnaceRepairIcon } from "src/assets/icons/hvac-services/furnace-repair.svg";
import { ReactComponent as HVACMaintenanceIcon } from "src/assets/icons/hvac-services/hvac-maintenance.svg";
import { ReactComponent as ACInstallationIcon } from "src/assets/icons/hvac-services/ac-installation.svg";
import { ReactComponent as FurnaceInstallationIcon } from "src/assets/icons/hvac-services/furnace-installation.svg";
import { ReactComponent as HeatPumpInstallationIcon } from "src/assets/icons/hvac-services/heat-pump-installation.svg";

import { ReactComponent as HVACServiceIcon } from "src/assets/icons/hvac-services/hvac-service.svg";
import { ReactComponent as ElectricalServiceIcon } from "src/assets/icons/electrical-services/electrical-service.svg";
import { ReactComponent as ApplianceServiceIcon } from "src/assets/icons/appliance-service/appliance-service.svg";

import { PATHNAMES } from "src/constants/routes";
import { Services } from "./types";

export const SERVICES: Services = [
  {
    category: {
      id: crypto.randomUUID(),
      title: "HVAC Service",
      icon: HVACServiceIcon,
    },
    cards: [
      {
        id: 10,
        title: "AC Repair",
        icon: ACRepairIcon,
        path: PATHNAMES.AC_REPAIR,
      },
      {
        id: 11,
        title: "Furnace Repair",
        icon: FurnaceRepairIcon,
        path: PATHNAMES.FURNACE_REPAIR,
      },
      {
        id: 12,
        title: "HVAC Maintenance",
        icon: HVACMaintenanceIcon,
        path: PATHNAMES.HVAC_MAINTENANCE,
      },
      {
        id: 13,
        title: "AC Installation",
        icon: ACInstallationIcon,
        path: PATHNAMES.AC_INSTALLATION,
      },
      {
        id: 14,
        title: "Furnace Installation",
        icon: FurnaceInstallationIcon,
        path: PATHNAMES.FURNACE_INSTALLATION,
      },
      {
        id: 15,
        title: "Heat Pump Installation",
        icon: HeatPumpInstallationIcon,
        path: PATHNAMES.HEAT_PUMP_INSTALLATION,
      },
    ],
  },
  {
    category: {
      id: crypto.randomUUID(),
      title: "Electrical Service",
      icon: ElectricalServiceIcon,
    },
    cards: [
      {
        id: 1,
        title: "Electrical Repairs",
        icon: ElectricalRepairIcon,
        path: PATHNAMES.ELECTRICAL_REPAIRS,
      },
      {
        id: 2,
        title: "Rough Wiring",
        icon: RoughWingsIcon,
        path: PATHNAMES.ROUGH_WIRING,
      },
      {
        id: 3,
        title: "Electrical Breaker Installation",
        icon: ElectricalBreakerIcon,
        path: PATHNAMES.ELECTRICAL_BREAKER_INSTALLATION,
      },
      {
        id: 4,
        title: "Electrical Panel Installation",
        icon: ElectricalPanelIcon,
        path: PATHNAMES.ELECTRICAL_PANEL_INSTALLATION,
      },
      {
        id: 5,
        title: "Residential Electrical Services",
        icon: ResidentialElectricalServiceIcon,
        path: PATHNAMES.RESIDENTIAL_ELECTRICAL_SERVICES,
      },
      {
        id: 6,
        title: "EV Charger Installation",
        icon: ChargerInstallationIcon,
        path: PATHNAMES.EV_CHARGER_INSTALLATION,
      },
    ],
  },
  {
    category: {
      id: crypto.randomUUID(),
      title: "Appliance Service",
      icon: ApplianceServiceIcon,
    },
    cards: [
      {
        id: 7,
        title: "Major Appliance Repair",
        icon: MajorApplianceRepairIcon,
        path: PATHNAMES.MAJOR_APPLIANCE_REPAIR,
      },
      {
        id: 8,
        title: "Appliance Maintenance",
        icon: ApplianceMaintanceIcon,
        path: PATHNAMES.APPLIANCE_MAINTENANCE,
      },
      {
        id: 9,
        title: "Appliance Installation",
        icon: ApplianceInstallationIcon,
        path: PATHNAMES.APPLIANCE_INSTALLATION,
      },
    ],
  },
];
