import { FC } from "react";
import { getImageUrl } from "src/utils/getImageUrl";
import { ILink } from "src/@types";
import {
  DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME,
  DEFAULT_FOOTER_TITLE_CLASSNAME,
} from "./constants";
import { Link } from "../Link";

interface Props {
  contacts: ILink[];
}

export const ContactsList: FC<Props> = ({ contacts }) => {
  return (
    <div className="xs:min-h-50">
      <h4 className={DEFAULT_FOOTER_TITLE_CLASSNAME}>CONTACT US TODAY</h4>

      <div className={DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME} />

      <ul className="flex flex-col gap-5">
        {Boolean(contacts?.length) &&
          contacts.map(({ id, href, icon, label }) => (
            <li key={id} className="group">
              <Link
                className="flex items-center gap-3 group-hover:text-yellow-base"
                href={href}
              >
                {typeof icon === "string" && (
                  <img
                    className="w-full max-w-6"
                    src={getImageUrl(icon)}
                    alt="icon"
                  />
                )}
                <span className="break-all">{label}</span>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};
