import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorResidentialServicesState = (state: RootState) =>
  state.residentialServices;

export const selectorResidentialServices = createSelector(
  selectorResidentialServicesState,
  (residentialServicesState) => residentialServicesState.data
);

export const selectorResidentialServicesIsLoading = createSelector(
  selectorResidentialServicesState,
  (residentialServicesState) => residentialServicesState.isLoading
);
