export const COUPON_ROMB_TOP =
  "absolute -bottom-2.5 -left-2.5 w-5 h-5 rotate-45 border border-black-base border-t-yellow-base bg-black-base gl:left-auto gl:-top-2.5 gl:-right-2.5 gl:border-black-base gl:border-b-yellow-base ";

export const COUPON_ROMB_BOTTOM =
  "absolute w-5 h-5  -bottom-2.5 -right-2.5 rotate-45 border border-black-base border-l-yellow-base bg-black-base";

export const COUPON_CIRCLE = "absolute  w-8 h-8  rounded-full bg-black-base";

export const couponTerms = [
  {
    id: crypto.randomUUID(),
    text: "Coupon code must be redeemed when calling to schedule service.",
  },
  {
    id: crypto.randomUUID(),
    text: "Coupon does not apply toward service fee.",
  },
  {
    id: crypto.randomUUID(),
    text: "Cannot be combined with other offers or discounts.",
  },
  {
    id: crypto.randomUUID(),
    text: "One Coupon Limit per customer.",
  },
  {
    id: crypto.randomUUID(),
    text: "For new customers only.",
  },
];
