import React, { FC } from "react";
import cn from "classnames";
import { HashLink as Link } from "react-router-hash-link";
import { Step } from "src/@types/newsTips";

interface Props extends Omit<Step, "description"> {
  activeLink: string;
  index: number;
  onItemClick: (id: string) => void;
}

export const TableItem: FC<Props> = ({
  slug,
  title,
  index,
  onItemClick,
  activeLink,
}) => {
  const onClick = () => onItemClick(slug);

  return (
    <li
      onClick={onClick}
      className={cn(
        "text-xl font-medium py-3 px-5 border-t border-gray-light first:border-none cursor-pointer transition-colors duration-300",
        { "hover:text-yellow-base": activeLink !== slug },
        {
          "bg-yellow-base text-black-base rounded-lg": activeLink === slug,
        }
      )}
    >
      <Link to={`#${slug}`} smooth>
      {title}
      </Link>
    </li>
  );
};
