import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorCurrentCareerPostState = (state: RootState) =>
  state.currentCareerPost;

export const selectorCurrentCareerPost = createSelector(
  selectorCurrentCareerPostState,
  (currentCareerPostState) => currentCareerPostState.post
);

export const selectorCurrentCareerIsLoading = createSelector(
  selectorCurrentCareerPostState,
  (currentCareerPostState) => currentCareerPostState.isLoading
);
