export const DEFAULT_CARD_CLASSNAME =
  "p-10 w-full h-full bg-black-base rounded-card";
export const DEFAULT_CARD_WRAPPER_CLASSNAME =
  // "p-px rounded-10 bg-gradient-to-b from-goldGradientStart to-goldGradientEnd";
  "p-px rounded-10 border border-yellow-base border-opacity-50";
export const DEFAULT_DESCRIPTION_CLASSNAME = "text-16 font-arial";
export const DEFAULT_TITLE_CLASSNAME = "text-2xl lg:text-xl font-bold";

export const DEFAULT_CARD_LIST_CLASSNAME =
  "flex flex-col md:flex-row md:flex-wrap justify-center gap-10";
