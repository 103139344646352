import { FC } from "react";
import cn from "classnames";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { getImageUrl } from "src/utils/getImageUrl";
import { Button } from "src/components/Button";
import { Link } from "src/components/Link";
import { ReactComponent as BurgerMenuIcon } from "src/assets/icons/burger-menu.svg";
import { ReactComponent as MobLogoIcon } from "src/assets/icons/mob-logo-black.svg";
import { MobNavMenuList } from "./MobNavMenuList";

const ICON_SIZE = 20;

interface Props {
  hiddenPage?: boolean;
  isOpenDropdownMenu?: boolean;
  setIsOpenDropdownMenu?: (isOpen: boolean) => void;
}

export const MobNavMenu: FC<Props> = ({
  isOpenDropdownMenu,
  setIsOpenDropdownMenu,
  hiddenPage = false,
}) => {
  const detailsData = useAppSelector(selectorDetails);
  const contacts = detailsData?.CONTACTS;

  const onClose = () => setIsOpenDropdownMenu(false);

  const primaryContacts = contacts?.slice(0, 3);

  return (
    <div
      className={cn(
        "bg-yellow-base flex flex-col gap-5 fixed top-0 left-0 w-full min-h-screen z-50 transition-transform duration-300",
        {
          "transform translate-x-0": isOpenDropdownMenu,
          "transform -translate-x-full": !isOpenDropdownMenu,
        }
      )}
    >
      <div className="container w-full flex justify-between items-center px-5 py-4">
        <Link href="/">
          <MobLogoIcon className="fill-svg-black-base" />
        </Link>

        <Button onClick={onClose}>
          <BurgerMenuIcon
            className="hover:scale-125"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </Button>
      </div>

      <MobNavMenuList />

      {hiddenPage ? (
        <ul className="flex flex-col gap-4 pb-17.5 mt-auto mb-10">
          <li className="px-5">
            <Link
              className="flex items-center gap-3"
              href={detailsData?.CONTACTS[0]?.href}
            >
              <img
                className="black-base-svg w-full max-w-6"
                src={
                  typeof detailsData?.CONTACTS[0]?.icon === "string"
                    ? getImageUrl(detailsData?.CONTACTS[0]?.icon)
                    : undefined
                }
                alt="icon"
              />

              <span className="text-black-base break-all">
                {detailsData?.CONTACTS[0]?.label}
              </span>
            </Link>
          </li>

          <li className="px-5">
            <Link
              className="flex items-center gap-3"
              href={detailsData?.CONTACTS[3]?.href}
            >
              <img
                className="black-base-svg w-full max-w-6"
                src={
                  typeof detailsData?.CONTACTS[3]?.icon === "string"
                    ? getImageUrl(detailsData?.CONTACTS[3]?.icon)
                    : undefined
                }
                alt="icon"
              />

              <span className="text-black-base break-all">
                {detailsData?.CONTACTS[3]?.label}
              </span>
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="flex flex-col gap-4 pb-17.5 mt-auto mb-10">
          {Boolean(primaryContacts?.length) &&
            primaryContacts.map(({ id, href, icon, label }) => (
              <li key={id} className="px-5">
                <Link className="flex items-center gap-3" href={href}>
                  {typeof icon === "string" && (
                    <img
                      className="black-base-svg w-full max-w-6"
                      src={getImageUrl(icon)}
                      alt="icon"
                    />
                  )}
                  <span className="text-black-base break-all">{label}</span>
                </Link>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
