import { Section } from "../Section";
import { RatingSection } from "./RatingSection";
import { ReviewSwiper } from "./ReviewSwiper";
import { DEFAULT_REVIEW_WRAPPER_CLASSNAME } from "./constants";
import { RatingVariants } from "../StarRating/types";
import { useAppSelector } from "src/hooks/redux";
import { selectoReviews } from "src/redux/reviews/selectors";

const SECTION_TITLE = "Google review";

export const GoogleReviewSection = () => {
  const reviewsData = useAppSelector(selectoReviews);
  const googleReviewsData = reviewsData?.google_reviews;

  return (
    <Section titleClassName="text-40 mb-5" title={SECTION_TITLE}>
      <div className={DEFAULT_REVIEW_WRAPPER_CLASSNAME}>
        <RatingSection
          title="Overall rating"
          titleClassName="!text-2xl"
          ratingVariant={RatingVariants.DEFAULT}
          totalReviewCount={googleReviewsData?.totalReviewCount}
          ratingValue={googleReviewsData?.ratingValue}
          reviewsLink={googleReviewsData?.reviewsLink}
        />

        <ReviewSwiper isGoogle cards={googleReviewsData?.cards} />
      </div>
    </Section>
  );
};
