import { WhenToCallUsItem } from "src/components/WhenToCallUsSection/types";

export const AC_REPAIR_WHEN_TO_CALL_US: WhenToCallUsItem[] = [
  {
    id: crypto.randomUUID(),
    title: "Insufficient Cooling",
    description:
      "If your air conditioning system is struggling to maintain a comfortable temperature, it may indicate a problem with the compressor, refrigerant levels, or airflow.",
  },
  {
    id: crypto.randomUUID(),
    title: "Strange Sounds or Odors",
    description:
      "Unusual sounds such as grinding, squealing, or banging, as well as strange odors coming from your AC unit, could signal mechanical issues or electrical problems that require immediate attention.",
  },
  {
    id: crypto.randomUUID(),
    title: "Frequent Cycling On and Off",
    description:
      "If your air conditioner frequently cycles on and off without effectively cooling your space, it may be a sign of an underlying issue such as a faulty thermostat or compressor.",
  },
  {
    id: crypto.randomUUID(),
    title: "Visible Leaks or Moisture",
    description:
      "Any signs of water leaks or excessive moisture around your air conditioning unit could indicate a refrigerant leak, condensate drain blockage, or other issues that need to be addressed promptly.",
  },
  {
    id: crypto.randomUUID(),
    title: "Rising Energy Bills",
    description:
      "A sudden increase in your energy bills without a corresponding increase in usage could indicate that your air conditioning system is operating inefficiently due to a malfunction or lack of maintenance.",
  },
];

export const BENEFITS = [
  {
    id: 1,
    cardNumber: "01",
    title: "Cost Savings",
    description:
      "Avoid the expense of replacing your entire system by repairing it. A faulty air conditioner can waste power trying to achieve the desired temperature, so repairs can reduce energy costs.",
  },
  {
    id: 2,
    cardNumber: "02",
    title: "Improved Comfort",
    description:
      "Repairs help maintain a comfortable environment in your home or office by ensuring your AC operates efficiently.",
  },
  {
    id: 3,
    cardNumber: "03",
    title: "Enhanced Efficiency",
    description:
      "Even a simple tune-up can result in higher efficiency, cleaner air, and improved reliability of your air      conditioning system.",
  },
  {
    id: 4,
    cardNumber: "04",
    title: "Convenience",
    description:
      "Our technicians are equipped to handle most repairs on the spot, minimizing downtime and inconvenience.",
  },
];
