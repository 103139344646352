import { ReactComponent as CirciutBreakerIcon } from "src/assets/icons/electrical-services/BreakerInstallation/circuit-breaker.svg";
import { ReactComponent as GFCIIcon } from "src/assets/icons/electrical-services/BreakerInstallation/gfci-and-afci.svg";
import { ReactComponent as IndividualBreakerIcon } from "src/assets/icons/electrical-services/BreakerInstallation/individual-breaker .svg";

export const TYPES_OF_ELECTRICAL_BREAKER_INSTALLATION = [
  {
    id: crypto.randomUUID(),
    title: "Circuit Breaker Panel Upgrades",
    description:
      "Upgrading outdated panels to increase capacity and improve safety.",
    icon: CirciutBreakerIcon,
  },
  {
    id: crypto.randomUUID(),
    title: "GFCI and AFCI Breaker Installations",
    description:
      "Enhancing safety by installing ground fault circuit interrupter (GFCI) and arc fault circuit interrupter (AFCI) breakers in critical areas.",
    icon: GFCIIcon,
  },
  {
    id: crypto.randomUUID(),
    title: "Individual Breaker Installations",
    description:
      "Installing new breakers for dedicated circuits, ensuring reliable power distribution.",
    icon: IndividualBreakerIcon,
  },
];

export const WHY_ELECTRICAL_BREAKER_INSTALLATION_IS_IMPORTANT = [
  {
    id: crypto.randomUUID(),
    title: "Safety",
    description:
      "Prevents electrical fires and protects against electrical shocks by interrupting unsafe electrical currents.",
    cardNumber: "01",
  },
  {
    id: crypto.randomUUID(),
    title: "Reliability",
    description:
      "Ensures consistent power distribution, reducing the risk of power outages and equipment damage.",
    cardNumber: "02",
  },
  {
    id: crypto.randomUUID(),
    title: "Compliance",
    description:
      "Meets electrical codes and regulations, ensuring your installation is safe and legal.",
    cardNumber: "03",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_BREAKER_INSTALLATION = [
  {
    id: crypto.randomUUID(),
    title: "Expertise",
    description:
      "Our licensed electricians have extensive experience in breaker installations, ensuring precision and compliance with industry standards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality",
    description:
      "We use high-quality breakers and components to ensure durability and performance.",
  },
  {
    id: crypto.randomUUID(),
    title: "Workmanship Guaranteed",
    description:
      "We stand behind our work with a guarantee of quality craftsmanship.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction with clear communication, timely service, and meticulous attention to detail.",
  },
];
