import { FC } from "react";
import cn from "classnames";

import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
  DEFAULT_TITLE_CLASSNAME,
} from "../constants";
import { ICardProps } from "../types";

export const ServiceCard: FC<ICardProps> = ({
  icon: Icon,
  title,
  description,
  className,
  containerClassName,
}) => (
  <div
    className={cn(
      DEFAULT_CARD_WRAPPER_CLASSNAME,
      "md:max-w-77.5",
      containerClassName
    )}
  >
    <div className={cn(DEFAULT_CARD_CLASSNAME, className)}>
      <div className="flex justify-center items-center mx-auto mb-5 lg:mb-11 w-25 h-25 border border-yellow-base rounded-full">
        <Icon />
      </div>

      <h4 className={cn(DEFAULT_TITLE_CLASSNAME, "mb-5 text-center")}>
        {title}
      </h4>

      <p className="text-16 text-center lg:text-start">{description}</p>
    </div>
  </div>
);
