import { FC } from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ReactComponent as Arrow } from "src/assets/icons/arrow.svg";
import { ReviewCard } from "./ReviewCard";
import { IReviewCard } from "./types";

interface Props {
  cards: IReviewCard[];
  isGoogle?: boolean;
}

export const ReviewSwiper: FC<Props> = ({ cards, isGoogle = false }) => {
  const nextButtonClass = isGoogle
    ? "custom-swiper-google-rewiew-button-next"
    : "custom-swiper-yelp-rewiew-button-next";

  const prevButtonClass = isGoogle
    ? "custom-swiper-google-rewiew-button-prev"
    : "custom-swiper-yelp-rewiew-button-prev";

  return (
    <div className="relative gl:px-14 overflow-hidden">
      <Swiper
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={20}
        className="max-w-256 pb-px"
        navigation={{
          nextEl: `.${nextButtonClass}`,
          prevEl: `.${prevButtonClass}`,
        }}
        breakpoints={{
          768: {
            spaceBetween: 40,
          },
        }}
      >
        {Boolean(cards?.length) &&
          cards.map((card) => (
            <SwiperSlide key={card.id} className="max-w-70">
              <ReviewCard {...card} />
            </SwiperSlide>
          ))}
      </Swiper>

      <Arrow
        className={cn(
          "hidden gl:block absolute top-24 left-0 w-10 h-10 cursor-pointer fill-yellow-base hover:scale-110",
          prevButtonClass,
          "rotate-180"
        )}
      />

      <Arrow
        className={cn(
          "hidden gl:block absolute top-24 right-0 w-10 h-10 cursor-pointer fill-yellow-base hover:scale-110",
          nextButtonClass
        )}
      />
    </div>
  );
};
