import { FC } from "react";
import cn from "classnames";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ReactComponent as VerticalBarCode } from "src/assets/icons/vertical-bar-code.svg";
import { ReactComponent as HorizontalalBarCode } from "src/assets/icons/horizontal-bar-code.svg";

import {
  COUPON_CIRCLE,
  COUPON_ROMB_BOTTOM,
  COUPON_ROMB_TOP,
} from "./constants";

interface CouponProps {
  label: string;
  discount?: string;
  onButtonClick?: () => void;
}

export const Coupon: FC<CouponProps> = ({ label, discount, onButtonClick }) => (
  <div className="flex flex-col gl:flex-row">
    <div className="relative flex flex-col items-center justify-between h-60 w-45 px-4 py-7 gl:px-7 gl:py-5 border bg-black-base border-yellow-base rounded-10 gl:h-auto gl:w-110 ">
      <div className={cn(COUPON_ROMB_TOP)} />
      <div className={cn(COUPON_ROMB_BOTTOM)} />

      <div
        className={cn(
          COUPON_CIRCLE,
          "top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 !-rotate-45  !border !border-black-base !border-b-yellow-base !border-l-yellow-base gl:top-1/2 gl:!left-0 gl:!-translate-x-1/2 gl:!rotate-45 gl:!border-black-base gl:!border-t-yellow-base gl:!border-r-yellow-base"
        )}
      />

      <p className="text-sm ont-manrope font-bold line-clamp-5 gl:text-xl">
        {label}
      </p>

      <hr className="w-1/4 border-yellow-base" />

      <Button
        className="text-sm"
        variant={ButtonVariants.SECONDARY}
        onClick={onButtonClick}
      >
        SCHEDULE APPOINTMENТ
      </Button>
    </div>
    <div className="relative flex flex-col gap-y-3 gl:flex-row justify-center w-45 items-center gap-x-8 -z-10 py-6 px-7   rounded-10 bg-yellow-base gl:w-auto gl:pl-11 gl:pr-8 gl:py-7">
      <p className="text-32 rotated-text gl:-rotate-180 gl:text-40">
        {discount}
      </p>

      <VerticalBarCode className="hidden gl:block" />

      <HorizontalalBarCode className="w-30 bg-no-repeat gl:hidden" />

      <div
        className={cn(
          COUPON_CIRCLE,
          "bottom-0 left-1/2 translate-y-1/2 -translate-x-1/2 gl:top-1/2 gl:left-auto gl:right-0 gl:-translate-y-1/2 gl:translate-x-1/2 "
        )}
      />
    </div>
  </div>
);
