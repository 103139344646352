import  { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { ServiceCard } from "./ServiceCard";
import { PrevButton } from "./PrevButton";
import { NextButton } from "./NextButton";
import { IServiceCategory } from "./types";

import "swiper/css/navigation";

export const ServiceSwiper: FC<IServiceCategory> = ({ cards, category }) => {
  const { title, icon: Icon } = category;

  return (
    <div className="max-w-80 lg:max-w-87.5">
      <Swiper
        className="flex flex-col-reverse serviceSwiper px-px"
        modules={[Navigation]}
        spaceBetween={8}
        slidesPerView={3}
        slideActiveClass="swiper-slide-active"
        centeredSlides={true}
        centeredSlidesBounds={true}
        breakpoints={{
          1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            centeredSlidesBounds: false,
            centeredSlides: false,
          },
        }}
      >
        <div className="flex items-center justify-between mb-5 lg:mb-6">
          <PrevButton />

          <div className="flex items-center gap-2.5">
            <div className="w-8 h-8">
              <Icon />
            </div>

            <h4 className="uppercase text-xl font-extrabold">{title}</h4>
          </div>

          <NextButton />
        </div>

        <div>
          {cards.map((card) => (
            <SwiperSlide key={card.id}>
              <ServiceCard {...card} />
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};
