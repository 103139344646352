import React, { FC } from "react";
import cn from "classnames";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
  DEFAULT_DESCRIPTION_CLASSNAME,
  DEFAULT_TITLE_CLASSNAME,
} from "../constants";
import { ICardProps } from "../types";

export const DefaultCard: FC<ICardProps> = ({
  cardNumber,
  title,
  description,
  icon: Icon,
  className,
  containerClassName,
}) => {
  return (
    <div
      className={cn(
        DEFAULT_CARD_WRAPPER_CLASSNAME,
        "max-w-full w-full md:max-w-95 gl:max-w-120.5",
        containerClassName
      )}
    >
      <div className={cn(DEFAULT_CARD_CLASSNAME, "px-6 py-5", className)}>
        <div
          className={cn(
            "flex items-center gap-5 mb-2 lg:mb-1",
            { "flex-col lg:flex-row mb-5 lg:mb-5": Icon } // Додаємо flex-col тільки якщо є Icon
          )}
          // className="flex flex-col items-center gap-5 mb-5 lg:mb-1"
        >
          {Icon ? (
            <div className="flex justify-center items-center p-6 w-25 h-25 border border-yellow-base rounded-full">
              <Icon className="filter yellow-base-svg"/>
            </div>
          ) : (
            <span className="text-5xl text-yellow-base font-bebas">
              {cardNumber}
            </span>
          )}

          <h4 className={DEFAULT_TITLE_CLASSNAME}>{title}</h4>
        </div>

        <p className={DEFAULT_DESCRIPTION_CLASSNAME}>{description}</p>
      </div>
    </div>
  );
};
