import React from "react";
import cn from "classnames";
import { useSwiper } from "swiper/react";
import { Button } from "src/components/Button";
import { ReactComponent as ArrowLeftIcon } from "src/assets/icons/arrow-left-yellow-base.svg";

export const PrevButton = () => {
  const swiper = useSwiper();

  const handleOnClick = () => {
    swiper.slidePrev();
  };

  return (
    <Button onClick={handleOnClick} className={cn("w-6 h-6 hover:scale-125")}>
      <ArrowLeftIcon className="w-full h-full" />
    </Button>
  );
};
