import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorAcRepairState = (state: RootState) => state.acRepair;

export const selectorAcRepair = createSelector(
  selectorAcRepairState,
  (acRepairState) => acRepairState.data
);

export const selectorAcRepairsIsLoading = createSelector(
  selectorAcRepairState,
  (acRepairState) => acRepairState.isLoading
);
