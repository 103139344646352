import React, { FC, FunctionComponent, SVGProps } from "react";
import { ReactComponent as FullStarIcon } from "src/assets/icons/full-start-orange-base.svg";
import { ReactComponent as EmptyStarIcon } from "src/assets/icons/empty-start-yellow-base.svg";
import { ReactComponent as HalfStarIcon } from "src/assets/icons/half-start-yellow-base.svg";
import { IStarRating } from "./types";

interface Props extends IStarRating {
  fullIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  emptyIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  halfIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const StarRating: FC<Props> = ({
  total = 5,
  value = 0,
  starSize = 30,
  fullIcon: FullIcon = FullStarIcon,
  emptyIcon: EmptyIcon = EmptyStarIcon,
  halfIcon: HalfIcon = HalfStarIcon,
}) => {
  const fullStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;
  const emptyStars = total - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className="flex justify-center items-center gap-1 gl:justify-normal">
      {[...Array(fullStars)].map((_, i) => (
        <FullIcon
          key={`full-${i}`}
          style={{
            width: `${starSize}px`,
          }}
        />
      ))}

      {hasHalfStar && (
        <HalfIcon
          style={{
            width: `${starSize}px`,
          }}
        />
      )}

      {[...Array(emptyStars)].map((_, i) => (
        <EmptyIcon
          key={`empty-${i}`}
          style={{
            width: `${starSize}px`,
          }}
        />
      ))}
    </div>
  );
};
