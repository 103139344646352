import { FC } from "react";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { Link } from "../Link";
import { ReactComponent as BookIcon } from "src/assets/icons/book-black-base.svg";


interface Props {
  onButtonClick?: VoidFunction;
}

export const BookOrCall: FC<Props> = ({ onButtonClick }) => {
  const contactsData = useAppSelector(selectorDetails);

  return (
    <div className="hidden lg:flex flex-col sm:flex-row items-center gap-5 w-full">
      <Button
        className="flex items-center gap-2  border-goldGradientOlive bg-gradient-to-b from-heroBtnStart to-heroEnd lg:py-3"
        type="button"
        variant={ButtonVariants.PRIMARY}
        onClick={onButtonClick}
      >
        <BookIcon className="min-w-5 min-h-5" />

        <span className="text-18 font-extrabold whitespace-nowrap">
          Book now
        </span>
      </Button>

      <div className="underline underline-offset-1 group">
        <Link
          className="group-hover:text-yellow-base"
          href={contactsData?.CONTACTS[1]?.href}
          isExternalLink
        >
          or call us &nbsp;
          {contactsData?.CONTACTS[1]?.label}
        </Link>
      </div>
    </div>
  );
};
