import { useEffect, useRef } from "react";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getMembershipPageAsync } from "src/redux/membershipPage/actions";
import {
  selectorMembershipPage,
  selectorMembershipPageIsLoading,
} from "src/redux/membershipPage/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { WhyJoin } from "src/page-components/Membership/WhyJoin";
import { HowItWorks } from "src/page-components/Membership/HowItWorks";
import { JoinToday } from "src/page-components/Membership/JoinToday";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { BookNowModal } from "src/components/BookNowModal";
import { MembershipModal } from "src/components/MembershipModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";

const Membership = () => {
  const sectionRef = useRef(null);

  const bookNow = useModal();
  const membership = useModal();

  const dispatch = useAppDispatch();

  const contactData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorMembershipPage);
  const isLoading = useAppSelector(selectorMembershipPageIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getMembershipPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper isShownMaps={false}>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Join the Smartchoice Service Membership Program"
            image={pageData?.banner_background}
            name="Membership"
            sectionRef={sectionRef}
          >
            <div className="font-bold">
              Ensure Your Comfort Year-Round with Our Exclusive HVAC Tune-Up
              Service
            </div>
            <p>
              At Smartchoice Service, we understand the importance of
              maintaining a comfortable and efficient home throughout the year.
              That’s why we are excited to introduce our&nbsp;
              <span className="text-yellow-base font-bold">
                Membership Program
              </span>
              , designed to provide you with peace of mind and top-notch HVAC
              care.
            </p>
          </Hero>
        )}

        <div ref={sectionRef} className="container">
          <WhyJoin />
          <HowItWorks
            membershipData={pageData?.how_it_works}
            onButtonClick={membership.openModal}
          />
        </div>

        <JoinToday
          image={pageData?.join_today_background}
          phone={contactData?.CONTACTS[2]}
        />
      </PageWrapper>

      <BookNowModal isOpen={bookNow.isOpenModal} onClose={bookNow.closeModal} />
      <MembershipModal
        isOpen={membership.isOpenModal}
        onClose={membership.closeModal}
      />
    </>
  );
};

export default Membership;
