import { FC, RefObject } from "react";
import { useMediaQuery } from "react-responsive";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { scrollToSection } from "src/utils/scrollToSection";
import { PATHNAMES } from "src/constants/routes";
import { SCREEN_BREAKPOINTS } from "src/constants/screenBreakpoints";
import { getImageUrl } from "src/utils/getImageUrl";
import { INewsTipsItem, Step } from "src/@types/newsTips";
import { TableOfContent } from "./TableOfContent";

interface Props extends Pick<INewsTipsItem, "description" | "title" | "date"> {
  heroImage: string;
  sectionRef?: RefObject<HTMLElement>;
  steps?: Step[];
}

export const HeroPost: FC<Props> = ({
  heroImage,
  title,
  date,
  description,
  sectionRef,
  steps,
}) => {
  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { path: PATHNAMES.NEWS_TIPS, name: "News & Tips" },
    { name: title },
  ];

  const onClick = () => {
    if (sectionRef) {
      scrollToSection(sectionRef);
    }
  };

  const isLGScreenBreakpoint = useMediaQuery({
    minWidth: SCREEN_BREAKPOINTS.LG,
  });

  return (
    <div className="relative w-full min-h-screen lg:h-200 overflow-hidden">
      <div className="absolute z-10 w-full h-full bg-gradient-to-r from-black-base to-transparent" />
      <img
        className="absolute w-full h-full object-cover object-center"
        src={getImageUrl(heroImage)}
        alt="hero img"
      />

      <div className="wrapper flex flex-col justify-between gap-20 lg:gap-30 relative z-20">
        <BreadCrumbs breadCrumbsItems={breadCrumbsItems} />

        <div className="flex flex-col lg:flex-row justify-between gap-20">
          <div className="flex flex-col gap-6 sm:max-w-160 w-full">
            <span className="text-yellow-base">{date}</span>
            <h2 className="text-40 leading-snug font-bebas lg:mb-6 gl:text-5xl">
              {title}
            </h2>

            {description && (
              <div
                className="mb-6 font-manrope"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}

            <Button
              onClick={onClick}
              variant={ButtonVariants.PRIMARY}
              className="w-full xs:max-w-70 xs:text-xl"
            >
              Read On
            </Button>
          </div>
          {isLGScreenBreakpoint && (
            <TableOfContent steps={steps} className="self-start" />
          )}
        </div>
      </div>
    </div>
  );
};
