import { FC, useState } from "react";
import cn from "classnames";
import { IImage } from "src/@types";
import { ImageWithLoader } from "src/components/ImageWithLoader";
import { BookOrCall } from "src/components/WhenToCallUsSection/BookOrCall";
import { Button } from "src/components/Button";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow.svg";

interface AreaText {
  subTitle: string;
  description: string;
}

interface Section {
  id?: string;
  title: string;
  areaText: AreaText[];
}

interface CallUsDoubleDescProps {
  sectionTitle: string;
  sectionSubTitle?: string;

  imageClassname?: string;
  containerImageClassName?: string;
  data: Section[];
  isReverse?: boolean;
  showBookOrCall?: boolean;
  onButtonClick?: VoidFunction;
  image?: IImage;
  containerClassName?: string;
}

export const CallUsDoubleDesc: FC<CallUsDoubleDescProps> = ({
  sectionTitle,
  sectionSubTitle,
  image,
  imageClassname,
  containerImageClassName,
  data,
  isReverse,
  onButtonClick,
  showBookOrCall = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div className="wrapper flex flex-col">
      <h3 className="text-40 leading-9 mb-8 lg:text-64 lg:leading-16 lg:mb-15">
        {sectionTitle}
      </h3>

      {sectionSubTitle && (
        <p className="mx-auto block text-base mb-5 gl:mb-10">
          {" "}
          {sectionSubTitle}
        </p>
      )}

      <div
        className={cn(
          "flex flex-col gl:flex-row items-center gl:items-start gap-5 gl:gap-15",
          {
            "gl:!flex-row-reverse": isReverse,
          }
        )}
      >
        <ImageWithLoader
          className={cn(
            "rounded-10 object-contain gl:object-cover",
            imageClassname
          )}
          containerClassName={cn(
            "sm:max-h-143.5 gl:max-w-155 gl:max-h-176.75 border border-yellow-base rounded-10 overflow-hidden",
            containerImageClassName
          )}
          image={image}
        />

        <div className="w-full">
          <div
            className={cn(
              "flex flex-col gap-y-5 mb-10 max-h-[525px] sm:max-h-[530px] overflow-hidden",
              {
                "!max-h-full": isOpen,
              }
            )}
          >
            {data.map((section) => (
              <div key={section.id || section.title}>
                <h4 className="mb-1 text-2xl font-manrope font-bold text-yellow-base">
                  {section.title}
                </h4>

                <div className="flex flex-col">
                  {section.areaText.map((item, index) => (
                    <div key={index}>
                      <h5 className="mb-1 text-xl font-arial font-bold">
                        {item.subTitle}
                      </h5>

                      <p className="text-16 font-arial">{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Button
            className={cn("max-w-10 max-h-10 mx-auto mb-10 rotate-90", {
              "!-rotate-90": isOpen,
            })}
            onClick={handleOpenClick}
          >
            <ArrowIcon className="w-full h-full fill-yellow-base" />
          </Button>

          {showBookOrCall && <BookOrCall onButtonClick={onButtonClick} />}
        </div>
      </div>
    </div>
  );
};
