import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { COMMON_REASONS_FOR_ELECTRICAL_FAILURES } from "./constants";

const SECTION_TITLE = "Common Reasons for Electrical Failures";
const SECTION_DESC =
  "Electrical systems can fail for numerous reasons, and understanding these can help in preventing future issues:";

export const CommonReasons = () => (
  <Section
    className="wrapper mx-auto"
    descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10 lg:text-center"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {COMMON_REASONS_FOR_ELECTRICAL_FAILURES.map((reason, index) => (
        <SectionCard
          key={reason.id}
          containerClassName={
            index === COMMON_REASONS_FOR_ELECTRICAL_FAILURES.length - 1
              ? "col-span-2 mx-auto"
              : ""
          }
          {...reason}
        />
      ))}
    </div>
  </Section>
);
