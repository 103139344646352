import { FC, memo, useMemo } from "react";
import cn from "classnames";
import { ReactComponent as GoogleIcon } from "src/assets/icons/google-button-icon.svg";
import { ReactComponent as YelpIcon } from "src/assets/icons/yelp.svg";
import { ReactComponent as FullStarIcon } from "src/assets/icons/full-yelp-star.svg";
import { ReactComponent as EmptyStarIcon } from "src/assets/icons/empty-yelp-star.svg";
import { ReactComponent as HalfStarIcon } from "src/assets/icons/half-yelp-star.svg";
import { Link } from "../Link";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { StarRating } from "../StarRating";
import { RatingVariants } from "../StarRating/types";

interface Props {
  ratingVariant?: RatingVariants;
  titleClassName?: string;
  totalReviewCount?: number;
  title: string;
  reviewsLink?: string;
  ratingValue: number;
}

export const RatingSection: FC<Props> = ({
  ratingVariant = RatingVariants.DEFAULT,
  titleClassName,
  totalReviewCount,
  title,
  reviewsLink,
  ratingValue,
}) => {
  const Icon = useMemo(() => {
    switch (ratingVariant) {
      case RatingVariants.YELP:
        return YelpIcon;

      default:
        return GoogleIcon;
    }
  }, [ratingVariant]);

  const Rating = memo(() => {
    switch (ratingVariant) {
      case RatingVariants.YELP:
        return (
          <StarRating
            value={ratingValue}
            fullIcon={FullStarIcon}
            emptyIcon={EmptyStarIcon}
            halfIcon={HalfStarIcon}
          />
        );

      default:
        return <StarRating value={ratingValue} />;
    }
  });

  return (
    <div className=" gl:min-w-55 gl:max-w-55">
      <div className=" mb-10">
        <h4
          className={cn(
            "text-center mb-4 text-32 font-bold gl:text-start gl:mb-5",
            titleClassName
          )}
        >
          {title}
        </h4>

        <Rating />

        {Boolean(totalReviewCount) && (
          <p className="mt-4 text-center text-16 font-medium gl:text-start">
            Based on {totalReviewCount} reviews
          </p>
        )}
      </div>

      <Link href={reviewsLink} isExternalLink={true} isOpenNewTab={true}>
        <Button
          className="flex items-center gap-3 w-full max-w-70 mx-auto"
          variant={ButtonVariants.PRIMARY}
        >
          Review us on <Icon />
        </Button>
      </Link>
    </div>
  );
};
