import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorMaintenanceState = (state: RootState) => state.maintenance;

export const selectorMaintenance = createSelector(
  selectorMaintenanceState,
  (maintenanceState) => maintenanceState.data
);

export const selectorMaintenanceIsLoading = createSelector(
  selectorMaintenanceState,
  (maintenanceState) => maintenanceState.isLoading
);
