import { Section } from "src/components/Section";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-yellow-base.svg";
import { SignUpMembershipCard } from "./SignUpMembershipCard";
import { FC } from "react";
import { MembershipData } from "src/@types/membership";
// import cn from "classnames";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const SECTION_TITLE = "How It Works";

interface Props {
  membershipData: MembershipData[];
  onButtonClick?: VoidFunction;
}

export const HowItWorks: FC<Props> = ({ onButtonClick, membershipData }) => (
  <Section className="wrapper relative" title={SECTION_TITLE}>
    <div className="max-w-155 mx-auto flex flex-col gap-5">
      <div className="flex items-center gap-5">
        <div className="flex items-center justify-center rounded-full border border-yellow-base w-10 h-10">
          <CheckIcon />
        </div>

        <p className="flex-1">
          Let us know that you want to sign up for the Membership Program, and
          that's all! We'll take care of the rest.
        </p>
      </div>

      <SignUpMembershipCard
        onButtonClick={onButtonClick}
        membershipData={membershipData}
      />
    </div>

    {/* <div
        className={cn(
          DEFAULT_YELLOW_BLUR,
          "right-0 top-0 -z-10 !translate-y-1/2"
        )}
      /> */}
  </Section>
);
