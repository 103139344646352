import { FC } from "react";
// import cn from "classnames";
import AnimatedNumbers from "react-animated-numbers";
import { Section } from "src/components/Section";
import { selectorHome } from "src/redux/home/selectors";
import { useAppSelector } from "src/hooks/redux";

export const ServiceInNumber: FC = () => {
  const pageData = useAppSelector(selectorHome);
  const numbers = pageData?.service_in_numbers ?? [];

  return (
    <Section
      className="relative wrapper "
      titleClassName="mb-8 xl:mb-20"
      title="Smartchoice Service In Number"
    >
      {/* <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 !-translate-y-1/2")} /> */}

      <div className="relative flex flex-col gap-y-5 overflow-hidden xl:flex-row xl:items-center xl:justify-evenly ">
        {numbers.map(({ id, number, label }) => (
          <div key={id} className="flex flex-col items-center text-center">
            <div className="flex items-center">
              <span className="h-8 text-[#ffdc5c] text-40 font-bold">+</span>

              <AnimatedNumbers
                transitions={(index) => ({
                  type: "spring",
                  duration: index + 0.3,
                })}
                animateToNumber={number}
                fontStyle={{
                  fontWeight: "700",
                  fontSize: "40px",
                  lineHeight: "42px",
                  fontFamily: "Manrope",
                  letterSpacing: "10px",
                  color: "#ffdc5c",
                }}
              />
            </div>

            <p className="text-xl font-manrope text-yellow-base font-extrabold">
              {label}
            </p>
          </div>
        ))}
      </div>
    </Section>
  );
};
