import { FC, useEffect } from "react";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { selectorNewsTips } from "src/redux/newsTips/selectors";
import { Section } from "src/components/Section";
import { NewsSwiper } from "src/components/NewsSwiper";
import { getNewsTipsAsync } from "src/redux/newsTips/actions";

interface Props {
  className?: string;
}

export const NewsTips: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();

  const newsData = useAppSelector(selectorNewsTips);
  const newsList = newsData?.results || [];

  useEffect(() => {
    if (!newsList.length) {
      dispatch(getNewsTipsAsync(1));
    }
  }, [newsList.length, dispatch]);

  if (!newsData) return null;

  return (
    <Section
      className={cn("wrapper xl:pt-25 xl:pb-30", className)}
      titleClassName="mb-5 xl:mb-25"
      title="News and Tips"
    >
      <NewsSwiper newsList={newsList} />
    </Section>
  );
};
