import { ReactComponent as PanelUpgradesIcon } from "src/assets/icons/electrical-services/panel-installation/panel-upgrades.svg";
import { ReactComponent as NewInstallationsIcon } from "src/assets/icons/electrical-services/panel-installation/new-installations.svg";
import { ReactComponent as ServicePanelInspectionsIcon } from "src/assets/icons/electrical-services/panel-installation/service-panel-inspections.svg";
import { ReactComponent as CodeComplianceIcon } from "src/assets/icons/electrical-services/panel-installation/code-compliance.svg";

export const WHY_CHOOSE_PROFESSIONAL_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Safety",
    cardNumber: "01",
    description:
      "Ensures your electrical panel is installed safely and meets current electrical codes.",
  },
  {
    id: crypto.randomUUID(),
    title: "Reliability",
    cardNumber: "02",
    description:
      "Expert technicians provide reliable solutions tailored to your electrical requirements.",
  },
  {
    id: crypto.randomUUID(),
    title: "Capacity",
    cardNumber: "03",
    description:
      "Upgrading your electrical panel increases capacity to handle modern electrical demands.",
  },
  {
    id: crypto.randomUUID(),
    title: "Expertise",
    cardNumber: "04",
    description:
      "Trained professionals with knowledge of electrical panel installation techniques and best practices.",
  },
];

export const PANEL_INSTALLATION_SERVICES = [
  {
    id: crypto.randomUUID(),
    title: "Panel Upgrades",
    icon: PanelUpgradesIcon,
    description:
      "Upgrading outdated electrical panels to accommodate increased electrical loads.",
  },
  {
    id: crypto.randomUUID(),
    title: "New Installations",
    icon: NewInstallationsIcon,
    description:
      "Installing new electrical panels for new constructions or renovations.",
  },
  {
    id: crypto.randomUUID(),
    title: "Service Panel Inspections",
    icon: ServicePanelInspectionsIcon,
    description:
      "Assessing the condition of existing electrical panels and recommending upgrades.",
  },
  {
    id: crypto.randomUUID(),
    title: "Code Compliance",
    icon: CodeComplianceIcon,
    description:
      "Ensuring all installations comply with local electrical codes and regulations.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Experience",
    description:
      "We have years of experience in electrical panel installations.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality Assurance",
    description:
      "We ensure high-quality installations with attention to detail.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction with reliable service and expert solutions.",
  },
  {
    id: crypto.randomUUID(),
    title: "Workmanship Guaranteed",
    description:
      "We stand behind our work with a guarantee of quality craftsmanship.",
  },
  {
    id: crypto.randomUUID(),
    title: "Compliance",
    description:
      "We adhere to all local electrical codes and regulations for your peace of mind.",
  },
];
