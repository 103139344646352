import { FC, useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getAcRepairPageAsync } from "src/redux/services/hvac/acRepair/actions";
import {
  selectorAcRepair,
  selectorAcRepairsIsLoading,
} from "src/redux/services/hvac/acRepair/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { BenefitsRepair } from "src/page-components/Services/HVAC/ACRepair/BenefitsRepair";
import { AirConditioning } from "src/page-components/Services/HVAC/ACRepair/AirConditioning";
import { ReadyToHave } from "src/page-components/Services/HVAC/ACRepair/ReadyToHave";
import { AC_REPAIR_WHEN_TO_CALL_US } from "src/page-components/Services/HVAC/ACRepair/constants";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { ReviewSection } from "src/components/ReviewSection";
import { OtherServices } from "src/components/OtherServices";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const TITLE_WHEN_TO_CALL_US_SECTION = "When to Call Us for Service";
const DESC_WHEN_TO_CALL_US_SECTION =
  "Knowing when to call for professional air conditioning repair can help prevent minor issues from escalating into costly repairs or system failures. If you notice any of the following signs, it's time to contact.";
const SUBTITLE_WHEN_TO_CALL_US_SECTION =
  "Smartchoice Service for prompt and reliable service:";

const ACRepair: FC = () => {
  const sectionRef = useRef(null);
  const bookNow = useModal();

  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorAcRepair);
  const isLoading = useAppSelector(selectorAcRepairsIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getAcRepairPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Expert Air Conditioning Repair in Southern California"
            image={pageData?.banner_background}
            name="AC Repair"
            sectionRef={sectionRef}
          >
            When your air conditioning system needs repair, you deserve the
            confidence of knowing you’re bringing a true HVAC expert into your
            home. At Smartchoice Service, we provide top-notch repair services
            for all AC models, regardless of where or when you bought them. We
            service every brand on the market, including Lennox, Carrier, York,
            Bryant, Comfort Air, Rheem, and more.
            <br />
            <br />
            Our repair technicians are among the best in the industry. They are
            highly knowledgeable, communicative, and respectful of your home.
            You can trust them to quickly identify and resolve any AC issues.
          </Hero>
        )}

        <div className="container">
          <BenefitsRepair sectionRef={sectionRef} />

          <AirConditioning />

          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />

          <WhenToCallUsSection
            imageClassname="max-h-176.75 object-top"
            title={TITLE_WHEN_TO_CALL_US_SECTION}
            description={DESC_WHEN_TO_CALL_US_SECTION}
            image={pageData?.when_to_call_us}
            subtitle={SUBTITLE_WHEN_TO_CALL_US_SECTION}
            items={AC_REPAIR_WHEN_TO_CALL_US}
            onButtonClick={bookNow.openModal}
          />
        </div>

        <ReadyToHave
          image={pageData?.ready_to_have_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}

          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default ACRepair;
