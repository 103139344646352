import { FC, useEffect } from "react";
import cn from "classnames";
import { GoogleReviewSection } from "./GoogleReviewSection";
import { YelpReviewSection } from "./YelpReviewSection";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  selectoReviews,
  selectorReviewstIsLoading,
} from "src/redux/reviews/selectors";
import { Loader } from "../Loader";
import { Sizes } from "src/@types/sizes";
import { getReviewsAsync } from "src/redux/reviews/actions";

interface Props {
  className?: string;
}

export const ReviewSection: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();

  const reviewsData = useAppSelector(selectoReviews);
  const isLoading = useAppSelector(selectorReviewstIsLoading);

  useEffect(() => {
    if (!reviewsData) {
      dispatch(getReviewsAsync());
    }
  }, [reviewsData]);

  return isLoading ? (
    <div className="flex justify-center items-center min-h-96">
      <Loader size={Sizes.L} />
    </div>
  ) : (
    <div
      className={cn(
        "wrapper flex flex-col gap-25 gl:px-17.5 gl:py-25",
        className
      )}
    >
      <YelpReviewSection />

      <GoogleReviewSection />
    </div>
  );
};
