import cn from "classnames";
import { FC } from "react";
import { ImageWithLoader } from "src/components/ImageWithLoader";
import { CITY_CLASSNAME } from "src/pages/ServiceArea/Region";
import { IImage } from "src/@types";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

interface Props {
  img: IImage;
  city: string;
}

export const ImageTextSection: FC<Props> = ({ city, img }) => (
  <section className="wrapper relative">
    {/* <div
        className={cn(DEFAULT_YELLOW_BLUR, "hidden top-0 right-0 sm:block")}
      /> */}

    <h3 className="mb-8 sm:mb-15">
      HVAC, Electrical, and Appliance Services in &nbsp;
      <span className="text-yellow-base">{city}</span> &nbsp;
    </h3>

    <div className="flex flex-col justify-center  gap-y-5 gap-x-15 lg:flex-row">
      <ImageWithLoader
        containerClassName="sm:max-h-143.5 gl:max-w-155 gl:max-h-176.75 h-full w-full border border-yellow-base rounded-10 overflow-hidden"
        className={cn("w-full lg:h-176.75 rounded-10")}
        image={img}
      />

      <ul className="flex flex-col gap-y-5 lg:max-w-1/2">
        <li>
          <h4 className="text-2xl text-yellow-base font-bold font-manrope">
            Our History in <span className={CITY_CLASSNAME}>{city}</span>
          </h4>
          <p className="text-16 font-manrope">
            Since 2017, Smartchoice Service Inc. has been serving {city}
            residents with top-notch HVAC, electrical, and appliance repair
            services. We started as a family-owned business with a mission to
            provide reliable solutions and exceptional customer care. Over the
            years, we have grown our team and expanded our services to meet the
            evolving needs of {city} homeowners and businesses.
          </p>
        </li>
        <li>
          <h4 className="text-2xl text-yellow-base font-bold font-manrope">
            HVAC Services in <span className={CITY_CLASSNAME}>{city}</span>
          </h4>
          <p className="text-16 font-manrope">
            At Smartchoice Service Inc., we specialize in HVAC installation,
            repair, and maintenance services designed to keep your indoor
            environment comfortable and energy-efficient. Our certified
            technicians are skilled in handling all HVAC brands and models,
            ensuring reliable performance and optimal comfort for your home or
            business in <span className={CITY_CLASSNAME}>{city}</span>.
          </p>
        </li>
        <li>
          <h4 className="text-2xl text-yellow-base font-bold font-manrope">
            Electrical Services in{" "}
            <span className={CITY_CLASSNAME}>{city}</span>
          </h4>
          <p className="text-16 font-manrope">
            Smartchoice Service Inc. offers comprehensive electrical repair and
            installation services in{" "}
            <span className={CITY_CLASSNAME}>{city}</span>. From troubleshooting
            electrical issues to upgrading electrical systems, our licensed
            electricians deliver safe and reliable solutions. We prioritize
            safety and compliance with local codes to ensure the electrical
            systems in your <span className={CITY_CLASSNAME}>{city}</span>{" "}
            property are up to standard.
          </p>
        </li>
        <li>
          <h4 className="text-2xl text-yellow-base font-bold font-manrope">
            Appliance Services in <span className={CITY_CLASSNAME}>{city}</span>
          </h4>
          <p className="text-16 font-manrope">
            Count on Smartchoice Service Inc. for expert appliance repair
            services in <span className={CITY_CLASSNAME}>{city}</span>. Whether
            it's your refrigerator, dishwasher, washer, dryer, or other
            appliances, our technicians have the expertise to diagnose and
            repair issues promptly. We use quality parts and provide lasting
            solutions to extend the lifespan of your appliances.
          </p>
        </li>
      </ul>
    </div>
  </section>
);
