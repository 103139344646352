import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorApplianceMajorRepairState = (state: RootState) =>
  state.applianceMajorRepair;

export const selectorApplianceMajorRepair = createSelector(
  selectorApplianceMajorRepairState,
  (applianceMajorRepairState) => applianceMajorRepairState.data
);

export const selectorApplianceMajorRepairsIsLoading = createSelector(
  selectorApplianceMajorRepairState,
  (applianceMajorRepairState) => applianceMajorRepairState.isLoading
);
