import React, { FC } from "react";
import cn from "classnames";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
  DEFAULT_DESCRIPTION_CLASSNAME,
  DEFAULT_TITLE_CLASSNAME,
} from "src/components/SectionCard/constants";
import { ICardProps } from "src/components/SectionCard/types";
import { ICardBlock } from "./types";

interface Props extends ICardProps {
  blocks?: ICardBlock[];
}

export const PointInspectionCard: FC<Props> = ({
  title,
  description,
  blocks,
  className,
  containerClassName,
}) => (
  <div
    className={cn(DEFAULT_CARD_WRAPPER_CLASSNAME, "w-full", containerClassName)}
  >
    <div
      className={cn(DEFAULT_CARD_CLASSNAME, "flex flex-col gap-5", className)}
    >
      <div className="flex flex-col gap-2 ">
        <h4 className={cn("text-yellow-base text-2xl font-bold")}>{title}</h4>

        <p className={cn(DEFAULT_DESCRIPTION_CLASSNAME, "w-full")}>
          {description}
        </p>
      </div>

      <div className="flex flex-col gap-5">
        {blocks.map(({ id, title, description }) => (
          <>
            <div key={id} className="flex flex-col gap-2 ">
              <span className={cn(DEFAULT_TITLE_CLASSNAME, "font-arial")}>
                {title}
              </span>

              <ul
                className="grid grid-cols-1 items-center gap-3 list-disc list-inside text-base font-arial lg:grid-cols-2 lg:gap-2"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>

            <div className="h-px bg-gray-light last:hidden" />
          </>
        ))}
      </div>
    </div>
  </div>
);
