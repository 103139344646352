import { useEffect } from "react";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getNewsTipsAsync } from "src/redux/newsTips/actions";
import {
  selectorNewsTips,
  selectorNewsTipstIsLoading,
} from "src/redux/newsTips/selectors";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { PATHNAMES } from "src/constants/routes";
import { Section } from "src/components/Section";
import { NewsCard } from "src/components/NewsCard";
import { Pagination } from "src/components/Pagination";
import { Loader } from "src/components/Loader";
import { scrollTop } from "src/utils/scrollTop";
import { QUERY_PARAM_KEYS } from "src/constants/queryParams";
import { Sizes } from "src/@types/sizes";

const PAGE_NAME = "News & Tips";
const SECTION_NAME = "News & Tips";
const ITEMS_PER_PAGE = 9;

const NewsTips = () => {
  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { name: PAGE_NAME },
  ];

  const { getQueryParam, setQueryParam } = useQueryParams();
  const currentPage = Number(getQueryParam(QUERY_PARAM_KEYS.PAGE)) || 1;

  const dispatch = useAppDispatch();
  const newsResponse = useAppSelector(selectorNewsTips);
  const isLoading = useAppSelector(selectorNewsTipstIsLoading);

  useEffect(() => {
    dispatch(getNewsTipsAsync(currentPage));
  }, [dispatch, currentPage]);

  const setPage = (page: number) => {
    scrollTop();
    setQueryParam(QUERY_PARAM_KEYS.PAGE, page.toString());
  };
  const pageCount = Math.ceil(newsResponse?.count / ITEMS_PER_PAGE);

  const isPaginated = pageCount > 1;

  return (
    <PageWrapper isShownMaps={false}>
      <div className="container wrapper">
        <BreadCrumbs breadCrumbsItems={breadCrumbsItems} />

        {/* NewsList */}
        <Section className="py-10 mx-auto max-w-267.5" title={SECTION_NAME}>
          {isLoading ? (
            <div className="flex justify-center items-center min-h-96">
              <Loader size={Sizes.L} />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 justify-items-center gap-10 sm:grid-cols-2 lg:grid-cols-3">
                {Boolean(newsResponse?.results?.length) &&
                  newsResponse.results.map((newsItem) => (
                    <NewsCard key={newsItem.id} {...newsItem} />
                  ))}
              </div>

              {isPaginated && (
                <Pagination
                  className="justify-center my-10"
                  page={currentPage}
                  pageCount={pageCount}
                  setPage={setPage}
                />
              )}
            </>
          )}
        </Section>
      </div>
    </PageWrapper>
  );
};

export default NewsTips;
