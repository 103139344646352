import { FC } from "react";
import { Step } from "src/@types/newsTips";
import { Link } from "src/components/Link";
import { useAppSelector } from "src/hooks/redux";
import { selectorDetails } from "src/redux/details/selectors";

interface Props {
  details?: string;
  steps: Step[];
}

export const PostInfo: FC<Props> = ({ steps, details }) => {
  const contactsData = useAppSelector(selectorDetails);

  return (
    <section className="p-5 lg:pl-17.5 md:py-20 w-full">
      <div className="max-w-160">
        {details && (
          <div
            dangerouslySetInnerHTML={{ __html: details }}
            className="mb-5 text-base"
          />
        )}

        <div className="flex flex-col gap-5">
          {Boolean(steps?.length) &&
            steps.map(({ slug, title, description }, index) => (
              <div
                key={slug}
                id={slug}
                className="flex flex-col gap-2 pt-20 -mt-20"
              >
                <h4 className="text-2xl text-yellow-base font-bold">{title}</h4>

                <div
                  className="flex flex-col gap-4 text-base font-arial"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            ))}
          <p className="text-base font-arial lg:text-xl">
            Contact Smartchoice Service at{" "}
            <Link
              className="font-semibold cursor-pointer hover:text-yellow-base"
              href={contactsData?.CONTACTS[2].href}
            >
              {contactsData?.CONTACTS[2].label}
            </Link>{" "}
            today.
          </p>
        </div>
      </div>
    </section>
  );
};
