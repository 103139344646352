import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorReviewsState = (state: RootState) => state.reviews;

export const selectoReviews = createSelector(
  selectorReviewsState,
  (reviewsState) => reviewsState.data
);

export const selectorReviewstIsLoading = createSelector(
  selectorReviewsState,
  (reviewsState) => reviewsState.isLoading
);
