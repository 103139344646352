import { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Link } from "src/components/Link";
import { Section } from "src/components/Section";
import { ISectionWithImage } from "src/@types";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => {
  return (
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper"
        titleClassName="mb-5 text-yellow-base"
        title="Contact Us for Major Appliance Repair Solutions"
      >
        <p className="mb-10  text-xl font-manrope font-medium  md:text-center">
          Experiencing issues with your major appliances? Contact
          <span className="text-yellow-base"> Smartchoice Service</span>
          today.
        </p>

        <p className="mb-10  text-xl font-manrope font-bold  md:text-center">
          Call us at &nbsp;
          <Link href={phone?.href} className="text-yellow-base hover:underline">
            {phone?.label}
          </Link>
          &nbsp; to schedule an appointment with our appliance repair experts.
        </p>

        <p className=" text-xl font-manrope  md:text-center">
          Trust Smartchoice Service to keep your appliances running smoothly
          with our expert repair services.
        </p>
      </Section>
    </ContentOverlay>
  );
};
