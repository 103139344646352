import { Services } from "src/page-components/Home/Services/index";
import { Section } from "src/components/Section";

export const OtherServices = () => (
  <Section
    title="our services"
    titleClassName="lg:!mb-10"
    className="wrapper"
  >
    <Services />
  </Section>
);
