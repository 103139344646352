import { FC, useEffect, useRef } from "react";
import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getBreakerInstallationPageAsync } from "src/redux/services/electrical/breakerInstallation/actions";
import {
  selectorBreakerInstallation,
  selectorBreakerInstallationIsLoading,
} from "src/redux/services/electrical/breakerInstallation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { ContactUs } from "src/page-components/Services/Electrical/BreakerInstallation/ContactUs";
import {
  TYPES_OF_ELECTRICAL_BREAKER_INSTALLATION,
  WHY_CHOOSE_SMARTCHOICE_BREAKER_INSTALLATION,
  WHY_ELECTRICAL_BREAKER_INSTALLATION_IS_IMPORTANT,
} from "src/page-components/Services/Electrical/BreakerInstallation/constants";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { ReviewSection } from "src/components/ReviewSection";
import { OtherServices } from "src/components/OtherServices";
import { BookNowModal } from "src/components/BookNowModal";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";
import { CardType } from "src/@types/card";
import { Sizes } from "src/@types/sizes";
import { Loader } from "src/components/Loader";

const BreakerInstallation: FC = () => {
  const sectionRef = useRef(null);

  const bookNow = useModal();

  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorBreakerInstallation);
  const isLoading = useAppSelector(selectorBreakerInstallationIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getBreakerInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            name="Electrical breaker installation"
            title="Safeguard Your Electrical System with Expert Breaker Installations"
            image={pageData?.banner_background}
            sectionRef={sectionRef}
          >
            Installing electrical breakers is a critical step in safeguarding
            your home or business against electrical overloads and faults. This
            process involves integrating circuit breakers into your electrical
            system to regulate and protect against excessive current. Ensuring
            proper installation is essential for the safety and reliability of
            your electrical setup.
          </Hero>
        )}

        <div className="container">
          <Section
            sectionRef={sectionRef}
            className="wrapper"
            descriptionClassName="max-w-250 mx-auto mb-5 lg:mb-10 lg:text-start"
            title="Types of Electrical Breaker Installation We Offer"
            description="At Smartchoice Service, we specialize in various types of electrical breaker installations tailored to meet your specific needs:"
          >
            <div className="relative flex justify-center gap-10">
              {/* <div
                className={cn(
                  DEFAULT_YELLOW_BLUR,
                  "hidden lg:block top-0 right-0"
                )}
              /> */}

              <div className={DEFAULT_CARD_LIST_CLASSNAME}>
                {TYPES_OF_ELECTRICAL_BREAKER_INSTALLATION.map((card) => (
                  <SectionCard
                    key={card.id}
                    cardType={CardType.SERVICE_CARD}
                    {...card}
                  />
                ))}
              </div>
            </div>
          </Section>

          <Section
            className="wrapper relative"
            descriptionClassName="mb-5 lg:mb-10"
            title="Why Electrical Breaker Installation is Important"
            description="Properly installed electrical breakers provide several crucial benefits:"
          >
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2 translate-y-1/2"
              )}
            /> */}

            <div className={DEFAULT_CARD_LIST_CLASSNAME}>
              {WHY_ELECTRICAL_BREAKER_INSTALLATION_IS_IMPORTANT.map((card) => (
                <SectionCard
                  key={card.id}
                  containerClassName="md:w-76.5"
                  {...card}
                />
              ))}
            </div>
          </Section>

          <WhenToCallUsSection
            imageClassname="max-h-110 object-top"
            title="Why Choose Smartchoice for Your Electrical Breaker Installation Needs?"
            description="When it comes to electrical breaker installation, Smartchoice Service stands out for:"
            items={WHY_CHOOSE_SMARTCHOICE_BREAKER_INSTALLATION}
            image={pageData?.why_choose}
            onButtonClick={bookNow.openModal}
          />

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 left-0 !-translate-x-1/2"
              )}
            /> */}

            <AppointmentBooking
              className="py-20"
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
            />
          </div>
        </div>

        <ContactUs
          image={pageData?.contact_us_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 !right-0"
              )}
            /> */}

            <GetInTouch />
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 left-0 !-translate-x-1/2 translate-y-1/2"
              )}
            /> */}
          </div>

          <OtherServices />

          <NewsTips className="lg:hidden" />

          <div className="relative">
            {/* <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
            <ReviewSection className="hidden lg:flex" />
          </div>
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};
export default BreakerInstallation;
