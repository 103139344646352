import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { ISectionWithImage } from "src/@types";

const TITLE = "Schedule Your Maintenance Appointment Today";

interface Props extends ISectionWithImage {}

export const ReadyToHave: FC<Props> = ({ image, phone }) => (
  <ContentOverlay
    className="flex"
    bgPositionClassName="object-center-30"
    bg={image}
  >
    <Section
      title={TITLE}
      className="wrapper"
      titleClassName="text-yellow-base"
    >
      <div className="md:text-center mx-auto max-w-215">
        <p className="font-bold">
          Don't wait until problems arise to schedule HVAC maintenance. Contact
          Smartchoice Service today to schedule your maintenance appointment and
          ensure that your HVAC system operates efficiently, reliably, and
          safely all year long.
        </p>
        <br />
        <p>
          Our team of skilled technicians will identify and address any
          potential issues, keeping your home or business comfortable and
          worry-free.
        </p>
      </div>
    </Section>
  </ContentOverlay>
);
