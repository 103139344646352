import { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "Our Commitment";

interface Props extends ISectionWithImage {}

export const OurCommitment: FC<Props> = ({ image }) => (
  <div className="relative">
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper py-0"
        title={SECTION_TITLE}
        titleClassName="text-yellow-base text-start md:text-center"
      >
        <div className="max-w-160 mx-auto">
          <p>
            At&nbsp;
            <span className="text-yellow-base font-bold">
              Smartchoice Service
            </span>
            , we are dedicated to excellence in everything we do. We aim to
            exceed your expectations and ensure your complete satisfaction with
            every job. Our commitment to quality and integrity has made us a
            trusted name in the industry.
          </p>

          <br />

          <h4 className="mb-2 text-2xl font-bold">
            Thank you for choosing&nbsp;
            <span className="text-yellow-base">Smartchoice Service</span>.
          </h4>

          <p className="">
            We look forward to serving you and addressing all your appliance,
            HVAC, and electrical needs.
          </p>
        </div>
      </Section>
    </ContentOverlay>
  </div>
);
