import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { WHY_CHOOSE_PROFESSIONAL_ITEMS } from "./constants";

const SECTION_TITLE = "Why Choose Professional Electrical Panel Installation?";
const SECTION_DESC =
  "Choosing professional electrical panel installation offers several advantages:";

export const WhyChooseProfessional = () => (
  <Section
    className="wrapper"
    descriptionClassName="max-w-215 mb-5 lg:mb-10 mx-auto"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {WHY_CHOOSE_PROFESSIONAL_ITEMS.map((item) => (
        <SectionCard key={item.id} {...item} />
      ))}
    </div>
  </Section>
);
