import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { Link } from "src/components/Link";
import { ISectionWithImage } from "src/@types";

const TITLE = "Ready to have your air conditioning working again?";

interface Props extends ISectionWithImage {}

export const ReadyToHave: FC<Props> = ({ image, phone }) => (
  <ContentOverlay
    className="flex relative"
    bg={image}
    bgPositionClassName="object-center-30"
  >
    <Section
      title={TITLE}
      className="wrapper"
      titleClassName="text-yellow-base"
    >
      <div className="lg:text-center mx-auto max-w-160">
        <p className="font-bold">
          You can make your air conditioning service appointment today by giving
          us a call at {""}
          <Link
            className="text-yellow-base font-bold hover:underline transition-all"
            href={phone?.href}
            isExternalLink
          >
            {phone?.label}
          </Link>
        </p>
        <br />
        <p>
          We also provide AC maintenance as well as heating repair services!
        </p>
      </div>
    </Section>
  </ContentOverlay>
);
