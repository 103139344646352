import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorApplianceMaintenanceState = (state: RootState) =>
  state.applianceMaintenance;

export const selectorApplianceMaintenance = createSelector(
  selectorApplianceMaintenanceState,
  (applianceMaintenanceState) => applianceMaintenanceState.data
);

export const selectorApplianceMaintenancesIsLoading = createSelector(
  selectorApplianceMaintenanceState,
  (applianceMaintenanceState) => applianceMaintenanceState.isLoading
);
