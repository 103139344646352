import { FC, forwardRef, Ref } from "react";
import { useController, useFormContext } from "react-hook-form";
import { t } from "i18next";
import cn from "classnames";
import MaskedInput from "react-text-mask";
import { INPUTS_VALIDATION_SCHEMAS } from "src/constants/formValidation";
import { IFormField } from "src/@types/form";
import { FormField } from ".";
import { TEXT_INPUT_STYLE_VARIANTS } from "./constants";
import { FormFieldVariants } from "./types";

interface Props extends IFormField {
  required?: boolean;
  errorMessage?: string;
  isActiveValidation?: boolean;
}

const InputComponent = forwardRef<HTMLInputElement | HTMLTextAreaElement, any>(
  ({ type, ...props }, ref: Ref<HTMLInputElement | HTMLTextAreaElement>) => {
    if (type === "tel") {
      return <MaskedInput {...props} ref={ref as any} autoComplete="off" />;
    }

    if (type === "textarea") {
      return <textarea {...props} ref={ref as any} />;
    }

    return <input {...props} type={type} ref={ref as any} />;
  }
);

export const TextInput: FC<Props> = ({
  fieldId,
  type = "text",
  name,
  required = false,
  variant = FormFieldVariants.PRIMARY,
  placeholder = "",
  className,
  fieldClassName,
  isActiveValidation = true,
}) => {
  const { control } = useFormContext();

  const validation = isActiveValidation ? INPUTS_VALIDATION_SCHEMAS[type] : {};

  const { field, fieldState } = useController({
    name,
    control,
    rules: { ...validation, required: required && "required" },
  });

  const { error } = fieldState;

  const placeholderText =
    typeof placeholder === "string" ? placeholder : t(placeholder.i18nKey);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value;

    if (type === "tel" && !field.value.startsWith("+1")) {
      field.onChange(`+1${inputValue}`);
    } else {
      field.onChange(inputValue);
    }
  };

  return (
    <FormField
      error={error?.message}
      isShownError={Boolean(error?.message)}
      labelFor={fieldId}
      className={className}
    >
      <InputComponent
        id={fieldId}
        type={type}
        mask={
          type === "tel"
            ? [
                "+",
                /\d/,
                " ",
                "(",
                /\d/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]
            : ""
        }
        className={cn(TEXT_INPUT_STYLE_VARIANTS[variant], fieldClassName)}
        placeholder={placeholderText}
        {...field}
        onChange={handleInputChange}
      />
    </FormField>
  );
};
