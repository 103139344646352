export const TYPES_OF_ELECTRICAL_REPAIRS = [
  {
    id: crypto.randomUUID(),
    title: "Wiring Repairs and Upgrades",
    description:
      "Fixing faulty wiring, upgrading old wiring to meet current standards, and ensuring your home or business is safely powered.",
  },
  {
    id: crypto.randomUUID(),
    title: "Circuit Breaker Repairs",
    description:
      "Repairing or replacing malfunctioning circuit breakers that may cause power outages or pose safety hazards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Outlet and Switch Repairs",
    description:
      "Fixing or replacing damaged outlets and switches, including GFCI outlets in bathrooms and kitchens.",
  },
  {
    id: crypto.randomUUID(),
    title: "Lighting Repairs",
    description:
      "Addressing issues with indoor and outdoor lighting, including fixtures, dimmer switches, and smart lighting systems.",
  },
  {
    id: crypto.randomUUID(),
    title: "Electrical Panel Upgrades",
    description:
      "Upgrading outdated electrical panels to handle increased power demands safely and efficiently.",
  },
  {
    id: crypto.randomUUID(),
    title: "Ceiling Fan Repairs",
    description:
      "Repairing or installing ceiling fans to improve air circulation and comfort in your home or office.",
  },
  {
    id: crypto.randomUUID(),
    title: "Electrical Safety Inspections",
    description:
      "Conducting thorough inspections to identify and rectify potential electrical hazards before they cause serious problems.",
  },
];

export const COMMON_REASONS_FOR_ELECTRICAL_FAILURES = [
  {
    id: crypto.randomUUID(),
    title: "Aging Wiring",
    cardNumber: "01",
    description:
      "Older wiring can degrade over time, leading to shorts, arcing, and potential fire hazards.",
  },
  {
    id: crypto.randomUUID(),
    title: "Overloaded Circuits",
    cardNumber: "02",
    description:
      "Excessive power draw on a single circuit can cause overheating, tripping breakers, and even fires.",
  },
  {
    id: crypto.randomUUID(),
    title: "Poor Installation",
    cardNumber: "03",
    description:
      "Substandard or DIY electrical work can result in numerous issues, from minor inconveniences to major safety risks.",
  },
  {
    id: crypto.randomUUID(),
    title: "Rodent Damage",
    cardNumber: "04",
    description:
      "Rodents chewing on wires can cause shorts and electrical failures.",
  },
  {
    id: crypto.randomUUID(),
    title: "Environmental Factors",
    cardNumber: "05",
    description:
      "Moisture, heat, and physical damage can all compromise electrical components.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_ITEMS = [
  {
    id: crypto.randomUUID(),
    title: "Certified Technicians",
    description:
      "Our team of licensed and experienced electricians is trained to handle all types of electrical repairs with precision and care.",
  },
  {
    id: crypto.randomUUID(),
    title: "Quality Workmanship",
    description:
      "We adhere to the highest standards of quality and safety, ensuring every job is done right the first time.",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction, offering clear communication, timely service, and respectful treatment of your property.",
  },
  {
    id: crypto.randomUUID(),
    title: "Transparent Pricing",
    description:
      "With upfront pricing and no hidden fees, you can trust that you're getting fair and honest service.",
  },
  {
    id: crypto.randomUUID(),
    title: "Emergency Services",
    description:
      "Electrical problems can arise at any time. That's why we offer emergency repair services to address urgent issues promptly.",
  },
  {
    id: crypto.randomUUID(),
    title: "Comprehensive Solutions",
    description:
      "From minor repairs to major upgrades, we provide comprehensive electrical solutions tailored to your specific needs.",
  },
];
