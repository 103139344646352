import React from "react";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Section } from "src/components/Section";
import { BreadCrumbs } from "src/components/BreadCrumbs";
import { IBreadCrumbsItem } from "src/components/BreadCrumbs/types";
import { PATHNAMES } from "src/constants/routes";
import { selectorDetails } from "src/redux/details/selectors";
import { useAppSelector } from "src/hooks/redux";
import { Link } from "src/components/Link";

const PAGE_NAME = "Terms of Use";
const SECTION_TITLE = "Terms of Use";

const TermsOfUse = () => {
  const breadCrumbsItems: IBreadCrumbsItem[] = [
    {
      path: PATHNAMES.HOME,
      name: "Home",
    },
    { name: PAGE_NAME },
  ];

  const contactData = useAppSelector(selectorDetails);

  return (
    <PageWrapper isShownMaps={false}>
      <div className="container wrapper">
        <BreadCrumbs breadCrumbsItems={breadCrumbsItems} className="mb-6" />
        <Section className="max-w-160 mx-auto" title={SECTION_TITLE}>
              <p className="mb-7 text-base">
                <span className="text-yellow-base font-semibold">
                  Effective Date:{" "}
                </span>
                08/28/2024
              </p>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                1. Introduction
              </h4>
              <p className="text-base">
                Welcome to the Smartchoice Service website. These Terms of Use
                <span className="text-yellow-base"> Terms</span> govern your
                access to and use of our website and services. By accessing or
                using our website, you agree to be bound by these Terms. If you
                do not agree with any part of these Terms, please do not use our
                website.
              </p>
            </div>

            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                2. Services Provided
              </h4>
              <p className="text-base">
                Smartchoice Service provides HVAC, Electrical, and Appliance
                repair services. The information provided on our website is for
                general informational purposes only and does not constitute
                professional advice. We reserve the right to modify, suspend, or
                discontinue any aspect of our services at any time without
                notice.
              </p>
            </div>

            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                3. User Responsibilities
              </h4>
              <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                <li>
                  <span className="font-bold">
                    Accurate Information: &nbsp;
                  </span>
                  You agree to provide accurate, current, and complete
                  information when using our website or services. You are
                  responsible for maintaining the confidentiality of any account
                  information and for all activities that occur under your
                  account.
                </li>
                <li>
                  <span className="font-bold">
                    Prohibited Activities: &nbsp;
                  </span>
                  You agree not to use our website for any unlawful purpose or
                  in any way that could damage, disable, or impair our website.
                  Prohibited activities include, but are not limited to,
                  transmitting harmful code, interfering with the operation of
                  our website, or accessing data not intended for you.
                </li>
                <li>
                  <span className="font-bold">
                    Compliance with Laws: &nbsp;
                  </span>
                  You agree to comply with all applicable laws and regulations
                  when using our website and services.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                4. Intellectual Property
              </h4>
              <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                <li>
                  <span className="font-bold">Ownership: &nbsp;</span>
                  All content, including text, images, graphics, logos, and
                  software, on our website is the property of Smartchoice
                  Service or its content suppliers and is protected by
                  copyright, trademark, and other intellectual property laws.
                  account.
                </li>
                <li>
                  <span className="font-bold">Limited License: &nbsp;</span>
                  We grant you a limited, non-exclusive, non-transferable, and
                  revocable license to access and use our website for personal,
                  non-commercial purposes. You may not reproduce, distribute,
                  modify, or create derivative works from any content on our
                  website without our prior written consent.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                5. Disclaimers
              </h4>
              <ul className="flex flex-col gap-y-2 text-base list-disc list-inside">
                <li>
                  <span className="font-bold">No Warranty: &nbsp;</span>
                  Our website and services are provided on an "as-is" and
                  "as-available" basis. We make no warranties, express or
                  implied, regarding the accuracy, reliability, or availability
                  of our website or services.
                </li>
                <li>
                  <span className="font-bold">
                    Limitation of Liability: &nbsp;
                  </span>
                  To the fullest extent permitted by law, Smartchoice Service
                  and its affiliates, officers, directors, employees, and agents
                  will not be liable for any indirect, incidental, special,
                  consequential, or punitive damages arising out of your use of
                  our website or services.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                6. Indemnification
              </h4>
              <p className="text-base">
                You agree to indemnify, defend, and hold harmless Smartchoice
                Service and its affiliates, officers, directors, employees, and
                agents from and against any claims, liabilities, damages,
                losses, and expenses, including legal fees, arising out of or in
                any way connected with your use of our website or services, your
                violation of these Terms, or your violation of any rights of
                another person or entity.
              </p>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                7. Third-Party Links
              </h4>
              <p className="text-base">
                Our website may contain links to third-party websites that are
                not operated or controlled by Smartchoice Service. We are not
                responsible for the content or practices of these third-party
                websites. Your use of third-party websites is at your own risk,
                and you should review the terms and privacy policies of any
                third-party websites you visit.
              </p>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                8. Modifications to Terms
              </h4>
              <p className="text-base">
                We reserve the right to modify these Terms at any time. Any
                changes will be effective immediately upon posting the revised
                Terms on our website. Your continued use of our website
                following the posting of changes constitutes your acceptance of
                those changes.
              </p>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                9. Governing Law
              </h4>
              <p className="text-base">
                These Terms are governed by and construed in accordance with the
                laws of the State of California, without regard to its conflict
                of law principles. Any legal action or proceeding arising out of
                or related to these Terms or your use of our website shall be
                brought exclusively in the state or federal courts located in
                USA, California.
              </p>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <h4 className="text-yellow-base text-2xl font-manrope font-bold">
                10. Contact Us
              </h4>
              <p className="text-base">
                If you have any questions about these Terms, please contact us
                at:
              </p>
              <ul className="flex flex-col gap-y-2">
                <li className="text-yellow-base">Smartchoice Service</li>
                <li>
                  Phone:&nbsp;
                  <Link
                    className="cursor-pointer hover:text-yellow-base"
                    href={contactData?.CONTACTS[1].href}
                  >
                    {contactData?.CONTACTS[1].label}
                  </Link>
                </li>
                <li>
                  Email:&nbsp;
                  <Link
                    className="cursor-pointer hover:text-yellow-base"
                    href={contactData?.CONTACTS[0].href}
                  >
                    {contactData?.CONTACTS[0].label}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Section>
      </div>
    </PageWrapper>
  );
};

export default TermsOfUse;
