export const ADVANTAGES_OF_PROFESSIONAL_INSTALLATION = [
  {
    id: crypto.randomUUID(),
    title: "Correct Setup",
    description:
      "Ensures appliances are installed according to manufacturer specifications for optimal performance.",
    cardNumber: "01",
  },
  {
    id: crypto.randomUUID(),
    title: "Safety Assurance",
    description:
      "Reduces the risk of installation-related accidents or hazards.",
    cardNumber: "02",
  },
  {
    id: crypto.randomUUID(),
    title: "Warranty Compliance",
    description:
      "Professional installation may be required to maintain manufacturer warranties.",
    cardNumber: "03",
  },
  {
    id: crypto.randomUUID(),
    title: "Peace of Mind",
    description:
      "Knowing your appliances are installed correctly provides confidence in their reliability.",
    cardNumber: "04",
  },
];

export const OUR_APPLIANCE_INSTALLATION_SERVICES = [
  {
    id: crypto.randomUUID(),
    title: "Refrigerators",
    description:
      "Ensuring proper leveling, connection to water lines (if applicable), and checking for leaks.",
  },
  {
    id: crypto.randomUUID(),
    title: "Washers and Dryers",
    description:
      "Correct placement, leveling, and connection to water and electrical outlets.",
  },
  {
    id: crypto.randomUUID(),
    title: "Dishwashers",
    description:
      "Installation including proper alignment, securing to cabinetry, and connection to water and drainage systems.",
  },
  {
    id: crypto.randomUUID(),
    title: "Ovens and Stoves",
    description:
      "Precise installation, ensuring electrical or gas connections are safely secured.",
  },
  {
    id: crypto.randomUUID(),
    title: "Microwaves",
    description:
      "Mounting above stoves or on walls, ensuring secure placement and proper venting.",
  },
  {
    id: crypto.randomUUID(),
    title: "Icemakers",
    description:
      "Installing in refrigerators or standalone units, connecting to water lines and ensuring proper operation.",
  },
  {
    id: crypto.randomUUID(),
    title: "Wine Coolers",
    description:
      "Installation including placement, leveling, and ensuring adequate ventilation.",
  },
];

export const WHY_CHOOSE_SMARTCHOICE_SERVICE_INSTALATION = [
  {
    id: crypto.randomUUID(),
    title: "Expertise",
    description:
      "Our technicians are trained in the installation of a wide range of appliances.",
    cardNumber: "01",
  },
  {
    id: crypto.randomUUID(),
    title: "Precision",
    description:
      "We ensure appliances are installed correctly the first time, minimizing the risk of issues.",
    cardNumber: "02",
  },
  {
    id: crypto.randomUUID(),
    title: "Compliance",
    description:
      "We adhere to manufacturer guidelines and local codes for safe and reliable installations.",
    cardNumber: "03",
  },
  {
    id: crypto.randomUUID(),
    title: "Customer Satisfaction",
    description:
      "We prioritize your satisfaction with professional service and attention to detail.",
    cardNumber: "04",
  },
];
