import React, { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getHeatPumpInstallationPageAsync } from "src/redux/services/hvac/heatPumpInstallation/actions";
import {
  selectorHeatPumpInstallation,
  selectorHeatPumpInstallationIsLoading,
} from "src/redux/services/hvac/heatPumpInstallation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { WhyChoose } from "src/page-components/Services/HVAC/HeatPumpInstallation/WhyChoose";
import { WhyHeatPumpsAreBetter } from "src/page-components/Services/HVAC/HeatPumpInstallation/WhyHeatPumpsAreBetter";
import { ReadyUpgrade } from "src/page-components/Services/HVAC/HeatPumpInstallation/ReadyUpgrade";
import { OUR_GUARANTEES_ITEMS } from "src/page-components/Services/HVAC/HeatPumpInstallation/constants";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Hero } from "src/components/Hero";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const OUR_GUARANTEES_TITLE =
  "Our Guarantees for Heat Pump Installation and Repairs";
const OUR_GUARANTEES_DESC =
  "At Smartchoice Service, we stand behind our work and prioritize customer satisfaction above all else. When you choose us for your heat pump installation and repairs, you can expect:";

const HeatPumpInstallation = () => {
  const sectionRef = useRef(null);
  const bookNow = useModal();

  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorHeatPumpInstallation);
  const isLoading = useAppSelector(selectorHeatPumpInstallationIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getHeatPumpInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Heat Pump Installation"
            image={pageData?.banner_background}
            name="Heat Pump Installation"
            sectionRef={sectionRef}
          >
            For reliable and professional heat pump installation,{" "}
            <span className="text-yellow-base font-bold">
              Smartchoice Service
            </span>{" "}
            is the name you can trust. We proudly serve various regions in
            Southern California, including Orange and Los Angeles counties, as
            well as key areas in Washington State, such as Pierce and King
            counties. Our team ensures your new furnace is installed with
            precision and care, providing optimal heating comfort and
            efficiency.
          </Hero>
        )}

        <div className="container">
          {/* <div className="relative" >
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}
          <WhyChoose sectionRef={sectionRef} />
          {/* </div> */}

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0"
              )}
            /> */}
          <WhyHeatPumpsAreBetter />
          {/* </div> */}

          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-40 left-0 !-translate-x-1/2"
              )}
            /> */}
          <WhenToCallUsSection
            imageClassname="max-h-155 sm:object-contain gl:object-cover"
            title={OUR_GUARANTEES_TITLE}
            description={OUR_GUARANTEES_DESC}
            items={OUR_GUARANTEES_ITEMS}
            image={pageData?.our_guarantees}
            onButtonClick={bookNow.openModal}
          />
          {/* </div> */}
        </div>

        <ReadyUpgrade
          image={pageData?.ready_to_upgrade_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}

          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default HeatPumpInstallation;
