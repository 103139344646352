import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Coupon } from "./Coupon";

interface CouponProps {
  id: string | number;
  label: string;
  discount?: string;
}

interface Props {
  coupons: CouponProps[];
  onButtonClick?: () => void;
}

export const CouponSwiper: FC<Props> = ({ coupons, onButtonClick }) => {
  if (!coupons?.length) return null;

  return (
    <div className="relative max-w-82.5 mx-auto xs:max-w-110 sm:max-w-215 gl:max-w-full">
      <Swiper
        className="max-h-120"
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView="auto"
        // loop={true}
        grabCursor
        breakpoints={{
          1280: {
            direction: "vertical",
          },
        }}
      >
        {coupons.map((item) => (
          <SwiperSlide
            className="max-w-45 gl:h-auto gl:max-w-160"
            key={item.id}
          >
            <Coupon {...item} onButtonClick={onButtonClick} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
