import { FC, useRef } from "react";
import { NotificationService } from "src/helpers/notifications";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { postBookNowCoupon } from "src/redux/bookNow/actions";
import { postBookNow } from "src/redux/bookNow/actions";
import { IBookNowData } from "src/@types/postFormsData";
import { ReactComponent as EmergencyIcon } from "src/assets/icons/emergency.svg";
import { CustomFileUpload } from "./CustomFileUpload";
import { BOOK_NOW_DROP_DOWN_LIST, BOOK_NOW_FORM_FIELDS } from "./constants";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { RenderFields } from "../RenderFields";
import { TextInput } from "../FormField/TextInput";
import CustomSelect from "../CustomSelect";
import {
  selectorBookNowCouponIsLoading,
  selectorBookNowIsLoading,
} from "src/redux/bookNow/selectors";
import { Loader } from "../Loader";
import { Sizes } from "src/@types/sizes";
import cn from "classnames";

interface Props {
  openWithCoupon?: boolean;
  discounts?: { label: string }[];
  onClose: VoidFunction;
}

export const BookNowForm: FC<Props> = ({
  openWithCoupon,
  discounts,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<IBookNowData>({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      special_requests: "",
    },
  });
  const fileInputRef = useRef<{ clearFile: () => void } | null>(null);

  const isLoading = useAppSelector(selectorBookNowIsLoading);
  const isLoadingCoupon = useAppSelector(selectorBookNowCouponIsLoading);

  const onSubmit: SubmitHandler<IBookNowData> = async (data) => {
    const formData = { ...data, img: data.img || null };

    try {
      if (openWithCoupon) {
        await dispatch(postBookNowCoupon(formData)).unwrap();
      }
      await dispatch(postBookNow(formData)).unwrap();
      closeWhenSubmit();
    } catch (error) {
      console.error("Failed to submit form:", error);
    }
  };

  const closeWhenSubmit = () => {
    NotificationService.success("success request");
    methods.reset();
    fileInputRef.current?.clearFile();
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <div className="flex flex-col gap-y-8 mb-7">
          <RenderFields
            fieldClassName="!px-0 !w-full"
            fields={BOOK_NOW_FORM_FIELDS}
          />

          <CustomSelect
            name="service"
            control={methods.control}
            placeholder={"Choose Service\u002A"}
            options={BOOK_NOW_DROP_DOWN_LIST}
          />

          {openWithCoupon && (
            <div>
              <div className="flex justify-center items-center gap-x-2 mb-3">
                <EmergencyIcon />

                <p className="text-xl font-manrope text-gray-light">
                  For new customers only
                </p>
              </div>

              <CustomSelect
                name="coupon_slug"
                control={methods.control}
                placeholder={"Choose Coupon\u002A"}
                options={discounts}
                openWithCoupon={openWithCoupon}
              />
            </div>
          )}

          <CustomFileUpload
            ref={fileInputRef}
            acceptTypes=".png, .jpg, .jpeg, .tiff, .bmp, .webp"
            name="img"
            control={methods.control}
            defaultLabel="Choose Image"
            changeLabel="Change Image"
            maxSize={10 * 1024 * 1024} // 10 MB у байтах
            isValidate={false}
          />

          <TextInput
            fieldClassName="!pb-8 !px-0 !w-90"
            name="special_requests"
            placeholder="Special requests"
            type="textarea"
          />
        </div>

        <Button
          className={cn("mx-auto max-w-25 w-full", {
            "opacity-55": isLoading || isLoadingCoupon,
          })}
          variant={ButtonVariants.PRIMARY}
          isDisabled={isLoading || isLoadingCoupon}
        >
          {isLoading || isLoadingCoupon ? <Loader size={Sizes.S} /> : "Send"}
        </Button>
      </form>
    </FormProvider>
  );
};
