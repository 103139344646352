import React, { FC } from "react";
import cn from "classnames";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { ReactComponent as PrevIcon } from "src/assets/icons/arrow-left-yellow-base.svg";
import { ReactComponent as NextIcon } from "src/assets/icons/arrow-right-yellow-base.svg";

const ICON_SIZE = 20;

const DEFAULT_PAGE_CLASSNAME = "min-w-5 text-2xl text-center font-bold";
const DEFAULT_PAGE_LINK_CLASSNAME =
  "block w-full hover:text-yellow-base hover:scale-125";
const DEFAULT_DISABLED_NAV_LINK_CLASSNAME = "!opacity-50 pointer-events-none";

interface Props {
  className?: string;
  page: number;
  pageCount: number;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  setPage: (value: number) => void;
}

export const Pagination: FC<Props> = ({
  className,
  page,
  pageCount,
  pageRangeDisplayed = 5,
  marginPagesDisplayed = 3,
  setPage,
}) => {
  const currentPage = page - 1;

  const onPageChange: ReactPaginateProps["onPageChange"] = ({ selected }) =>
    setPage(selected + 1);

  return (
    <ReactPaginate
      containerClassName={cn("flex items-center gap-3", className)}
      pageClassName={DEFAULT_PAGE_CLASSNAME}
      pageLinkClassName={DEFAULT_PAGE_LINK_CLASSNAME}
      breakClassName={DEFAULT_PAGE_CLASSNAME}
      breakLinkClassName={DEFAULT_PAGE_LINK_CLASSNAME}
      activeClassName="text-yellow-base default:opacity-100"
      activeLinkClassName="!hover:scale-100"
      disabledClassName={DEFAULT_DISABLED_NAV_LINK_CLASSNAME}
      disabledLinkClassName="cursor-default"
      initialPage={currentPage}
      pageCount={pageCount}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginPagesDisplayed}
      onPageChange={onPageChange}
      previousLabel={<PrevIcon width={ICON_SIZE} height={ICON_SIZE} />}
      nextLabel={<NextIcon width={ICON_SIZE} height={ICON_SIZE} />}
    />
  );
};
