import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { INewsTipsBookNow } from "src/@types/postFormsData";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { RenderFields } from "src/components/RenderFields";
import { NEWS_TIPS_FORM_FIELDS } from "./constants";
import { useAppDispatch } from "src/hooks/redux";
import { postNewsTipsBookNow } from "src/redux/newsTipsBookNow/actions";
import { NotificationService } from "src/helpers/notifications";

export const NewsTipsForm = () => {
  const methods = useForm<INewsTipsBookNow>({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<INewsTipsBookNow> = (data) => {
    dispatch(postNewsTipsBookNow(data));
    NotificationService.success("success request");
    methods.reset();
  };

  return (
    <div className="lg:max-w-1/2 mx-auto p-10 mb-7 rounded-10">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <p className="mb-10 text-center capitalize text-5xl  font-manrope font-bold sm:text-6xl">
            Book now
          </p>
          <div className="flex flex-col gap-y-8 ">
            <RenderFields
              fieldClassName="!px-0 !w-full"
              fields={NEWS_TIPS_FORM_FIELDS}
            />
          </div>

          <Button
            className="w-full py-3 mt-7 mx-auto sm:max-w-77.5 gl:max-w-55 gl:mx-0 gl:ml-auto "
            variant={ButtonVariants.PRIMARY}
          >
            Send
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
