import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorFurnaceRepairState = (state: RootState) =>
  state.furnaceRepair;

export const selectorFurnaceRepair = createSelector(
  selectorFurnaceRepairState,
  (furnaceRepairState) => furnaceRepairState.data
);

export const selectorFurnaceRepairsIsLoading = createSelector(
  selectorFurnaceRepairState,
  (furnaceRepairState) => furnaceRepairState.isLoading
);
