import React from "react";
import { Section } from "src/components/Section";
import { SectionCard } from "src/components/SectionCard";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { IMPORTANCE_ITEMS } from "./constants";

const SECTION_TITLE = "Importance of Residential Electrical Services";
const SECTION_DESC =
  "Having reliable residential electrical services is crucial for:";

export const Importance = () => (
  <Section
    className="wrapper"
    descriptionClassName="mx-auto mb-5 lg:mb-10"
    title={SECTION_TITLE}
    description={SECTION_DESC}
  >
    <div className={DEFAULT_CARD_LIST_CLASSNAME}>
      {IMPORTANCE_ITEMS.map((item) => (
        <SectionCard key={item.id} containerClassName="md:!w-76.5" {...item} />
      ))}
    </div>
  </Section>
);
