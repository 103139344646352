import React, { FC } from "react";
import cn from "classnames";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ReactComponent as Icon } from "src/assets/icons/membership/membership-how-it-works-icon.svg";
import { MembershipData } from "src/@types/membership";

const ICON_SIZE = 100;
const CURRENCY = "$";

interface Props {
  className?: string;
  onButtonClick?: VoidFunction;
  membershipData: MembershipData[];
}

export const SignUpMembershipCard: FC<Props> = ({
  className,
  onButtonClick,
  membershipData,
}) => (
  <div
    className={cn(
      "border border-yellow-base rounded-10 w-full p-10",
      className
    )}
  >
    <div className="flex flex-col justify-center items-center gap-5 lg:flex-row lg:justify-between lg:gap-11">
      <Icon
        width={ICON_SIZE}
        height={ICON_SIZE}
        className="min-w-25 min-h-25"
      />

      {Boolean(membershipData?.length) && (
        <div className="w-full">
          <div className="flex flex-col items-center justify-between gap-5 lg:gap-11 lg:flex-row mb-5 text-2xl font-bold sm:text-start">
            <span>{membershipData[0]?.title}</span>
            <span className="text-6xl text-yellow-base font-bebas whitespace-nowrap break-all">
              {CURRENCY} {membershipData[0]?.price}
            </span>
          </div>

          {membershipData[1] && (
            <div className="flex flex-col items-center justify-between gap-5 lg:gap-11 lg:flex-row text-2xl font-bold sm:text-start">
              <span>{membershipData[1]?.title}</span>
              <span className="text-6xl text-yellow-base font-bebas whitespace-nowrap">
                {CURRENCY} {membershipData[1]?.price}
              </span>
            </div>
          )}
        </div>
      )}
    </div>

    <Button
      className="w-full mt-5 uppercase"
      variant={ButtonVariants.SECONDARY}
      onClick={onButtonClick}
    >
      sign up for membership
    </Button>
  </div>
);
