import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import { getImageUrl } from "src/utils/getImageUrl";
import { IImage } from "src/@types";

interface Props {
  brands: IImage[];
  classname?: string;
}

export const BrandListSwiper: FC<Props> = ({ brands }) => (
  <Swiper
    modules={[Grid, Autoplay]}
    spaceBetween={20}
    slidesPerView="auto"
    grid={{
      rows: 2,
      fill: "row",
    }}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    grabCursor
  >
    {Boolean(brands?.length) &&
      brands.map(({ image, alt, title }) => (
        <SwiperSlide
          key={alt}
          className="w-37.5 !h-33.75 gl:w-70 gl:!h-33.75 px-6 py-5 border border-gray-base rounded-18"
        >
          <img
            className="w-full h-full object-contain"
            src={getImageUrl(image)}
            alt={alt}
            title={title}
          />
        </SwiperSlide>
      ))}
  </Swiper>
);
