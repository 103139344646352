import { FC, useEffect } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { getHomePageAsync } from "src/redux/home/actions";
import { selectorHome, selectorHomesIsLoading } from "src/redux/home/selectors";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { AboutUs } from "src/page-components/Home/AboutUs";
import { OurStory } from "src/page-components/Home/OurStory";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { HeroSwiper } from "src/page-components/Home/HeroSwiper";
import { ServiceInNumber } from "src/page-components/Home/ServiceInNumber";
import { BrandsWeService } from "src/page-components/Home/BrandsWeService";
import { OurServices } from "src/page-components/Home/OurServices";
import { WhySmartchoice } from "src/page-components/Home/WhySmartchoice";
import { SpecialOffers } from "src/page-components/Home/SpecialOffers";
import { OurCommitment } from "src/page-components/Home/OurCommitment";
import { ContactUs } from "src/page-components/Home/ContactUs";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
import { OtherServices } from "src/components/OtherServices";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const Home: FC = () => {
  const bookNow = useModal();

  const dispatch = useAppDispatch();
  const pageData = useAppSelector(selectorHome);
  const isLoading = useAppSelector(selectorHomesIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getHomePageAsync());
    }
  }, []);


  
  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <HeroSwiper />
        )}

        <div className="container">
          {/* <div className={cn(DEFAULT_YELLOW_BLUR, "!right-0")} /> */}
        <OtherServices/>

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 !left-0 -translate-x-1/3 sm:!-translate-x-3/4"
              )}
            /> */}
            <AppointmentBooking
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
            />
          </div>

          <AboutUs image={pageData?.about_us} />
        </div>

        <OurStory image={pageData?.our_story} />

        <div className="container">
          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-1/4 !left-0 -translate-x-1/3"
              )}
            /> */}
            <OurServices />
          </div>

          <WhySmartchoice />
        </div>

        <OurCommitment image={pageData?.our_commitment} />

        <div className="container">
          <SpecialOffers />
          <GetInTouch />

          <div className="hidden lg:block">
            <ContactUs />
          </div>

          <ReviewSection />
          <BrandsWeService />
          <NewsTips />
          <ServiceInNumber />

          <BookNowModal
            isOpen={bookNow.isOpenModal}
            onClose={bookNow.closeModal}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default Home;
