import { FC } from "react";
import { getImageUrl } from "src/utils/getImageUrl";
import { IWorkingHours } from "src/@types/details";
import { ILink } from "src/@types";
import {
  DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME,
  DEFAULT_FOOTER_TITLE_CLASSNAME,
} from "./constants";
import { Link } from "../Link";

interface Props {
  workingHours: IWorkingHours[];
  socialLinks: ILink[];
}

export const SocialLinks: FC<Props> = ({ workingHours, socialLinks }) => (
  <div>
    <h4 className={DEFAULT_FOOTER_TITLE_CLASSNAME}>business hours</h4>

    <div className={DEFAULT_FOOTER_DIVIDE_LINE_CLASSNAME} />

    <div className="flex flex-col gap-y-3 mb-6 text-xl capitalize">
      {Boolean(workingHours?.length) &&
        workingHours.map(({ id, day, hours }) => (
          <span key={id}>{`${day}: ${hours}`}</span>
        ))}
    </div>

    <div className="flex items-center gap-10">
      {Boolean(socialLinks?.length) &&
        socialLinks.map(({ id, icon, href }) => (
          <Link
            key={id}
            href={href}
            className="hover:scale-110"
            isExternalLink
            isOpenNewTab
          >
            {typeof icon === "string" && (
              <img className="w-10" src={getImageUrl(icon)} alt="icon" />
            )}
          </Link>
        ))}
    </div>
  </div>
);
