import { FC, ReactNode } from "react";
import cn from "classnames";
import { getImageUrl } from "src/utils/getImageUrl";
import { IImage } from "src/@types";

interface ContentOverlayProps {
  bg: IImage;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  bgPosition?: string;
  bgPositionClassName?: string;
}

export const ContentOverlay: FC<ContentOverlayProps> = ({
  bg,
  children,
  className,
  contentClassName,
  bgPositionClassName = "object-center",
  bgPosition = "center",
}) => (
  <div
    className={cn(
      "flex justify-center items-center lg:py-20 relative",
      className
    )}
  >
    <div className="absolute z-10 w-full h-full bg-custom-dark-gradient" />

    <img
      className={cn(
        "absolute w-full h-full py-px object-cover",
        bgPositionClassName
      )}
      src={getImageUrl(bg?.image)}
      alt={bg?.alt}
    />

    <div className={cn("z-20", contentClassName)}>{children}</div>
  </div>
);
