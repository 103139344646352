import { useEffect, useRef } from "react";
import { SuperSEO } from "react-super-seo";
import { getEvChargerInstallationPageAsync } from "src/redux/services/electrical/evChargerInstallation/actions";
import {
  selectorEvChargerInstallation,
  selectorEvChargerInstallationIsLoading,
} from "src/redux/services/electrical/evChargerInstallation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { EvChargerInstallationServices } from "src/page-components/Services/Electrical/EVChargerInstallation/EvChargerInstallationServices";
import { Importance } from "src/page-components/Services/Electrical/EVChargerInstallation/Importance";
import { ContactUs } from "src/page-components/Services/Electrical/EVChargerInstallation/ContactUs";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { WHY_CHOOSE_SMARTCHOICE_ITEMS } from "src/page-components/Services/Electrical/EVChargerInstallation/constants";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";


// types
import { Sizes } from "src/@types/sizes";

const WHY_CHOOSE_SMARTCHOICE_TITLE = "Why Choose Smartchoice Service?";
const WHY_CHOOSE_SMARTCHOICE_DESC =
  "Choose Smartchoice Service for your EV charger installation because:";

const EVChargerInstallation = () => {
  const sectionRef = useRef(null);

  const bookNow = useModal();
  const dispatch = useAppDispatch();

  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorEvChargerInstallation);
  const isLoading = useAppSelector(selectorEvChargerInstallationIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getEvChargerInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Powering Your Future with Efficient EV Charging Solutions"
            name="EV Charger installation"
            image={pageData?.banner_background}
            sectionRef={sectionRef}
          >
            EV chargers are essential for modern homes and businesses looking to
            embrace sustainable transportation. At Smartchoice Service, we
            specialize in providing reliable EV charger installation services
            tailored to meet the needs of EV owners. Whether you're installing a
            new charger or upgrading existing infrastructure, our team is
            committed to delivering top-quality service and ensuring your EV
            charging setup operates efficiently and safely.
          </Hero>
        )}

        <div className="container">
          <div className="relative pt-17.5 -mt-17.5" ref={sectionRef}>
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0"
              )}
            /> */}
            <EvChargerInstallationServices />
          </div>

          <div className="relative">
            <Importance />
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}
          </div>

          <WhenToCallUsSection
            imageClassname="max-h-110 object-top"
            title={WHY_CHOOSE_SMARTCHOICE_TITLE}
            description={WHY_CHOOSE_SMARTCHOICE_DESC}
            items={WHY_CHOOSE_SMARTCHOICE_ITEMS}
            image={pageData?.why_choose}
            onButtonClick={bookNow.openModal}
          />

          <div className="relative">
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-40 left-0 !-translate-x-1/2"
              )}
            /> */}
            <AppointmentBooking
              title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
              image={pageData?.appointment_booking}
              onButtonClick={bookNow.openModal}
            />
          </div>
        </div>

        <ContactUs
          image={pageData?.contact_us_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          <div className="relative">
            <GetInTouch />

            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
            {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            /> */}
          </div>

          <OtherServices />

          <NewsTips className="lg:hidden" />

          <div className="relative">
            {/* <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
            <ReviewSection className="hidden lg:flex" />
          </div>
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default EVChargerInstallation;
