import NewsImg from "src/assets/images/home/news-tips.jpg";

export const SWIPER_ARROW_CLASSNAME =
  "w-10 h-10 fill-yellow-base cursor-pointer hover:scale-110 focus:scale-110";

export const NEWS_LIST = [
  {
    id: 13252356246,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If the door latch is working properly, or if replacing it hasn’t fixed your washing machine, the next component to check is the drive belt. The drive belt helps your washing machine to rotate during the washing cycle. If it becomes misaligned your washing machine will not be able to spin correctly.To check the drive belt, you need to remove the top and side panels of your appliance so you can locate the belt. Once you locate the belt, you need to inspect it to make sure it isn’t misaligned or damaged. If damaged or misaligned, it will need to be repaired and if that isn’t possible, replaced.",
    img: NewsImg,
  },
  {
    id: 112312312,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If the lid switch is working correctly, or if replacing it hasn’t fixed your washing machine, the next component to check is the door latch. Some washing machines have electronic door latches and others have manual ones.If you have an electronic switch, you need to test it with a multimeter for continuity. If you have a manual one, you can check if the door connects properly with the washing machine when you close the door. If you find the latch is faulty, it will need to be replaced.",
    img: NewsImg,
  },
  {
    id: 42636346345,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If your Samsung washing machine is not spinning, this guide will tell you exactly what you need to do. When a washing machine doesn’t spin, it’s usually the result of a defective component within the appliance. Most of the time, the faulty component can be repaired or replaced. Thankfully, most spare parts for Samsung washing machines are inexpensive and easy to find. To make fixing your washer as easy as possible we have created step-by-step instructions for you to follow.All major Samsung washing machine models are covered by this ",
    img: NewsImg,
  },
  {
    id: 14353467478658,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If the door latch is working properly, or if replacing it hasn’t fixed your washing machine, the next component to check is the drive belt. The drive belt helps your washing machine to rotate during the washing cycle. If it becomes misaligned your washing machine will not be able to spin correctly.To check the drive belt, you need to remove the top and side panels of your appliance so you can locate the belt. Once you locate the belt, you need to inspect it to make sure it isn’t misaligned or damaged. If damaged or misaligned, it will need to be repaired and if that isn’t possible, replaced.",
    img: NewsImg,
  },
  {
    id: 2354246234461,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If the lid switch is working correctly, or if replacing it hasn’t fixed your washing machine, the next component to check is the door latch. Some washing machines have electronic door latches and others have manual ones.If you have an electronic switch, you need to test it with a multimeter for continuity. If you have a manual one, you can check if the door connects properly with the washing machine when you close the door. If you find the latch is faulty, it will need to be replaced.",
    img: NewsImg,
  },
  {
    id: 1232463634,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If your Samsung washing machine is not spinning, this guide will tell you exactly what you need to do. When a washing machine doesn’t spin, it’s usually the result of a defective component within the appliance. Most of the time, the faulty component can be repaired or replaced. Thankfully, most spare parts for Samsung washing machines are inexpensive and easy to find. To make fixing your washer as easy as possible we have created step-by-step instructions for you to follow.All major Samsung washing machine models are covered by this ",
    img: NewsImg,
  },
  {
    id: 145654747,
    title: "Is It Worth Repairing an Air Conditioner?",
    text: "If the door latch is working properly, or if replacing it hasn’t fixed your washing machine, the next component to check is the drive belt. The drive belt helps your washing machine to rotate during the washing cycle. If it becomes misaligned your washing machine will not be able to spin correctly.To check the drive belt, you need to remove the top and side panels of your appliance so you can locate the belt. Once you locate the belt, you need to inspect it to make sure it isn’t misaligned or damaged. If damaged or misaligned, it will need to be repaired and if that isn’t possible, replaced.",
    img: NewsImg,
  },
];
