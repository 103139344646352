import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { Link } from "src/components/Link";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "Contact Us for Electrical Panel Installation Services";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => (
  <div className="relative">
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper"
        titleClassName="text-yellow-base max-w-215"
        title={SECTION_TITLE}
      >
        <div className="mx-auto max-w-215 md:text-center font-medium">
          <p>
            Ensure your electrical system is equipped to meet your needs with{" "}
            <span className="text-yellow-base">Smartchoice Service's</span>{" "}
            expert electrical panel installation services.
          </p>
          <br />

          <p className="font-semibold">
            Call us at{" "}
            <Link
              href={phone?.href}
              className="text-yellow-base font-bold hover:underline"
            >
              {phone?.label}
            </Link>{" "}
            to schedule your electrical panel installation appointment today.
          </p>

          <br />
          <p>
            Trust Smartchoice Service to provide reliable solutions for your
            electrical panel needs, ensuring safety and efficiency in your home
            or business.
          </p>
        </div>
      </Section>
    </ContentOverlay>
  </div>
);
