const VALID_PHONE_LENGTH = 11;

export const TEXT_VALIDATION_SCHEMA = {
  validate: (value: string) => {
    const trimmedValue = value.trim();
    const regex =
      /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ-]{1,}(?:[\s-][A-Za-zА-Яа-яЁёІіЇїЄєҐґ-]+){0,}$/;
    if (trimmedValue.length < 2 || trimmedValue.length > 50) {
      return "incorrect-length";
    }
    if (!regex.test(trimmedValue)) {
      return "incorrect-value";
    }
    return true;
  },
};

export const PHONE_VALIDATION_SCHEMA = {
  validate: {
    validLength: (value) => {
      const cleanedValue = value.replace(/\D/g, "");
      return (
        cleanedValue.length === VALID_PHONE_LENGTH || "incorrect-phone-number"
      );
    },
  },
};

export const EMAIL_VALIDATION_SCHEMA = {
  pattern: {
    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
    message: "incorrect-email",
  },
};

export const TEXTAREA_VALIDATION_SCHEMA = {
  validate: {
    maxLength: (value) => {
      return value.length <= 256 || "maximum-length";
    },
  },
};

export const INPUTS_VALIDATION_SCHEMAS = {
  tel: PHONE_VALIDATION_SCHEMA,
  text: TEXT_VALIDATION_SCHEMA,
  email: EMAIL_VALIDATION_SCHEMA,
  textarea: TEXTAREA_VALIDATION_SCHEMA,
};
