import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const selectorAppluBelowState = (state: RootState) => state.applyBelow;

export const selectorApplyBelow = createSelector(
  selectorAppluBelowState,
  (ApplyBelowState) => ApplyBelowState.data
);

export const selectorApplyBelowIsLoading = createSelector(
  selectorAppluBelowState,
  (ApplyBelowState) => ApplyBelowState.isLoading
);
