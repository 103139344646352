import { FC } from "react";
import cn from "classnames";
import {
  DEFAULT_CARD_CLASSNAME,
  DEFAULT_CARD_WRAPPER_CLASSNAME,
  DEFAULT_DESCRIPTION_CLASSNAME,
} from "../constants";
import { ICardProps } from "../types";

interface Props extends ICardProps {}

export const DoubleCard: FC<Props> = ({
  title,
  description,
  second_description,
  className,
  containerClassName,
}) => (
  <div
    className={cn(DEFAULT_CARD_WRAPPER_CLASSNAME, "w-full", containerClassName)}
  >
    <div className={cn(DEFAULT_CARD_CLASSNAME, "p-10", className)}>
      <div className="flex flex-col gap-2 ">
        <h4 className={cn("text-yellow-base text-2xl font-bold")}>{title}</h4>

        <div className="flex flex-col justify-center gap-5 sm:flex-row">
          <p className={cn(DEFAULT_DESCRIPTION_CLASSNAME, "w-full")}>
            {description}
          </p>

          <div className="hidden sm:block w-px bg-gray-light" />

          <p className={cn(DEFAULT_DESCRIPTION_CLASSNAME, " w-full")}>
            {second_description}
          </p>
        </div>
      </div>
    </div>
  </div>
);
