import { IFormField } from "src/@types/form";

export const BELOW_FORM_FIELDS: IFormField[] = [
  {
    id: "1363455",
    fieldId: "name",
    placeholder: "Name\u002A",
    name: "name",
    type: "text",
    required: true,
  },
  {
    id: "32342343",
    fieldId: "contactEmail",
    placeholder: "Email\u002A",
    name: "email",
    type: "email",
    required: true,
  },
  {
    id: "26834543",
    fieldId: "contactPhone",
    placeholder: "Phone\u002A",
    name: "phone",
    type: "tel",
    required: true,
  },

  {
    id: "43464353",
    fieldId: "contactAddress",
    placeholder: "Who Referred you?",
    name: "referrer",
    type: "text",
    required: false,
  },
];
