import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorEvChargerInstallationState = (state: RootState) =>
  state.evChargerInstallation;

export const selectorEvChargerInstallation = createSelector(
  selectorEvChargerInstallationState,
  (evChargerInstallationState) => evChargerInstallationState.data
);

export const selectorEvChargerInstallationIsLoading = createSelector(
  selectorEvChargerInstallationState,
  (evChargerInstallationState) => evChargerInstallationState.isLoading
);
