import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";

export const selectorApplianceInstallationState = (state: RootState) => state.applianceInstallation;

export const selectorApplianceInstallation = createSelector(
  selectorApplianceInstallationState,
  (applianceInstallationState) => applianceInstallationState.data
);

export const selectorApplianceInstallationsIsLoading = createSelector(
  selectorApplianceInstallationState,
  (applianceInstallationState) => applianceInstallationState.isLoading
);
