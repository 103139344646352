import { FC } from "react";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { ReactComponent as Arrow } from "src/assets/icons/arrow.svg";
import { INewsTipsItem } from "src/@types/newsTips";
import { SWIPER_ARROW_CLASSNAME } from "./constants";
import { NewsCard } from "../NewsCard";

interface NewsSwiperProps {
  newsList?: INewsTipsItem[];
}

export const NewsSwiper: FC<NewsSwiperProps> = ({ newsList }) => (
  <div className="relative max-w-267.5 mx-auto">
    <Swiper
      modules={[Navigation]}
      spaceBetween={35}
      slidesPerView="auto"
      navigation={{
        nextEl: ".custom-swiper-button-next",
        prevEl: ".custom-swiper-button-prev",
      }}
      centerInsufficientSlides={true}
    >
      {Boolean(newsList?.length) &&
        newsList.map((item) => (
          <SwiperSlide key={item.id} className="max-w-82.5">
            <NewsCard {...item} />
          </SwiperSlide>
        ))}
    </Swiper>

    <div className="relative z-20 flex justify-between mt-5  gl:absolute gl:-top-20 gl:right-0  gl:gap-x-10 gl:mt-0">
      <Arrow
        className={cn(
          SWIPER_ARROW_CLASSNAME,
          "custom-swiper-button-prev rotate-180"
        )}
      />

      <Arrow
        className={cn(SWIPER_ARROW_CLASSNAME, "custom-swiper-button-next")}
      />
    </div>
  </div>
);
