import React, { FC } from "react";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Section } from "src/components/Section";
import { Link } from "src/components/Link";
import { ISectionWithImage } from "src/@types";

const SECTION_TITLE = "Contact Us for EV Charger Installation Solutions";

interface Props extends ISectionWithImage {}

export const ContactUs: FC<Props> = ({ image, phone }) => (
  <div className="relative">
    <ContentOverlay bg={image} bgPositionClassName="object-center-30">
      <Section
        className="wrapper"
        titleClassName="text-yellow-base max-w-215"
        title={SECTION_TITLE}
      >
        <div className="mx-auto max-w-215 md:text-center font-medium">
          <p>
            Ready to power your EV with a reliable charging solution?
            <br />
            Contact{" "}
            <span className="text-yellow-base">Smartchoice Service</span> today.
          </p>
          <br />

          <p className="font-semibold">
            Call us at{" "}
            <Link
              href={phone?.href}
              className="text-yellow-base font-bold hover:underline"
            >
              {phone?.label}
            </Link>{" "}
            to schedule an appointment with our EV charger installation experts.
          </p>

          <br />
          <p>
            Experience seamless EV charging with Smartchoice Service, your
            trusted partner in sustainable transportation solutions.
          </p>
        </div>
      </Section>
    </ContentOverlay>
  </div>
);
