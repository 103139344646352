import { FC, useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getAcInstallationPageAsync } from "src/redux/services/hvac/acInstallation/actions";
import {
  selectorAcInstallation,
  selectorAcInstallationIsLoading,
} from "src/redux/services/hvac/acInstallation/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { CallUsDoubleDesc } from "src/page-components/Services/HVAC/FurnanceInstallation/CallUsDoubleDesc";
import {
  BENEFITS_OF_UPGRADING_AC_SYSTEM,
  WHY_CHOOSE_SMARTCHOICE_AC_INSTALLATION,
} from "src/page-components/Services/HVAC/AСInstallation/constants";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Section } from "src/components/Section";
import { GetInTouch } from "src/components/GetInTouchForm";
import { OtherServices } from "src/components/OtherServices";
import { ReviewSection } from "src/components/ReviewSection";
import { SectionCard } from "src/components/SectionCard";
import { AppointmentBooking } from "src/components/AppointmentBooking";
import { Link } from "src/components/Link";
import { BookNowModal } from "src/components/BookNowModal";
import { ContentOverlay } from "src/components/ContentOverlay";
import { Loader } from "src/components/Loader";
import { DEFAULT_CARD_LIST_CLASSNAME } from "src/components/SectionCard/constants";
import { Sizes } from "src/@types/sizes";
// import { CallUsDoubleDesc } from "src/page-components/Services/HVAC/AСInstallation/CallUsDoubleDesc";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const AcInstallation: FC = () => {
  const dispatch = useAppDispatch();

  const sectionRef = useRef(null);

  const bookNow = useModal();
  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorAcInstallation);
  const isLoading = useAppSelector(selectorAcInstallationIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getAcInstallationPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            name="AC Installation"
            image={pageData?.banner_background}
            title="Discover the Importance of Upgrading Your AC System"
            sectionRef={sectionRef}
          >
            Ensuring comfort, energy efficiency, and financial savings in your
            residential or commercial space hinges on investing in a new air
            conditioning system.
            <br /> <br />
            Recognizing this, Smartchoice Service provides expert AC
            installation services across Orange County and Los Angeles County,
            CA, as well as Pierce County and King County, WA. We understand the
            significance of transitioning to modern, high-efficiency systems
            tailored to your specific needs.
          </Hero>
        )}

        <div className="container">
          <Section
            className="wrapper relative"
            title="Benefits of Upgrading Your AC System"
            sectionRef={sectionRef}
          >
            {/* <div
              className={cn(DEFAULT_YELLOW_BLUR, "left-0 !-translate-x-1/2")}
            /> */}

            <div className={DEFAULT_CARD_LIST_CLASSNAME}>
              {BENEFITS_OF_UPGRADING_AC_SYSTEM.map(({ ...card }) => (
                <SectionCard key={card.id} {...card} />
              ))}
            </div>
          </Section>
          {/* 
          <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 left-0 !-translate-x-1/2 translate-y-1/2"
              )}
            /> */}

          <AppointmentBooking
            title="Contact Smartchoice Service to Schedule HVAC, Appliance Repair and Electrical Services"
            image={pageData?.appointment_booking}
            onButtonClick={bookNow.openModal}
            isLoading={isLoading}
          />
          {/* </div> */}

          <CallUsDoubleDesc
            sectionTitle="Why Choose Smartchoice Service for Your AC Installation?"
            sectionSubTitle="Selecting Smartchoice Service Inc. for your air conditioning installation ensures you receive the best service and value. Here’s why we stand out:"
            image={pageData?.why_choose_us}
            data={WHY_CHOOSE_SMARTCHOICE_AC_INSTALLATION}
            onButtonClick={bookNow.openModal}
            imageClassname="sm:h-138 gl:h-auto"
          />
        </div>

        <ContentOverlay
          className="flex relative"
          bgPositionClassName="object-center-30"
          bg={pageData?.ready_to_experience_background}
        >
          <Section
            className="wrapper"
            titleClassName="text-yellow-base"
            title="Ready to Experience the Smartchoice Difference?"
          >
            <div className="text-start md:text-center mx-auto max-w-160">
              <p className="text-xl font-manrope font-bold">
                Upgrade your comfort with Smartchoice.
                <br />
                Contact us today at &nbsp;
                <Link
                  href={contactsData?.CONTACTS[2]?.href}
                  className="text-yellow-base hover:underline"
                >
                  {contactsData?.CONTACTS[2]?.label}
                </Link>
                &nbsp; or visit our website to schedule your consultation.
                <br />
                <br />
                Your satisfaction and comfort are guaranteed
              </p>
            </div>
          </Section>
        </ContentOverlay>

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block top-0 right-0 "
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 left-0 !-translate-x-1/2 translate-y-1/2 "
              )}
            />
          </div> */}

          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}

          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default AcInstallation;
