import { useEffect, useRef } from "react";
// import cn from "classnames";
import { SuperSEO } from "react-super-seo";
import { useModal } from "src/hooks/useModal";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { getFurnaceRepairPageAsync } from "src/redux/services/hvac/furnaceRepair/actions";
import {
  selectorFurnaceRepair,
  selectorFurnaceRepairsIsLoading,
} from "src/redux/services/hvac/furnaceRepair/selectors";
import { selectorDetails } from "src/redux/details/selectors";
import { NewsTips } from "src/page-components/Home/NewsTips";
import { RealiableRepair } from "src/page-components/Services/HVAC/FurnaceRepair/RealiableRepair";
import { FurnaceSystems } from "src/page-components/Services/HVAC/FurnaceRepair/FurnaceSystems";
import { ReadyToHave } from "src/page-components/Services/HVAC/FurnaceRepair/ReadyToHave";
import { Hero } from "src/components/Hero";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { WhenToCallUsSection } from "src/components/WhenToCallUsSection";
import { FURNACE_REPAIR_WHEN_TO_CALL_US } from "src/page-components/Services/HVAC/FurnaceRepair/constants";
import { GetInTouch } from "src/components/GetInTouchForm";
import { ReviewSection } from "src/components/ReviewSection";
import { OtherServices } from "src/components/OtherServices";
import { BookNowModal } from "src/components/BookNowModal";
import { Loader } from "src/components/Loader";
import { Sizes } from "src/@types/sizes";
// import { DEFAULT_YELLOW_BLUR } from "src/constants/blurBackgroundClassName";

const TITLE_WHEN_TO_CALL_US_SECTION = "When to Call Us for Service";
const DESC_WHEN_TO_CALL_US_SECTION =
  "Recognizing the signs indicating the need for furnace repair can help prevent minor issues from escalating into major problems. If you notice any of the following indicators, it's time to reach out to Smartchoice Service for professional assistance:";

const FurnaceRepair = () => {
  const sectionRef = useRef(null);
  const bookNow = useModal();

  const dispatch = useAppDispatch();
  const contactsData = useAppSelector(selectorDetails);

  const pageData = useAppSelector(selectorFurnaceRepair);
  const isLoading = useAppSelector(selectorFurnaceRepairsIsLoading);

  useEffect(() => {
    if (!pageData) {
      dispatch(getFurnaceRepairPageAsync());
    }
  }, []);

  return (
    <>
      <SuperSEO
        title={pageData?.meta_title}
        description={pageData?.meta_description}
      />

      <PageWrapper>
        {isLoading ? (
          <div className="flex items-center min-h-screen lg:h-200 overflow-hidden">
            <Loader size={Sizes.L} />
          </div>
        ) : (
          <Hero
            title="Furnace Repair Experts in Southern California"
            image={pageData?.banner_background}
            name="FURNACE REPAIR"
          >
            Don’t let the chill of a cold room catch you off guard in the dead
            of night. At{" "}
            <span className="text-yellow-base font-bold">
              Smartchoice Service
            </span>
            , we understand the discomfort and inconvenience of a malfunctioning
            furnace.
            <br />
            <br />
            We are qualified to diagnose and repair all major heater brands,
            such as Carrier, Bryant, York, Lennox, Trane, Rheem, American
            Standard, Tempstar, Payne, Day N Night, Armstrong, RUUD, Amana, and
            Coleman, and stand behind our workmanship with excellent warranties
            on parts and labor.
            <br />
            <br />
            Our dedicated team of experts is committed to delivering top-notch
            service at the best price possible. With{" "}
            <span className="text-yellow-base font-bold">
              Smartchoice Service
            </span>
            , you can trust that every furnace repair order is handled with
            precision and efficiency.
          </Hero>
        )}

        <div className="container">
          <RealiableRepair
            image={pageData?.reliable_repairs}
          />

          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "top-0 left-0 !-translate-x-1/2"
              )}
            /> */}

          <FurnaceSystems />
          {/* </div> */}

          <WhenToCallUsSection
            className="gl:!flex-row-reverse"
            imageClassname="max-h-176.75 object-center"
            title={TITLE_WHEN_TO_CALL_US_SECTION}
            description={DESC_WHEN_TO_CALL_US_SECTION}
            image={pageData?.when_to_call_us}
            items={FURNACE_REPAIR_WHEN_TO_CALL_US}
            onButtonClick={bookNow.openModal}
          />
        </div>

        <ReadyToHave
          image={pageData?.ready_to_have_background}
          phone={contactsData?.CONTACTS[2]}
        />

        <div className="container">
          {/* <div className="relative">
            <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block -top-30 right-0 !translate-x-1/2"
              )}
            /> */}
          <GetInTouch />
          {/* <div
              className={cn(
                DEFAULT_YELLOW_BLUR,
                "hidden lg:block bottom-0 !-translate-x-1/2"
              )}
            />
          </div> */}
          <OtherServices />

          <NewsTips className="lg:hidden" />

          {/* <div className="relative">
            <div className={cn(DEFAULT_YELLOW_BLUR, "top-0 right-0")} /> */}
          <ReviewSection className="hidden lg:flex" />
          {/* </div> */}
        </div>
      </PageWrapper>

      <BookNowModal onClose={bookNow.closeModal} isOpen={bookNow.isOpenModal} />
    </>
  );
};

export default FurnaceRepair;
