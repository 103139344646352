import React, { FC } from "react";
import { DetailsBlock } from "./DetailsBlock";
// import { CAREER_POST } from "../constants";
import { ICareerItem } from "src/@types/career";

interface Props extends Omit<ICareerItem, "id" | "img"> {}

export const DetailsCareerPost: FC<Props> = ({
  title,
  description,
  requirements,
  traits_desired,
  benefits,
  note,
}) => {
  return (
    <section className="pt-6 pb-20 w-full">
      <div className="flex flex-col gap-5 max-w-160">
        <h2 className="text-40 leading-snug  font-bebas lg:text-5xl">
          {title}
        </h2>

        <div
          className="text-base"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <DetailsBlock title="Requirements:" description={requirements} />
        <DetailsBlock title="Traits Desired" description={traits_desired} />
        <DetailsBlock title="Benefits:" description={benefits} />
        <DetailsBlock title="Note:" description={note} />
      </div>
    </section>
  );
};
