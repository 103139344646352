import { FC } from "react";
import { useAppSelector } from "src/hooks/redux";
import { selectoReviews } from "src/redux/reviews/selectors";
import { RatingSection } from "./RatingSection";
import { ReviewSwiper } from "./ReviewSwiper";
import { DEFAULT_REVIEW_WRAPPER_CLASSNAME } from "./constants";
import { Section } from "../Section";
import { RatingVariants } from "../StarRating/types";

const SECTION_TITLE = "Yelp reviews";

export const YelpReviewSection: FC = () => {
  const reviewsData = useAppSelector(selectoReviews);
  const yelpReviewsData = reviewsData?.yelp_reviews;

  return (
    <Section title={SECTION_TITLE}>
      <div className={DEFAULT_REVIEW_WRAPPER_CLASSNAME}>
        <RatingSection
          titleClassName="!text-2xl"
          title="Overall rating"
          totalReviewCount={yelpReviewsData?.totalReviewCount}
          ratingVariant={RatingVariants.YELP}
          ratingValue={yelpReviewsData?.ratingValue}
          reviewsLink={yelpReviewsData?.reviewsLink}
        />

        <ReviewSwiper cards={yelpReviewsData?.cards} />
      </div>
    </Section>
  );
};
