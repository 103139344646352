import { FC } from "react";
import cn from "classnames";
import { IImage } from "src/@types";
import { Section } from "../Section";
import { BookOrCall } from "./BookOrCall";
import { ListItem } from "./ListItem";
import { WhenToCallUsItem } from "./types";
import { ImageWithLoader } from "../ImageWithLoader";

interface Props {
  title: string;
  description?: string;
  image: IImage;
  imageClassname?: string;
  subtitle?: string;
  items: WhenToCallUsItem[];
  isOrderedList?: boolean;
  className?: string;
  isShowBookOrcall?: boolean;
  onButtonClick?: VoidFunction;
}

export const WhenToCallUsSection: FC<Props> = ({
  title,
  description,
  image,
  imageClassname,
  subtitle,
  items = [],
  isOrderedList = true,
  className,
  isShowBookOrcall = true,
  onButtonClick,
}) => (
  <Section
    className="wrapper"
    descriptionClassName="mb-10"
    title={title}
    description={description}
  >
    <div
      className={cn(
        "flex flex-col items-center gap-5 gl:flex-row gl:items-start gl:gap-15",
        className
      )}
    >
      <ImageWithLoader
        containerClassName="sm:max-h-143.5 gl:max-w-155 gl:max-h-176.75 h-full border border-yellow-base rounded-10 overflow-hidden"
        className={cn("h-full rounded-10 object-cover", imageClassname)}
        image={image}
      />

      <div className="flex flex-col">
        {Boolean(subtitle) && (
          <span className="text-2xl font-bold mb-5">{subtitle}</span>
        )}

        <ul
          className={cn("flex flex-col gap-5 lg:mb-10", {
            "list-decimal list-inside bold-numbers": isOrderedList,
          })}
        >
          {items.map((item) => (
            <ListItem key={item.id} {...item} isOrderedList={isOrderedList} />
          ))}
        </ul>

        {isShowBookOrcall && <BookOrCall  onButtonClick={onButtonClick} />}
      </div>
    </div>
  </Section>
);
